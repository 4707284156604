import React, { useState, useContext, useEffect } from "react";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from "material-table";
import Grid from "@material-ui/core/Grid";

// import { QueryContext } from "../context/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../context/GlobalContext";

// const useStyles = makeStyles((theme) => ({
//     root: {
//       backgroundColor: theme.palette.background.paper,
//       width: "50vw",
//       height: "25vh"
//     },
//   }));
import { gridStyles } from "../ui/styles";
import { FunctionsTwoTone } from "@mui/icons-material";
import { findSiteAvgHourParkingByYearMonth } from "../data-loaders/DataLoaders";

export default function TablePanelSites(props) {
  // const classes = useStyles();
  const classesGrid = gridStyles();

  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const {
    titleDesc,
    unauthorizedPercentTable,
    spaceRatioData,
    expansionFactor,
    siteAvgHourParkingByYearMonthData,
  } = props;

  const [tableData, setTableData] = useState(query.regionCategoryTableData);
  useEffect(() => {
    setTableData(query.regionCategoryTableData);
  }, [query.regionCategoryTableData]);
  // console.log("tableDataSites-check", tableData);
  // console.log("spaceRatioData-check", spaceRatioData);

  function setSpaceRatioTableData(srData, expFact) {
    // console.log("expFactData-check", expFact);
    let tempTableData = [];
    srData.map((obj) =>
      tempTableData.push({
        ...obj,
        expansion_fact: expFact,
      })
    );
    // console.log("tempTableData-check", tempTableData);
    return tempTableData;
  }
  function setAvgHrSpaceRatioTableData(srData) {
    // console.log("expFactData-check", expFact);
    let parkingSpaces = 1;
    let tempTableData = [];
    srData.map((obj) => {
      // let parkingSpaces = parkingSiteGeom.properties.filter(
      //   (fc) => fc.dir === obj.direction && fc.authorized === 1
      // )[0].space;
      let featuresTemp = query.parkingSiteGeom.features;
      let parkingSpaces = 0;
      featuresTemp
        .filter((itm) => itm.properties.dir === obj.direction)
        .map((itm) => (parkingSpaces = parkingSpaces + itm.properties.space));
      // console.log(
      //   "parkingSpaces-check",
      //   featuresTemp.filter((itm) => itm.properties.dir === obj.direction),
      //   parkingSpaces
      // );

      tempTableData.push({
        sitename: obj.sitename,
        // direction: obj.direction,
        direction:
          obj.direction === "N"
            ? "Northbound"
            : obj.direction === "S"
            ? "Southbound"
            : obj.direction === "W"
            ? "Westbound"
            : obj.direction === "E"
            ? "Eastbound"
            : "Both",
        start_year: obj.start_year,
        start_month: obj.start_month,
        // siteLabel: obj.sitename,
        unexpanded_count: obj.unexpanded_count,
        expansion_fact: obj.expansion_fact,
        expanded_count: obj.expanded_count,
        spaces: parkingSpaces,
        spaceRatio: (obj.expanded_count / parkingSpaces) * 100,
      });
    });
    // console.log("tempTableData-check", tempTableData);
    return tempTableData;
  }

  // if (spaceRatioData && expansionFactor)
  //   setSpaceRatioTableData(spaceRatioData, expansionFactor);

  return (
    <div style={{ display: "flex" }}>
      <Grid container spacing={0} className={classesGrid.gridRow1}>
        <Grid item xs={12}>
          <div style={{ marginBottom: 1, paddingBottom: 1 }}>
            {unauthorizedPercentTable && (
              <MaterialTable
                columns={[
                  {
                    title: "Site Name",
                    field: "siteName",
                    hidden: true,
                    export: true,
                  },
                  {
                    title: "Year-Month",
                    field: "yearMonth",
                    // width: "5%",
                    hidden: true,
                    export: true,
                  },
                  {
                    title: "Parking Category",
                    field: "parkingCategory",
                    // type: "numeric",
                    // hidden: true,
                    // export: true,
                    // width: "2.5%",
                    cellStyle: {
                      backgroundColor: "#039be5",
                      color: "#FFF",
                      // width: "100px",
                      // maxWidth: "2px",
                      // whiteSpace: "nowrap",
                      cellWidth: "10%",
                    },
                  },
                  {
                    title: "Total Count",
                    field: "totalCount",

                    cellStyle: {
                      cellWidth: "15%",
                    },
                    type: "numeric",
                    // hidden: true,
                    // export: true,
                    render: (obj) => obj.totalCount.toLocaleString(),
                  },
                  {
                    title: "Official Location Count",
                    field: "authorizedCount",

                    cellStyle: {
                      cellWidth: "15%",
                    },
                    type: "numeric",
                    // hidden: true,
                    // export: true,
                    render: (obj) => obj.authorizedCount.toLocaleString(),
                  },
                  {
                    title: "Unofficial Location Count",
                    field: "unauthorizedCount",

                    cellStyle: {
                      cellWidth: "15%",
                    },
                    type: "numeric",
                    // hidden: true,
                    // export: true,
                    render: (obj) => obj.unauthorizedCount.toLocaleString(),
                  },
                  {
                    title: "Official Rate (%)",
                    field: "percentAuthorized",

                    cellStyle: {
                      cellWidth: "18%",
                    },
                    type: "numeric",
                    render: (obj) => obj.percentAuthorized.toFixed(1),
                  },
                  {
                    title: "Unofficial Rate (%)",
                    field: "percentUnauthorized",

                    cellStyle: {
                      cellWidth: "18%",
                    },
                    type: "numeric",
                    render: (obj) => obj.percentUnauthorized.toFixed(1),
                  },
                ]}
                data={unauthorizedPercentTable}
                title={
                  unauthorizedPercentTable[0].siteName +
                  " - " +
                  unauthorizedPercentTable[0].yearMonth
                }
                //   detailPanel={rowData => {
                //     return (
                //       <div>
                //        <PlotlyBarChart />
                //       <PlotlyStackedBarChart dataX={[1, 2, 3]} dataY1={[2, 5, 3]} dataY2={[3, 4, 4]}/>
                //       </div>
                //     )
                //   }}
                //   onRowClick={(event, rowData, togglePanel) => togglePanel()}

                options={{
                  exportButton: { csv: true, pdf: false },
                  // maxBodyHeight: "25vh"
                  exportAllData: true,
                  exportFileName:
                    unauthorizedPercentTable[0].siteName +
                    "_" +
                    unauthorizedPercentTable[0].yearMonth +
                    "_unauthorized-parking-summary",
                  paging: false,
                  rowStyle: {
                    wordWrap: "break-word",
                  },
                  padding: "dense",
                  tableLayout: "fixed",
                  doubleHorizontalScroll: false,
                  // detailPanelType: "multiple",
                  search: false,
                  headerStyle: {
                    backgroundColor: "#01579b",
                    color: "#FFF",
                  },
                }}
              />
            )}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: 1, paddingTop: 1 }}>
            {spaceRatioData && (
              <MaterialTable
                columns={[
                  {
                    title: "Site Name",
                    field: "sitename",
                    hidden: true,
                    export: true,
                  },
                  {
                    title: "Direction",
                    field: "direction",
                    // hidden: true,
                    export: true,
                  },
                  {
                    title: "Year",
                    field: "start_year",
                    // width: "5%",
                    hidden: true,
                    export: true,
                  },
                  {
                    title: "Year",
                    field: "start_month",
                    // width: "5%",
                    hidden: true,
                    export: true,
                  },
                  // {
                  //   title: "Site Name",
                  //   field: "siteLabel",
                  //   width: "25%",
                  //   cellStyle: {
                  //     backgroundColor: "#039be5",
                  //     color: "#FFF",
                  //     // width: "100px",
                  //     // maxWidth: "2px",
                  //     cellWidth: "25%",
                  //   },
                  //   headerStyle: { headerWidth: "25%" },
                  //   // type: "numeric",
                  //   // hidden: true,
                  //   // export: true,
                  // },
                  {
                    // title: "Unexpanded Average Daily Total Count",
                    title: "Unexpanded Count",
                    field: "unexpanded_count",
                    type: "numeric",
                    // hidden: true,
                    // export: true,
                    render: (obj) => obj.unexpanded_count.toFixed(2),
                    // render: (obj) => obj.totalCount.toFixed(0),
                    cellStyle: {
                      cellWidth: "15%",
                    },
                    headerStyle: { headerMaxWidth: "15%" },
                  },
                  {
                    title: "Expansion Factor",
                    field: "expansion_fact",
                    type: "numeric",
                    render: (obj) => obj.expansion_fact.toFixed(2),
                    cellStyle: {
                      cellWidth: "15%",
                    },
                  },
                  {
                    // title: "Unexpanded Average Daily Total Count",
                    title: "Expanded Count",
                    field: "expanded_count",
                    type: "numeric",
                    // hidden: true,
                    // export: true,
                    render: (obj) => obj.expanded_count.toFixed(2),
                    // render: (obj) => obj.totalCount.toFixed(0),
                    cellStyle: {
                      cellWidth: "15%",
                    },
                  },
                  {
                    title: "Parking Spaces",
                    field: "spaces",
                    type: "numeric",
                    // hidden: true,
                    // export: true,
                    // render: (obj) => obj.spaces.toLocaleString(),
                    cellStyle: {
                      cellWidth: "15%",
                    },
                  },
                  {
                    title: "Space Ratio (%)",
                    field: "spaceRatio",
                    type: "numeric",
                    render: (obj) => obj.spaceRatio.toFixed(2),
                    cellStyle: {
                      cellWidth: "15%",
                    },
                  },
                ]}
                // data={spaceRatioData}
                // data={setSpaceRatioTableData(spaceRatioData, expansionFactor)}
                data={setAvgHrSpaceRatioTableData(
                  siteAvgHourParkingByYearMonthData
                )}
                // title={
                //   spaceRatioData[0].siteName + " - " + spaceRatioData[0].yearMonth
                // }
                title={"Average Hourly Count and Space Ratio"}
                //   detailPanel={rowData => {
                //     return (
                //       <div>
                //        <PlotlyBarChart />
                //       <PlotlyStackedBarChart dataX={[1, 2, 3]} dataY1={[2, 5, 3]} dataY2={[3, 4, 4]}/>
                //       </div>
                //     )
                //   }}
                //   onRowClick={(event, rowData, togglePanel) => togglePanel()}
                options={{
                  exportButton: { csv: true, pdf: false },
                  // maxBodyHeight: "25vh"
                  exportAllData: true,
                  exportFileName:
                    spaceRatioData[0].siteName +
                    "_" +
                    spaceRatioData[0].yearMonth +
                    "_space-ratios",
                  paging: false,
                  rowStyle: {
                    wordWrap: "break-word",
                  },
                  padding: "dense",
                  tableLayout: "fixed",
                  // tableLayout: "auto",
                  // doubleHorizontalScroll: false,
                  // detailPanelType: "multiple",
                  search: false,
                  maxToolBarHeight: "0.01vh",
                  headerStyle: {
                    // position: "sticky",
                    top: 0,
                    paddingTop: 10,
                    marginTop: 10,
                    backgroundColor: "#01579b",
                    color: "#FFF",
                  },
                }}
                components={{
                  Toolbar: (props) => {
                    // Make a copy of props so we can hide the default title and show our own
                    // This let's us use our own Title while keeping the search
                    const propsCopy = { ...props };
                    // Hide default title
                    propsCopy.showTitle = true;
                    return (
                      <div
                        style={{
                          // backgroundColor: "lightblue",
                          height: "25px",
                          display: "flex",
                          alignItems: "center",
                          // borderTop: "solid",
                          paddingBottom: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        <Grid container direction="row">
                          {/* <Grid item xs={6}>
                    <h2>Some Table Title</h2>
                  </Grid> */}
                          <Grid item xs={12}>
                            <MTableToolbar {...propsCopy} />
                          </Grid>
                        </Grid>
                      </div>
                    );
                  },
                }}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
