/**
 * @fileOverview the global context provider component.
 */

import {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useCallback,
} from "react";
import {
  yearsMonths,
  geographies,
  sitesYearsMonths,
  parkingSites,
} from "../common/constants";

const GlobalStateContext = createContext();
const GlobalUpdaterContext = createContext();

const initialState = {
  popUpEnabled: true,
  highlightQuery: function (feature) {
    return false;
  },
  regionParkingData: [],
  // regionSelected: {
  //   selected: "Statewide",
  //   year: 2018,
  //   month: 1,
  //   yearMonthOptionsIndex: 0,
  //   geographyOptionsIndex: 1,
  // },
  regionParkingDataType: 0,
  regionCategoryTableData: {},
  selectedDayType: "All Daytypes",
  selectedVehicleWeightClass: 0,
  regionSelected: {
    selected: "Western Region",
    year: 2021,
    month: 1,
    yearMonthOptionsIndex: 36,
    // geographyOptionsIndex: 1,
    geographyOptionsIndex: geographies.findIndex(
      (obj) => obj.label === "Western Region"
    ),
    regionParkingDataType: 0,
    parkingDurationCategories: [1, 2, 3, 4, 5],
    statewideData: null,
  },
  statewideData: null,
  selectedSite: {
    selected: null,
    year: sitesYearsMonths[sitesYearsMonths.length - 1].year,
    month: sitesYearsMonths[0].month,
    // yearMonth: sitesYearsMonths[0].yearMonth,
    yearMonth: sitesYearsMonths.filter(
      (itm) => itm.year === sitesYearsMonths[sitesYearsMonths.length - 1].year
    )[0].yearMonth,
    parkingDataType: 0,
    parkingDurationCategories: [1, 2, 3, 4, 5],
  },
  parkingSiteGeom: null,
  siteEventsGeom: null,
};

const GlobalProvider = (props) => {
  const queryReducer = (state, action) => {
    switch (action.type) {
      case "setRegionParkingData":
        //regionParkingData
        return {
          ...state,
          regionParkingData: action.selection,
        };
      //   regionSelected: "Statewide",
      //   regionYearSelected: 2018,
      //   regionMonthSelected: 1,
      // case "setRegionSelected":
      //   return {
      //     ...state,
      //     regionSelected: action.selection,
      //   };
      case "setRegionParkingDataType":
        return {
          ...state,
          regionParkingDataType: action.selection,
        };
      case "setRegionCategoryTableData":
        return {
          ...state,
          regionCategoryTableData: action.selection,
        };
      case "setSelectedVehicleWeightClass":
        return {
          ...state,
          selectedVehicleWeightClass: action.selection,
        };
      case "setSelectedDayType":
        return {
          ...state,
          selectedDayType: action.selection,
        };
      case "setRegionSelected":
        return {
          ...state,
          regionSelected: action.selection,
        };
      case "setStatewideData":
        return {
          ...state,
          statewideData: action.selection,
        };
      case "setSelectedSite":
        return {
          ...state,
          selectedSite: action.selection,
        };
      case "setParkingSiteGeom":
        return {
          ...state,
          parkingSiteGeom: action.selection,
        };
      case "setSiteEventsGeom":
        return {
          ...state,
          siteEventsGeom: action.selection,
        };
      default:
        throw new Error();
    }
  };
  //   const [state, dispatch] = useReducer(queryReducer, INITIAL_INPUTS);
  const [state, dispatch] = useReducer(queryReducer, initialState);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalUpdaterContext.Provider value={dispatch}>
        {props.children}
      </GlobalUpdaterContext.Provider>
    </GlobalStateContext.Provider>
  );
};

const useGlobalState = () => {
  const globalState = useContext(GlobalStateContext);
  if (typeof globalState === "undefined") {
    throw new Error("useGlobalState must be used within a GlobalProvider");
  }
  return globalState;
};

const useGlobalUpdater = () => {
  const dispatch = useContext(GlobalUpdaterContext);
  if (typeof dispatch === "undefined") {
    throw new Error("useGlobalUpdater must be used within a GlobalProvider");
  }
  //const increment = useCallback(() => setCount((c) => c + 1), [setCount])
  const updater = useCallback((props) => dispatch(props), [dispatch]);
  return updater;
};

export { GlobalProvider, useGlobalState, useGlobalUpdater };
