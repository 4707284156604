import React, { useState, useRef, useEffect, useMemo, useContext } from "react";

import {
  geographies,
  sitesYears,
  vehicleWeightClassOptions,
} from "../common/constants";

import Grid from "@material-ui/core/Grid";

// import HighStock from "./HighCharts/HighStock";
// import LineChart from "./Charts/HighCharts/LineChart";
import StackedBarChart from "./HighCharts/StackedBarChart";
// import PieChart from "./Charts/HighCharts/PieChart";
// import StackedGrouped3dBarChart from "./HighCharts/StackedGrouped3dBarChart";
// import StackedGroupedBarChart from "./HighCharts/StackedGroupedBarChart";
import Donut3dChart from "./HighCharts/Donut3dChart";
// import MultiLineChart from "./Charts/HighCharts/MultiLineChart";
// import DependencyWheel from "./Charts/HighCharts/DependencyWheel";
// import SankeyDiagram from "./HighCharts/SankeyDiagram";
import TimeSeriesChart from "./HighCharts/TimeSeriesChart";
import TimeSeriesWithLineChart from "./HighCharts/TimeSeriesWithLineChart";
import ScatterPlot from "./HighCharts/ScatterPlot";
import Column3dPlot from "./HighCharts/Column3dPlot";
import AreaChart from "./HighCharts/AreaChart";
import HighContrasLight from "highcharts/themes/high-contrast-light";

// import { QueryContext } from "../context/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../context/GlobalContext";
import axios from "axios";

import { gridStyles } from "../ui/styles";

// import {
//   defaultPieChartData1,
//   defaultPieChartData2,
//   defaultPieChartData3,
//   defaultMultiLineChartData1,
//   defaultMultiLineChartData2,
//   defaultMultiLineChartData3,
//   defaultStackedColumnChartData1,
//   defaultStackedColumnChartData2,
//   defaultStackedColumnChartData3,
//   defaultDependencyWheelData1,
//   defaultDependencyWheelData2,
//   defaultDependencyWheelData3,
//   defaultStackedGrouped3dBarChartData1,
//   defaultStackedGrouped3dBarChartData2,
//   defaultStackedGrouped3dBarChartData3,
//   defaultDonut3dChartData1,
//   defaultDonut3dChartData2,
//   defaultDonut3dChartData3,
//   defaultSankeyDiagramData1,
//   defaultSankeyDiagramData2,
//   defaultSankeyDiagramData3,
// } from "../data/defaultData";

import { fetchParkingCatSummaryByRegion } from "../data-loaders/DataLoaders";

import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import BoxPlot from "./HighCharts/BoxPlot";

function ChartsLayout(props) {
  const classesGrid = gridStyles();
  //   const classes = useStyles();
  const {
    statusGuide,
    chartHeight,
    // regionSelected,
    // statewideData,
    stateOverTime,
  } = props;
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const [selectedVehicleWeightClass, setSelectedVehicleWeightClass] =
    React.useState(0);
  const [selectedDayType, setSelectedDayType] = React.useState("All Daytypes");

  const [regionParkingCatSummary, setRegionParkingCatSummary] = useState(null);
  //   const [yearMonthSummaryData, setYearMonthSummaryData] = useState(null);
  const [regionYearParkingCatSummary, setRegionYearParkingCatSummary] =
    useState(null);
  const [
    regionYearParkingCatSummaryChart,
    setRegionYearParkingCatSummaryChart,
  ] = useState(null);
  // console.log("regionYearParkingCatSummary-check", regionYearParkingCatSummary);
  const [
    regionYearDOWParkingCatSummaryChart,
    setRegionYearDOWParkingCatSummaryChart,
  ] = useState(null);
  const [
    duration1HrBinsByRegionYearMonth,
    setDuration1HrBinsByRegionYearMonth,
  ] = useState(null);
  const [durationBinsAveMax, setDurationBinsAveMax] = useState({
    averageBin: 0,
    maxEvents: 0,
  });

  useEffect(() => {
    //console.log(props.layerID)
    // console.log("YEAR-CHANGE-GOOD-CHARTS-STATE");
    const fetchRegionParkingCatSummary = async (
      region,
      year,
      month,
      vehweightclass
    ) => {
      try {
        //if (props.quads.length > 0) {
        //   if (props.layerID) {
        // setIsLoading(true);

        // let dataUrl =
        //   `${process.env.REACT_APP_API_ROOT}` +
        //   `/truckParking/fetchParkingCatSummaryByRegionYearMonth?region=${region}&year=${year}&month=${month}`;
        // if (vehweightclass !== 0)
        //   dataUrl =
        //     `${process.env.REACT_APP_API_ROOT}` +
        //     `/truckParking/fetchParkingCatSummaryByRegionYearMonthVehWeightClass?region=${region}&year=${year}&month=${month}&vehvweightclass=${vehweightclass}`;

        let dataUrl = "";
        if (selectedDayType === "All Daytypes") {
          dataUrl =
            `${process.env.REACT_APP_API_ROOT}` +
            `/truckParking/fetchParkingCatSummaryByRegionYearMonth?region=${region}&year=${year}&month=${month}`;
          if (vehweightclass !== 0)
            dataUrl =
              `${process.env.REACT_APP_API_ROOT}` +
              `/truckParking/fetchParkingCatSummaryByRegionYearMonthVehWeightClass?region=${region}&year=${year}&month=${month}&vehvweightclass=${vehweightclass}`;
        } else {
          dataUrl =
            `${process.env.REACT_APP_API_ROOT}` +
            `/truckParking/fetchParkingCatSummaryByRegionYearDayType?region=${region}&year=${year}&dow=${selectedDayType}`;
          if (vehweightclass !== 0)
            dataUrl =
              `${process.env.REACT_APP_API_ROOT}` +
              `/truckParking/fetchParkingCatSummaryByRegionYearDayTypeVehWeightClass?region=${region}&year=${year}&vehvweightclass=${vehweightclass}&dow=${selectedDayType}`;
        }

        // let dataUrl =
        //   `${process.env.REACT_APP_API_ROOT}` +
        //   `/truckParking/fetchParkingCatSummaryByRegionYearDayType?region=${region}&year=${year}&dow=${"all"}`;
        // if (vehweightclass !== 0)
        //   dataUrl =
        //     `${process.env.REACT_APP_API_ROOT}` +
        //     `/truckParking/fetchParkingCatSummaryByRegionYearDayTypeVehWeightClass?region=${region}&year=${year}&vehvweightclass=${vehweightclass}&dow=${"all"}`;

        // const response = await axios.get(
        //   `${process.env.REACT_APP_API_ROOT}` +
        //     `/truckParking/fetchParkingCatSummaryByRegionYearMonth?region=${region}&year=${year}&month=${month}`
        // );
        const response = await axios.get(dataUrl);
        //console.log(response);
        // console.log("response.data-check", response.data);
        // console.log(
        //   "query-url-check",
        //   `${process.env.REACT_APP_API_ROOT}` +
        //     `/truckParking/fetchParkingCatSummaryByRegionYearMonth?region=${region}&year=${year}&month=${month}`
        // );
        let f = response.data;
        // console.log("setRegionParkingCatSummary-check", f, selectedDayType);
        setRegionParkingCatSummary(f);

        //   }
      } catch (e) {
        console.log(e);
      }
    };
    let regionTemp = "State";
    if (query.regionSelected.selected !== "Statewide")
      regionTemp = query.regionSelected.selected;
    fetchRegionParkingCatSummary(
      regionTemp,
      query.regionSelected.year,
      query.regionSelected.month,
      selectedVehicleWeightClass
    );

    const fetchRegionYearParkingCatSummary = async (
      region,
      year,
      month,
      vehweightclass
    ) => {
      try {
        let dataUrl = "";

        if (selectedDayType === "All Daytypes") {
          dataUrl =
            `${process.env.REACT_APP_API_ROOT}` +
            `/truckParking/fetchParkingCatSummaryByRegionYear?region=${region}&year=${year}`;
          if (vehweightclass !== 0)
            dataUrl =
              `${process.env.REACT_APP_API_ROOT}` +
              `/truckParking/fetchParkingCatSummaryByRegionYearVehWeightClass?region=${region}&year=${year}&vehvweightclass=${vehweightclass}`;
        } else {
          dataUrl =
            `${process.env.REACT_APP_API_ROOT}` +
            `/truckParking/fetchParkingCatSummaryByRegionYearDayType?region=${region}&year=${year}&dow=${selectedDayType}`;
          if (vehweightclass !== 0)
            dataUrl =
              `${process.env.REACT_APP_API_ROOT}` +
              `/truckParking/fetchParkingCatSummaryByRegionYearDayTypeVehWeightClass?region=${region}&year=${year}&vehvweightclass=${vehweightclass}&dow=${selectedDayType}`;
        }

        const response = await axios.get(dataUrl);

        let f = response.data;

        setRegionYearParkingCatSummary(f);
        // console.log("dataUrl-check", dataUrl, f);
        // console.log("setYearSummaryData(f)-check", setYearSummaryData(f));
        // setRegionYearParkingCatSummaryChart(tempSummaryChart);

        let tableData = f;
        let totals = {
          region: tableData[0].region,
          start_year: tableData[0].start_year,
          start_month: "Total",
          parking_cat_1: 0,
          parking_cat_1_pc: 0,
          parking_cat_2: 0,
          parking_cat_2_pc: 0,
          parking_cat_3: 0,
          parking_cat_3_pc: 0,
          parking_cat_4: 0,
          parking_cat_4_pc: 0,
          parking_cat_5: 0,
          parking_cat_5_pc: 0,
          parking_cat_total: 0,
          parking_cat_total_pc: 0,
        };
        tableData.map((obj) => {
          obj.parking_cat_total =
            obj.parking_cat_1 +
            obj.parking_cat_2 +
            obj.parking_cat_3 +
            obj.parking_cat_4 +
            obj.parking_cat_5;
          obj.parking_cat_total_pc =
            (
              obj.parking_cat_1_pc +
              obj.parking_cat_2_pc +
              obj.parking_cat_3_pc +
              obj.parking_cat_4_pc +
              obj.parking_cat_5_pc
            ).toFixed(2) * 1;
          obj.parking_cat_1_pc = obj.parking_cat_1_pc.toFixed(2) * 1;
          obj.parking_cat_2_pc = obj.parking_cat_2_pc.toFixed(2) * 1;
          obj.parking_cat_3_pc = obj.parking_cat_3_pc.toFixed(2) * 1;
          obj.parking_cat_4_pc = obj.parking_cat_4_pc.toFixed(2) * 1;
          obj.parking_cat_5_pc = obj.parking_cat_5_pc.toFixed(2) * 1;

          totals.parking_cat_1 = totals.parking_cat_1 + obj.parking_cat_1;
          totals.parking_cat_2 = totals.parking_cat_2 + obj.parking_cat_2;
          totals.parking_cat_3 = totals.parking_cat_3 + obj.parking_cat_3;
          totals.parking_cat_4 = totals.parking_cat_4 + obj.parking_cat_4;
          totals.parking_cat_5 = totals.parking_cat_5 + obj.parking_cat_5;
          totals.parking_cat_total =
            totals.parking_cat_total +
            obj.parking_cat_1 +
            obj.parking_cat_2 +
            obj.parking_cat_3 +
            obj.parking_cat_4 +
            obj.parking_cat_5;
          //parking_cat_1_pc
          return obj;
        });
        totals.parking_cat_1_pc =
          (totals.parking_cat_1 / totals.parking_cat_total) * 100;
        totals.parking_cat_2_pc =
          (totals.parking_cat_2 / totals.parking_cat_total) * 100;
        totals.parking_cat_3_pc =
          (totals.parking_cat_3 / totals.parking_cat_total) * 100;
        totals.parking_cat_4_pc =
          (totals.parking_cat_4 / totals.parking_cat_total) * 100;
        totals.parking_cat_5_pc =
          (totals.parking_cat_5 / totals.parking_cat_total) * 100;
        totals.parking_cat_total_pc =
          (
            totals.parking_cat_1_pc +
            totals.parking_cat_2_pc +
            totals.parking_cat_3_pc +
            totals.parking_cat_4_pc +
            totals.parking_cat_5_pc
          ).toFixed(2) * 1;
        totals.parking_cat_1_pc = totals.parking_cat_1_pc.toFixed(2) * 1;
        totals.parking_cat_2_pc = totals.parking_cat_2_pc.toFixed(2) * 1;
        totals.parking_cat_3_pc = totals.parking_cat_3_pc.toFixed(2) * 1;
        totals.parking_cat_4_pc = totals.parking_cat_4_pc.toFixed(2) * 1;
        totals.parking_cat_5_pc = totals.parking_cat_5_pc.toFixed(2) * 1;
        tableData.push(totals);
        // console.log("tableData-check", tableData);

        dispatch({
          type: "setRegionCategoryTableData",
          selection: tableData,
        });

        setRegionYearParkingCatSummaryChart(
          setYearSummaryData2(f, query.regionSelected.year)
        );

        // console.log("tableData-check", tableData);
      } catch (e) {
        console.log(e);
      }
    };
    fetchRegionYearParkingCatSummary(
      regionTemp,
      query.regionSelected.year,
      query.regionSelected.month,
      selectedVehicleWeightClass
    );

    const fetchRegionYearDOWParkingCatSummary = async (
      region,
      year,
      month,
      vehweightclass
    ) => {
      try {
        let dataUrl =
          `${process.env.REACT_APP_API_ROOT}` +
          `/truckParking/fetchParkingCatSummaryByRegionYearDayType?region=${region}&year=${year}&dow=${"all"}`;
        if (vehweightclass !== 0)
          dataUrl =
            `${process.env.REACT_APP_API_ROOT}` +
            `/truckParking/fetchParkingCatSummaryByRegionYearDayTypeVehWeightClass?region=${region}&year=${year}&vehvweightclass=${vehweightclass}&dow=${"all"}`;

        // const response = await axios.get(
        //   `${process.env.REACT_APP_API_ROOT}` +
        //     `/truckParking/fetchParkingCatSummaryByRegionYearDayTpe?region=${region}&year=${year}`
        // );
        const response = await axios.get(dataUrl);
        let f = response.data;
        // console.log("f-check", f);

        // setYearDOWSummaryData(f);
        setRegionYearDOWParkingCatSummaryChart(f);
        //   }
      } catch (e) {
        console.log(e);
      }
    };
    fetchRegionYearDOWParkingCatSummary(
      regionTemp,
      query.regionSelected.year,
      query.regionSelected.month,
      selectedVehicleWeightClass
    );

    const fetch1HrDurationBins = async (region, year, month) => {
      try {
        //if (props.quads.length > 0) {
        //   if (props.layerID) {
        // setIsLoading(true);

        let regionLookup = geographies.filter((obj) => obj.label === region)[0]
          .lookup;

        // let dataUrl =
        //   `${process.env.REACT_APP_API_ROOT}` +
        //   `/truckParking/fetch1HrDurationBinsByRegionYearMonth?region=${regionLookup}&year=${year}&month=${month}`;
        let dataUrl =
          `${process.env.REACT_APP_API_ROOT}` +
          `/truckParking/fetchHalfHrDurationBinsByRegionYearMonth?region=${regionLookup}&year=${year}&month=${month}&vehvweightclass=${selectedVehicleWeightClass}`;
        if (selectedDayType === "All Daytypes")
          dataUrl = dataUrl + `&dow=${"All"}`;
        else dataUrl = dataUrl + `&dow=${selectedDayType}`;
        const response = await axios.get(dataUrl);
        //console.log(response);
        //console.log(response.data);
        let f = response.data;
        let fJson = [];
        let eventsTotal = 0;
        let hrEventsTotal = 0;
        let eventsMax = 0;
        f.map((obj) => {
          fJson.push([obj.duration_bin, obj.events]);
          eventsTotal = eventsTotal + obj.events;
          hrEventsTotal = hrEventsTotal + obj.duration_bin * obj.events;
          if (obj.events > eventsMax) eventsMax = obj.events;
        });

        // console.log("setDuration1HrBinsByRegionYearMonth-check", f, fJson);

        setDuration1HrBinsByRegionYearMonth(fJson);
        setDurationBinsAveMax({
          averageBin: hrEventsTotal / eventsTotal,
          maxEvents: eventsMax,
        });

        //   }
      } catch (e) {
        console.log(e);
      }
    };
    fetch1HrDurationBins(
      regionTemp,
      query.regionSelected.year,
      query.regionSelected.month
    );
  }, [
    query.regionSelected.geographyOptionsIndex,
    // query.regionSelected.yearMonthOptionsIndex,
    query.regionSelected.year,
    query.regionSelected.month,
    selectedVehicleWeightClass,
    selectedDayType,
  ]);
  // console.log(
  //   "duration1HrBinsByRegionYearMonth-check",
  //   duration1HrBinsByRegionYearMonth
  // );

  function setYearMonthSummaryData(tempData, region, year, month) {
    //console.log("fullData-check", month);
    let tempChartData = [];
    if (tempData !== null) {
      tempChartData.push({
        name: region,
        data: [
          ["Less than 1 hour", tempData[0].parking_cat_1_pc],
          [
            "Greater than 1 hour, Less than 3 hours",
            tempData[0].parking_cat_2_pc,
          ],
          ["Greater than 3, Less than 7 hours", tempData[0].parking_cat_3_pc],
          ["Greater than 7, Less than 11 hours", tempData[0].parking_cat_4_pc],
          ["Greater than 11 hours", tempData[0].parking_cat_5_pc],
        ],
      });
    }
    return tempChartData;
  }

  // function setYearSummaryData(tempData, region, year) {
  //   //console.log("fullData-check", month);
  //   let tempChartData = [];
  //   if (tempData !== null) {
  //     let dataTempCat1 = [];
  //     let dataTempCat2 = [];
  //     let dataTempCat3 = [];
  //     let tempDataMonth = tempData.filter((f) => f.start_month === 1)[0];
  //     //   dataTempCat1.push(tempDataMonth.parking_cat_1_pc);
  //     //   dataTempCat2.push(tempDataMonth.parking_cat_2_pc);
  //     //   dataTempCat3.push(tempDataMonth.parking_cat_3_pc);
  //     dataTempCat1.push(
  //       Math.round((tempDataMonth.parking_cat_1_pc + Number.EPSILON) * 100) /
  //         100
  //     );
  //     dataTempCat2.push(
  //       Math.round((tempDataMonth.parking_cat_2_pc + Number.EPSILON) * 100) /
  //         100
  //     );
  //     dataTempCat3.push(
  //       Math.round((tempDataMonth.parking_cat_3_pc + Number.EPSILON) * 100) /
  //         100
  //     );

  //     tempDataMonth = tempData.filter((f) => f.start_month === 4)[0];
  //     //   dataTempCat1.push(tempDataMonth.parking_cat_1_pc);
  //     //   dataTempCat2.push(tempDataMonth.parking_cat_2_pc);
  //     //   dataTempCat3.push(tempDataMonth.parking_cat_3_pc);
  //     dataTempCat1.push(
  //       Math.round((tempDataMonth.parking_cat_1_pc + Number.EPSILON) * 100) /
  //         100
  //     );
  //     dataTempCat2.push(
  //       Math.round((tempDataMonth.parking_cat_2_pc + Number.EPSILON) * 100) /
  //         100
  //     );
  //     dataTempCat3.push(
  //       Math.round((tempDataMonth.parking_cat_3_pc + Number.EPSILON) * 100) /
  //         100
  //     );

  //     tempDataMonth = tempData.filter((f) => f.start_month === 7)[0];
  //     //   dataTempCat1.push(tempDataMonth.parking_cat_1_pc);
  //     //   dataTempCat2.push(tempDataMonth.parking_cat_2_pc);
  //     //   dataTempCat3.push(tempDataMonth.parking_cat_3_pc);
  //     dataTempCat1.push(
  //       Math.round((tempDataMonth.parking_cat_1_pc + Number.EPSILON) * 100) /
  //         100
  //     );
  //     dataTempCat2.push(
  //       Math.round((tempDataMonth.parking_cat_2_pc + Number.EPSILON) * 100) /
  //         100
  //     );
  //     dataTempCat3.push(
  //       Math.round((tempDataMonth.parking_cat_3_pc + Number.EPSILON) * 100) /
  //         100
  //     );

  //     tempDataMonth = tempData.filter((f) => f.start_month === 10)[0];
  //     //   dataTempCat1.push(tempDataMonth.parking_cat_1_pc);
  //     //   dataTempCat2.push(tempDataMonth.parking_cat_2_pc);
  //     //   dataTempCat3.push(tempDataMonth.parking_cat_3_pc);
  //     dataTempCat1.push(
  //       Math.round((tempDataMonth.parking_cat_1_pc + Number.EPSILON) * 100) /
  //         100
  //     );
  //     dataTempCat2.push(
  //       Math.round((tempDataMonth.parking_cat_2_pc + Number.EPSILON) * 100) /
  //         100
  //     );
  //     dataTempCat3.push(
  //       Math.round((tempDataMonth.parking_cat_3_pc + Number.EPSILON) * 100) /
  //         100
  //     );

  //     // console.log("dataTempCat1", dataTempCat1);

  //     tempChartData.push({
  //       name: "Less than 3 hours",
  //       // name: "Cat 1",
  //       data: dataTempCat1,
  //     });
  //     tempChartData.push({
  //       name: "Greater than 3 hours, Less than 10 hours",
  //       // name: "Cat 2",
  //       data: dataTempCat2,
  //     });
  //     tempChartData.push({
  //       name: "Greater than 10 hours",
  //       // name: "Cat 3",
  //       data: dataTempCat3,
  //     });
  //   }
  //   // console.log(
  //   //   "tempChartData",
  //   //   tempChartData,
  //   //   defaultStackedGrouped3dBarChartData1
  //   // );
  //   return tempChartData;
  // }

  function setYearSummaryData2(tempData, yearSelected) {
    //console.log("fullData-check", month);
    let tempChartData = [];
    if (tempData !== null) {
      let dataTempCat1 = [];
      let dataTempCat2 = [];
      let dataTempCat3 = [];
      let dataTempCat4 = [];
      let dataTempCat5 = [];
      let years = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      // if (yearSelected === 2018) years = [1, 4, 7, 10];
      years.map((mobj) => {
        if (tempData.filter((f) => f.start_month === mobj).length > 0) {
          let tempDataMonth = tempData.filter((f) => f.start_month === mobj)[0];
          //   dataTempCat1.push(tempDataMonth.parking_cat_1_pc);
          //   dataTempCat2.push(tempDataMonth.parking_cat_2_pc);
          //   dataTempCat3.push(tempDataMonth.parking_cat_3_pc);

          dataTempCat1.push(
            Math.round(
              (tempDataMonth.parking_cat_1_pc + Number.EPSILON) * 100
            ) / 100
          );
          dataTempCat2.push(
            Math.round(
              (tempDataMonth.parking_cat_2_pc + Number.EPSILON) * 100
            ) / 100
          );
          dataTempCat3.push(
            Math.round(
              (tempDataMonth.parking_cat_3_pc + Number.EPSILON) * 100
            ) / 100
          );
          dataTempCat4.push(
            Math.round(
              (tempDataMonth.parking_cat_4_pc + Number.EPSILON) * 100
            ) / 100
          );
          dataTempCat5.push(
            Math.round(
              (tempDataMonth.parking_cat_5_pc + Number.EPSILON) * 100
            ) / 100
          );
        }
      });
      tempChartData.push({
        name: "Greater than 11 hours",
        // name: "Cat 3",
        data: dataTempCat5,
        index: 0,
        legendIndex: 4,
      });
      tempChartData.push({
        name: "Greater than 7, Less than 11 hours",
        // name: "Cat 3",
        data: dataTempCat4,
        index: 1,
        legendIndex: 3,
      });
      tempChartData.push({
        name: "Greater than 3, Less than 7 hours",
        // name: "Cat 3",
        data: dataTempCat3,
        index: 2,
        legendIndex: 2,
      });
      tempChartData.push({
        name: "Greater than 1, Less than 3 hours",
        // name: "Cat 2",
        data: dataTempCat2,
        index: 3,
        legendIndex: 1,
      });
      tempChartData.push({
        name: "Less than 1 hour",
        // name: "Cat 1",
        data: dataTempCat1,
        index: 4,
        legendIndex: 0,
      });
    }
    // console.log(
    //   "tempChartData",
    //   tempChartData,
    //   defaultStackedGrouped3dBarChartData1
    // );
    return tempChartData;
  }

  function setYearDOWSummaryData(tempData) {
    // console.log("tempData-check", tempData);
    let tempChartData = [];
    if (tempData !== null) {
      let dataTempCat1 = [];
      let dataTempCat2 = [];
      let dataTempCat3 = [];
      let dataTempCat4 = [];
      let dataTempCat5 = [];
      let tempDataMonth = tempData.filter(
        (f) => f.start_month === query.regionSelected.month
      );
      // console.log("tempDataMonth-check", tempData);
      [1, 2, 3, 4, 5, 6, 7].forEach((idx) => {
        let tempDataMonthWeek = tempDataMonth.filter(
          (f) => f.dow_index === idx
        )[0];
        dataTempCat1.push(
          Math.round(
            (tempDataMonthWeek.parking_cat_1_pc + Number.EPSILON) * 100
          ) / 100
        );
        dataTempCat2.push(
          Math.round(
            (tempDataMonthWeek.parking_cat_2_pc + Number.EPSILON) * 100
          ) / 100
        );
        dataTempCat3.push(
          Math.round(
            (tempDataMonthWeek.parking_cat_3_pc + Number.EPSILON) * 100
          ) / 100
        );
        dataTempCat4.push(
          Math.round(
            (tempDataMonthWeek.parking_cat_4_pc + Number.EPSILON) * 100
          ) / 100
        );
        dataTempCat5.push(
          Math.round(
            (tempDataMonthWeek.parking_cat_5_pc + Number.EPSILON) * 100
          ) / 100
        );
      });

      tempChartData.push({
        name: "Greater than 11 hours",
        // name: "Cat 3",
        data: dataTempCat5,
        index: 0,
        legendIndex: 4,
      });
      tempChartData.push({
        name: "Greater than 7, Less than 11 hours",
        // name: "Cat 3",
        data: dataTempCat4,
        index: 1,
        legendIndex: 3,
      });
      tempChartData.push({
        name: "Greater than 3, Less than 7 hours",
        // name: "Cat 3",
        data: dataTempCat3,
        index: 2,
        legendIndex: 2,
      });
      tempChartData.push({
        name: "Greater than 1, Less than 3 hours",
        // name: "Cat 2",
        data: dataTempCat2,
        index: 3,
        legendIndex: 1,
      });
      tempChartData.push({
        name: "Less than 1 hour",
        // name: "Cat 1",
        data: dataTempCat1,
        index: 4,
        legendIndex: 0,
      });
    }

    // console.log(
    //   "tempChartData",
    //   tempChartData,
    //   defaultStackedGrouped3dBarChartData1
    // );
    // console.log("tempChartData", tempChartData);
    return tempChartData;
  }

  // console.log("statewideData-check", statewideData);
  function setTimeDurationScatterSeries(dayType) {
    let dataTemp = [];
    if (query.statewideData) {
      if (selectedVehicleWeightClass === 0) {
        query.statewideData
          .filter((filter) => filter.dow_desc === dayType)
          .map((obj) => {
            const timeSecs = obj.start_time.split(":");
            dataTemp.push([
              parseFloat(timeSecs[0]) +
                parseFloat(timeSecs[1]) / 60 +
                parseFloat(timeSecs[2]) / 3600,
              obj.minutes,
            ]);
          });
      } else {
        query.statewideData
          .filter(
            (filter) =>
              filter.dow_desc === dayType &&
              filter.vehicleweightclass === selectedVehicleWeightClass
            // vehicleWeightClassOptions[selectedVehicleWeightClass].value
          )
          .map((obj) => {
            const timeSecs = obj.start_time.split(":");
            dataTemp.push([
              parseFloat(timeSecs[0]) +
                parseFloat(timeSecs[1]) / 60 +
                parseFloat(timeSecs[2]) / 3600,
              obj.minutes,
            ]);
          });
      }
    }
    return dataTemp;
  }

  const handleChangeVehicleWeightClass = (event) => {
    setSelectedVehicleWeightClass(parseInt(event.target.value));
    // if (event.target.value === "leaflet") setDeckGlMapTypeOptionsEnabled(true);
    // else setDeckGlMapTypeOptionsEnabled(false);
    dispatch({
      type: "setSelectedVehicleWeightClass",
      selection: event.target.value,
    });
  };

  const dayTypeOptions = [
    { label: "All Daytypes", value: 10 },
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
    { label: "Sunday", value: 7 },
  ];
  const handleChangeDayType = (event) => {
    setSelectedDayType(event.target.value);
    // if (event.target.value === "leaflet") setDeckGlMapTypeOptionsEnabled(true);
    // else setDeckGlMapTypeOptionsEnabled(false);
    dispatch({
      type: "setSelectedDayType",
      selection: event.target.value,
    });
  };

  const [statewideDataFilter, setStatewideDataFilter] = React.useState(null);
  const [hourEventCounts, setHourEventCounts] = React.useState(null);

  useEffect(() => {
    if (query.statewideData) {
      // console.log("statewideData-check", statewideData);
      // setStatewideDataFilter(
      //   setTimeDurationScatterSeries(selectedDayType, selectedVehicleWeightClass)
      // );

      let dataTemp = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ];
      let hourEventCountsTemp = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];

      if (query.statewideData) {
        if (selectedVehicleWeightClass === 0) {
          if (selectedDayType === "All Daytypes") {
            query.statewideData
              // .filter((filter) => filter.dow_desc === selectedDayType)
              .map((obj) => {
                const timeSecs = obj.start_time.split(":");
                // dataTemp.push([
                //   parseFloat(timeSecs[0]) +
                //     parseFloat(timeSecs[1]) / 60 +
                //     parseFloat(timeSecs[2]) / 3600,
                //   obj.minutes,
                // ]);
                dataTemp[parseInt(timeSecs[0])].push(obj.minutes);
                // const timeMins = obj.minutes[0];
                // dataTemp[timeSecs[0]] = { ...dataTemp[timeSecs[0]], timeMins };
                hourEventCountsTemp[parseInt(timeSecs[0])] =
                  hourEventCountsTemp[parseInt(timeSecs[0])] + 1;
              });
          } else {
            query.statewideData
              .filter((filter) => filter.dow_desc === selectedDayType)
              .map((obj) => {
                const timeSecs = obj.start_time.split(":");
                // dataTemp[timeSecs[0]].push([
                //   parseFloat(timeSecs[0]) +
                //     parseFloat(timeSecs[1]) / 60 +
                //     parseFloat(timeSecs[2]) / 3600,
                //   obj.minutes,
                // ]);

                // const timeMins = obj.minutes;
                // dataTemp[timeSecs[0]] = {
                //   ...dataTemp[timeSecs[0]],
                //   timeMins,
                // };

                dataTemp[parseInt(timeSecs[0])].push(obj.minutes);

                hourEventCountsTemp[parseInt(timeSecs[0])] =
                  hourEventCountsTemp[parseInt(timeSecs[0])] + 1;
              });
          }
        } else {
          if (selectedDayType === "All Daytypes") {
            query.statewideData
              .filter(
                (filter) =>
                  // filter.dow_desc === selectedDayType &&
                  filter.vehicleweightclass === selectedVehicleWeightClass
                // vehicleWeightClassOptions[selectedVehicleWeightClass].value
              )
              .map((obj) => {
                const timeSecs = obj.start_time.split(":");
                // dataTemp[timeSecs[0]].push([
                //   parseFloat(timeSecs[0]) +
                //     parseFloat(timeSecs[1]) / 60 +
                //     parseFloat(timeSecs[2]) / 3600,
                //   obj.minutes,
                // ]);

                dataTemp[parseInt(timeSecs[0])].push(obj.minutes);

                hourEventCountsTemp[parseInt(timeSecs[0])] =
                  hourEventCountsTemp[parseInt(timeSecs[0])] + 1;
              });
          } else {
            query.statewideData
              .filter(
                (filter) =>
                  filter.dow_desc === selectedDayType &&
                  filter.vehicleweightclass === selectedVehicleWeightClass
                // vehicleWeightClassOptions[selectedVehicleWeightClass].value
              )
              .map((obj) => {
                const timeSecs = obj.start_time.split(":");
                // dataTemp[timeSecs[0]].push([
                //   parseFloat(timeSecs[0]) +
                //     parseFloat(timeSecs[1]) / 60 +
                //     parseFloat(timeSecs[2]) / 3600,
                //   obj.minutes,
                // ]);

                dataTemp[parseInt(timeSecs[0])].push(obj.minutes);

                hourEventCountsTemp[parseInt(timeSecs[0])] =
                  hourEventCountsTemp[parseInt(timeSecs[0])] + 1;
              });
          }
        }
      }
      // return dataTemp;
      setStatewideDataFilter(dataTemp);
      setHourEventCounts(hourEventCountsTemp);
    }
  }, [query.statewideData, selectedDayType, selectedVehicleWeightClass]);

  // console.log(
  //   "selectedVehicleWeightClass-check",
  //   selectedVehicleWeightClass,
  // statewideData.filter(
  //   (filter) =>
  //     filter.vehicleweightclass === 2 || filter.vehicleweightclass === 3
  //   // vehicleWeightClassOptions[selectedVehicleWeightClass].value
  // ),
  //   statewideData.length
  // );

  const monthsLookup = [
    { value: 1, description: "January" },
    { value: 2, description: "February" },
    { value: 3, description: "March" },
    { value: 4, description: "April" },
    { value: 5, description: "May" },
    { value: 6, description: "June" },
    { value: 7, description: "July" },
    { value: 8, description: "August" },
    { value: 9, description: "September" },
    { value: 10, description: "October" },
    { value: 11, description: "November" },
    { value: 12, description: "December" },
  ];

  function setStateOverTimeChartData(vehwghtclass) {
    let chartData = [];

    sitesYears.map((year) => {
      let otdataTemp = [];
      var rows = [];
      for (var i = 0; i < 366; i++) {
        otdataTemp.push(null);
      }

      // let stateOverTimeTemp = stateOverTime.filter((obj) => obj.year === year);
      // console.log("stateOverTimeTemp-check", stateOverTimeTemp);
      stateOverTime
        .filter(
          (obj) => obj.year === year && obj.vehicleweightclass === vehwghtclass
        )
        .map((itm) => {
          otdataTemp[itm.yday] = itm.n;
          // otdataTemp.push(itm.n);
        });
      if (otdataTemp.length > 1)
        chartData.push({ name: year, data: otdataTemp });
    });

    // console.log("chartData-check", chartData);

    return chartData;
  }
  const [
    stateOverTimeChartDataWghtClass2,
    setstateOverTimeChartDataWghtClass2,
  ] = React.useState([]);
  const [
    stateOverTimeChartDataWghtClass3,
    setstateOverTimeChartDataWghtClass3,
  ] = React.useState([]);
  useEffect(() => {
    setstateOverTimeChartDataWghtClass2(setStateOverTimeChartData(2));
    setstateOverTimeChartDataWghtClass3(setStateOverTimeChartData(3));
  }, [stateOverTime]);

  return (
    <div>
      <Grid
        container
        // spacing={0}
        // style={{ height: "41vh", marginTop: "12%" }}
        className={classesGrid.gridRow1}
      >
        <Grid item xs={6}>
          <FormControl className={classesGrid.formLabel}>
            <FormLabel
              id="vehicle-weight-class-radio-buttons-group-label"
              className={classesGrid.formLabel}
            >
              Vehicle Weight Class
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group-map-type"
              value={selectedVehicleWeightClass}
              onChange={handleChangeVehicleWeightClass}
              className={classesGrid.formLabel}
              row
            >
              {vehicleWeightClassOptions.map((option, index) => (
                <FormControlLabel
                  // value="mapbox://styles/mapbox/streets-v11"
                  value={option.value}
                  control={<Radio size="small" />}
                  label={option.label}
                  key={"vehicleweightclass-" + option.value}
                  // className={classesGrid.formLabel}
                  // disabled={false}
                  // disabled={regionSelected.year === 2018 ? true : false}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <FormLabel id="day-of-week-radio-buttons-group-label">
              Day of Week
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group-map-type"
              value={selectedDayType}
              onChange={handleChangeDayType}
              // className={classes.formLabel}
              row
            >
              {dayTypeOptions.map((option, index) => (
                <FormControlLabel
                  // value="mapbox://styles/mapbox/streets-v11"
                  value={option.label}
                  control={<Radio size="small" />}
                  label={option.label}
                  key={"vehicleweightclass-" + option.value}
                  // className={classes.formLabel}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>{" "}
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <Donut3dChart
            chartHeight={chartHeight}
            // titleText={
            //   regionSelected.selected + " Distribution of Truck Parking Events"
            // }
            titleText={
              query.regionSelected.selected +
              " Truck Parking Events By Duration" +
              "<br/>" +
              (selectedDayType !== "All Daytypes"
                ? selectedDayType + ", "
                : "") +
              monthsLookup.filter(
                (obj) => obj.value === query.regionSelected.month
              )[0].description +
              "-" +
              query.regionSelected.year +
              ", " +
              // vehicleWeightClassOptions[selectedVehicleWeightClass].label +
              vehicleWeightClassOptions.filter(
                (obj) => obj.value === selectedVehicleWeightClass
              )[0].label
            }
            // subtitleText={query.regionSelected.year + "-" + query.regionSelected.month}
            pieInnerSize={100}
            pieDepth={60}
            //   chartData={defaultDonut3dChartData1}
            chartData={setYearMonthSummaryData(regionParkingCatSummary)}
            //   chartData={regionYearMonthSummaryData}
          />
        </Grid>
        <Grid item xs={6}>
          {regionYearDOWParkingCatSummaryChart && (
            <StackedBarChart
              themeHC={HighContrasLight}
              chartHeight={chartHeight}
              chartData={setYearDOWSummaryData(
                regionYearDOWParkingCatSummaryChart
              )}
              // titleText={
              //   query.regionSelected.year +
              //   " " +
              //   query.regionSelected.selected +
              //   " Distribution of Truck Parking Events"
              // }
              // subtitleText={query.regionSelected.year + "-" + query.regionSelected.month}
              titleText={
                query.regionSelected.selected +
                " Truck Parking Events By Day-of-Week" +
                "<br/>" +
                // (selectedDayType !== "All Daytypes"
                //   ? selectedDayType + ", "
                //   : "") +
                monthsLookup.filter(
                  (obj) => obj.value === query.regionSelected.month
                )[0].description +
                "-" +
                query.regionSelected.year +
                ", " +
                // vehicleWeightClassOptions[selectedVehicleWeightClass].label +
                vehicleWeightClassOptions.filter(
                  (obj) => obj.value === selectedVehicleWeightClass
                )[0].label
              }
              xAxisCategories={[
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ]}
              plotOptionsColumnStacking={"normal"}
              plotOptionsColumnDataLabelsEndabled={true}
              yAxisMax={100}
              yAxisTitleText={"% of Truck Parking Events"}
              legendLayout={"horizontal"}
              legendFontSize={11}
              legendSpecs={{
                // align: "right",
                // verticalAlign: "top",
                layout: "horizontal",
                // x: 0,
                // y: 100,
                itemStyle: {
                  // color: "#000000",
                  // fontWeight: "bold",
                  fontSize: 11,
                },
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <FormControl className={classesGrid.formLabel}>
            <FormLabel
              id="vehicle-weight-class-radio-buttons-group-label"
              className={classesGrid.formLabel}
            >
              Vehicle Weight Class
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group-map-type"
              value={selectedVehicleWeightClass}
              onChange={handleChangeVehicleWeightClass}
              className={classesGrid.formLabel}
              row
            >
              {vehicleWeightClassOptions.map((option, index) => (
                <FormControlLabel
                  // value="mapbox://styles/mapbox/streets-v11"
                  value={option.value}
                  control={<Radio size="small" />}
                  label={option.label}
                  key={"vehicleweightclass-" + option.value}
                  // className={classesGrid.formLabel}
                  // disabled={false}
                  // disabled={query.regionSelected.year === 2018 ? true : false}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <FormLabel id="day-of-week-radio-buttons-group-label">
              Day of Week
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group-map-type"
              value={selectedDayType}
              onChange={handleChangeDayType}
              // className={classes.formLabel}
              row
            >
              {dayTypeOptions.map((option, index) => (
                <FormControlLabel
                  // value="mapbox://styles/mapbox/streets-v11"
                  value={option.label}
                  control={<Radio size="small" />}
                  label={option.label}
                  key={"vehicleweightclass-" + option.value}
                  // className={classes.formLabel}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          {regionYearParkingCatSummaryChart && (
            <StackedBarChart
              themeHC={HighContrasLight}
              chartHeight={chartHeight}
              chartData={regionYearParkingCatSummaryChart}
              // titleText={
              //   query.regionSelected.year +
              //   " " +
              //   query.regionSelected.selected +
              //   " Distribution of Truck Parking Events"
              // }
              titleText={
                query.regionSelected.year +
                " " +
                query.regionSelected.selected +
                " Truck Parking Events By Month" +
                "<br/>" +
                (selectedDayType !== "All Daytypes"
                  ? selectedDayType + ", "
                  : "") +
                // monthsLookup.filter(
                //   (obj) => obj.value === query.regionSelected.month
                // )[0].description +
                // "-" +
                // query.regionSelected.year +
                // ", " +
                // vehicleWeightClassOptions[selectedVehicleWeightClass].label +
                vehicleWeightClassOptions.filter(
                  (obj) => obj.value === selectedVehicleWeightClass
                )[0].label
              }
              // xAxisCategories={
              //   query.regionSelected.year === 2018
              //     ? ["January", "April", "July", "October"]
              //     : monthsLookup.map((mobj) => {
              //         return mobj.description;
              //       })
              // }
              xAxisCategories={monthsLookup.map((mobj) => {
                return mobj.description;
              })}
              // xAxisCategories={[
              //   "Month 1",
              //   "Month 2",
              //   "Month 3",
              //   "Month 4",
              //   "Month 5",
              //   "Month 6",
              //   "Month 7",
              //   "Month 8",
              //   "Month 9",
              //   "Month 10",
              //   "Month 11",
              //   "Month 12",
              // ]}
              plotOptionsColumnStacking={"normal"}
              plotOptionsColumnDataLabelsEndabled={true}
              yAxisMax={100}
              yAxisTitleText={"% of Truck Parking Events"}
              legendSpecs={{
                // align: "right",
                // verticalAlign: "top",
                layout: "horizontal",
                // x: 0,
                // y: 100,
                itemStyle: {
                  // color: "#000000",
                  // fontWeight: "bold",
                  fontSize: 11,
                },
              }}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {/* <TimeSeriesChart
            themeHC={HighContrasLight}
            chartHeight={chartHeight}
            titleText={
              query.regionSelected.selected +
              " Distribution of Truck Parking Events " +
              "<br/>" +
              query.regionSelected.year +
              "-" +
              query.regionSelected.month
            }
            yAxisTitleText={"Number of Parking Events"}
            xAxisType={"time"}
            xAxisTitleText={"Parking Duration (Hours)"}
            seriesType={"area"}
            // seriesType={"line"}
            seriesName={"number of Parking Events:"}
            seriesData={duration1HrBinsByRegionYearMonth}
          /> */}
          <TimeSeriesWithLineChart
            themeHC={HighContrasLight}
            chartHeight={chartHeight}
            // titleText={
            //   query.regionSelected.selected +
            //   " Distribution of Truck Parking Events " +
            //   "<br/>" +
            //   query.regionSelected.year +
            //   "-" +
            //   query.regionSelected.month
            // }
            titleText={
              monthsLookup.filter(
                (obj) => obj.value === query.regionSelected.month
              )[0].description +
              "-" +
              query.regionSelected.year +
              " " +
              query.regionSelected.selected +
              " Truck Parking Events By Duration Bin" +
              "<br/>" +
              (selectedDayType !== "All Daytypes"
                ? selectedDayType + ", "
                : "") +
              // monthsLookup.filter(
              //   (obj) => obj.value === query.regionSelected.month
              // )[0].description +
              // "-" +
              // query.regionSelected.year +
              // ", " +
              // vehicleWeightClassOptions[selectedVehicleWeightClass].label +
              vehicleWeightClassOptions.filter(
                (obj) => obj.value === selectedVehicleWeightClass
              )[0].label
            }
            yAxisTitleText={"Number of Parking Events"}
            xAxisType={"time"}
            xAxisTitleText={"Parking Duration (Hours)"}
            seriesType={"area"}
            // seriesType={"line"}
            seriesName={"Number of Parking Events"}
            seriesData={duration1HrBinsByRegionYearMonth}
            lineName={"Average Duration Bin"}
            // lineData={[
            //   [6, 0],
            //   [6, 25527],
            // ]}
            // durationBinsAveMax.averageBin, maxEvents;
            lineData={[
              [durationBinsAveMax.averageBin, 0],
              [durationBinsAveMax.averageBin, durationBinsAveMax.maxEvents],
            ]}
          />
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={6}>
          <FormControl className={classesGrid.formLabel}>
            <FormLabel
              id="vehicle-weight-class-radio-buttons-group-label"
              className={classesGrid.formLabel}
            >
              Vehicle Weight Class
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group-map-type"
              value={selectedVehicleWeightClass}
              onChange={handleChangeVehicleWeightClass}
              className={classesGrid.formLabel}
              row
            >
              {vehicleWeightClassOptions.map((option, index) => (
                <FormControlLabel
                  // value="mapbox://styles/mapbox/streets-v11"
                  value={option.value}
                  control={<Radio size="small" />}
                  label={option.label}
                  key={"vehicleweightclass-" + option.value}
                  // className={classesGrid.formLabel}
                  // disabled={false}
                  // disabled={query.regionSelected.year === 2018 ? true : false}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <FormLabel id="day-of-week-radio-buttons-group-label">
              Day of Week
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group-map-type"
              value={selectedDayType}
              onChange={handleChangeDayType}
              // className={classes.formLabel}
              row
            >
              {dayTypeOptions.map((option, index) => (
                <FormControlLabel
                  // value="mapbox://styles/mapbox/streets-v11"
                  value={option.label}
                  control={<Radio size="small" />}
                  label={option.label}
                  key={"vehicleweightclass-" + option.value}
                  // className={classes.formLabel}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          {query.statewideData !== null && (
            <BoxPlot
              themeHC={HighContrasLight}
              chartHeight={chartHeight}
              titleText={
                query.regionSelected.selected +
                " Truck Parking Events " +
                "<br/>" +
                (selectedDayType !== "All Daytypes"
                  ? selectedDayType + ", "
                  : "") +
                monthsLookup.filter(
                  (obj) => obj.value === query.regionSelected.month
                )[0].description +
                "-" +
                query.regionSelected.year +
                ", " +
                vehicleWeightClassOptions.filter(
                  (obj) => obj.value === selectedVehicleWeightClass
                )[0].label
              }
              yAxisTitleText={"Parking Duration (Minutes)"}
              xAxisType={"time"}
              xAxisTitleText={"Time of Day"}
              seriesName1={"Parking Events"}
              seriesData1={statewideDataFilter}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {query.statewideData !== null && (
            <Column3dPlot
              themeHC={HighContrasLight}
              chartHeight={chartHeight}
              titleText={
                query.regionSelected.selected +
                " Summary of Truck Parking Events " +
                "<br/>" +
                (selectedDayType !== "All Daytypes"
                  ? selectedDayType + ", "
                  : "") +
                monthsLookup.filter(
                  (obj) => obj.value === query.regionSelected.month
                )[0].description +
                "-" +
                query.regionSelected.year +
                ", " +
                // vehicleWeightClassOptions[selectedVehicleWeightClass].label +
                vehicleWeightClassOptions.filter(
                  (obj) => obj.value === selectedVehicleWeightClass
                )[0].label
              }
              yAxisTitleText={"Parking Duration (Minutes)"}
              xAxisType={"time"}
              xAxisTitleText={"Time of Day"}
              barName1={"Number of Events"}
              barData1={hourEventCounts}
            />
          )}
        </Grid>
      </Grid>
      <Grid container direction="row">
        {stateOverTimeChartDataWghtClass2.length > 1 && (
          <Grid item xs={6}>
            <AreaChart
              chartData={setStateOverTimeChartData(2)}
              titleText={"Statewide Parking Events By Day"}
              subtitleText={"Vehicle Weight Class 2"}
            />
          </Grid>
        )}
        {stateOverTimeChartDataWghtClass3.length > 1 && (
          <Grid item xs={6}>
            <AreaChart
              chartData={stateOverTimeChartDataWghtClass3}
              titleText={"Statewide Parking Events By Day"}
              subtitleText={"Vehicle Weight Class 3"}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ChartsLayout;
