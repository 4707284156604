import React, { useState, useContext } from "react";
import DeckGL from "@deck.gl/react";
import { ScatterplotLayer, GeoJsonLayer } from "@deck.gl/layers";
import { GridLayer } from "@deck.gl/aggregation-layers";
import {
  StaticMap,
  MapContext,
  NavigationControl,
  // Popup,
  ScaleControl,
  // WebMercatorViewport,
} from "react-map-gl";

import { makeStyles, Dialog } from "@material-ui/core";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

// import { PathLayer } from "@deck.gl/layers";

// import bbox from "@turf/bbox";

import {
  // fetchStatewideData,
  // fetchParkingDataRegionByYearMonth,
  fetchParkingDataCountyByYearMonth,
  fetchParkingDataByRegionYearMonth,
} from "../data-loaders/DataLoaders";

// import { sampleStateData } from "../TestData/sampleStateData";

// import axios from "axios";

// import { QueryContext } from "../context/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../context/GlobalContext";

import {
  regionParkingDataTypes,
  vehicleWeightClassOptions,
} from "../common/constants";

import LayerControls from "./contols/LayerControls";

import { hexToRgb } from "../utils/utils";

import StateMapLegend from "./StateMapLegend";
import StateYrMnthSelect from "./StateYrMnthSelect";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  deckglMap: {
    position: "relative",
  },
  dialog: {
    position: "absolute",
    left: "92%",
    top: "50%",
    transform: "translate(-75%,-50%)",
  },
  dialog2: {
    position: "absolute",
    left: "69vw",
    top: "27vh",
  },
  dataParameters: {
    position: "absolute",
    marginLeft: "12px",
    marginTop: "150px",
    color: "black",
  },
  dataParameters2: {
    position: "absolute",
    left: "74%",
    top: "1%",
    color: "black",
  },
  boxBackground: {
    backgroundColor: "white",
    color: "blue",
    fontSize: 12,
    position: "absolute",
  },
});

function DeckglMap(props) {
  // console.log("DeckglMap-fire-check");
  const classes = useStyles();
  const {
    // statusGuide,
    // regionSelected,
    // setRegionSelected,
    // statewideData,
    isLoading,
    landUseData,
  } = props;
  // console.log("statewideData-check", statewideData);
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
  });

  const NAV_CONTROL_STYLE = {
    position: "absolute",
    // top: "5px",
    // left: "5px",
    marginTop: "10px",
    marginLeft: "12px",
  };
  const ScaleControl_STYLE = {
    position: "absolute",
    top: "96%",
    left: "125px",
  };
  const layers_CONTROL_STYLE = {
    position: "absolute",
    // top: "90px",
    // left: 0,
    marginTop: "90px",
    marginLeft: "0px",
    paddingLeft: "0px",
  };

  const [homeMapBounds, setHomeMapBounds] = useState({
    // longitude: -99.458333,
    // latitude: 31.243333,
    longitude: -77.6413,
    latitude: 39.0458,
    zoom: 7,
  });

  const [baseMap, setBaseMap] = useState("mapbox://styles/mapbox/streets-v11");

  const [zoomLevel, setZoomLevel] = useState(4);

  const [showRegionParking, setShowRegionParking] = useState(true);
  const [showLandUse, setShowLandUse] = useState(false);
  function getFilteredStatewideData() {
    let statewideDataTemp = query.statewideData;
    if (parseInt(query.selectedVehicleWeightClass) !== 0) {
      statewideDataTemp = statewideDataTemp.filter(
        (filter) =>
          // filter.dow_desc === selectedDayType &&
          filter.vehicleweightclass ===
          parseInt(query.selectedVehicleWeightClass)
        // vehicleWeightClassOptions[selectedVehicleWeightClass].value
      );
    }
    if (query.selectedDayType !== "All Daytypes") {
      statewideDataTemp = statewideDataTemp.filter(
        (filter) =>
          // filter.dow_desc === selectedDayType &&
          filter.dow_desc === query.selectedDayType
        // vehicleWeightClassOptions[selectedVehicleWeightClass].value
      );
    }
    // if (regionSelected.regionParkingDataType === 0) return statewideDataTemp;
    // let tempData = statewideDataTemp.filter(
    //   (f) => f.parking_cat === regionSelected.regionParkingDataType
    // );
    let selectCats = query.regionSelected.parkingDurationCategories;
    // console.log("selectCats-check", selectCats, query.regionSelected);
    let tempData = statewideDataTemp;
    if (tempData)
      // console.log("selectCats-check", selectCats);
      tempData = tempData.filter((obj) => selectCats.includes(obj.parking_cat));
    return tempData;
  }

  function deckGlTooltip(layer, object) {
    // console.log("object-check", object);
    let toolTipStyle = {
      backgroundColor: "#e1e7ed",
      color: "#000000",
      fontSize: "1.0em",
    };
    if (layer.id === "statewideData-grid") {
      let vehWeightClassLabel = vehicleWeightClassOptions.filter(
        (obj) => obj.value === parseInt(query.selectedVehicleWeightClass)
      )[0].label;
      return {
        html:
          `<h7><strong>Cluster of Parking Events</strong></h7>` +
          `<br />` +
          `<h7>Count: </h7> ${object.count.toLocaleString()}` +
          `<br />` +
          query.regionSelected.selected +
          `<br />` +
          query.regionSelected.year +
          `-` +
          query.regionSelected.month +
          `<br />` +
          regionParkingDataTypes[query.regionSelected.regionParkingDataType]
            .label +
          `<br />` +
          vehWeightClassLabel +
          `<br />` +
          query.selectedDayType,
        style: toolTipStyle,
      };
    }
    // "landUse"

    if (layer.id === "statewideData") {
      let vehWeightClassLabel = vehicleWeightClassOptions.filter(
        (obj) => obj.value === object.vehicleweightclass
      )[0].label;
      return {
        html:
          `<h7><strong>Parking Event</strong></h7>` +
          `<br />` +
          `<h7>Parking Duration: </h7> ${object.minutes.toLocaleString()} minutes` +
          `<br />` +
          `<h7>Parking Duration Category: </h7> ${
            regionParkingDataTypes[query.regionSelected.regionParkingDataType]
              .label
          } minutes` +
          `<br />` +
          query.regionSelected.year +
          `-` +
          query.regionSelected.month +
          `<br />` +
          vehWeightClassLabel +
          `<br />` +
          `${object.dow_desc}`,
        style: toolTipStyle,
      };
    }
    if (layer.id === "landUse")
      return {
        html: `<h7>Land Use Type: </h7> ${object.properties.Description}`,
        style: toolTipStyle,
      };

    return;
  }

  const dataLayers = [
    {
      label: "Region Parking Data",
      key: "region-parking-data",
      visibility: showRegionParking,
      setVisibility: setShowRegionParking,
    },
    {
      label: "2010 Land Use",
      key: "land-use",
      visibility: showLandUse,
      setVisibility: setShowLandUse,
    },
  ];

  return (
    <div
      style={{
        position: "absolute",
        height: "75%",
        width: "58%",
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <DeckGL
        {...viewport}
        // initialViewState={initialViewState}
        initialViewState={{
          longitude: homeMapBounds.longitude,
          latitude: homeMapBounds.latitude,
          zoom: homeMapBounds.zoom,
          pitch: 52,
          bearing: 0,
          transitionDuration: 300,
          // transitionInterpolator: new FlyToInterpolator(),
        }}
        getTooltip={
          ({ layer, object }) =>
            // console.log("object-check", layer)
            object && deckGlTooltip(layer, object)
          //   html: `<h5>${object.properties.road}</h5><div>From: ${object.properties.from_road}</div>
          // <div>To: ${object.properties.to_road}</div>
          // <div>arank: ${object.properties.arank}</div>
          // <div>trank: ${object.properties.trank}</div>`,
          //   style: {
          //     backgroundColor: "#e1e7ed",
          //     color: "000000",
          //     fontSize: "1.0em",
          //   },
          // }
        }
        controller={true}
        ContextProvider={MapContext.Provider}
        onViewStateChange={({ viewState }) => {
          // console.log("viewState-change", viewState);
          setZoomLevel(viewState.zoom);
          // buildGeoJsonLayer();
        }}
      >
        <StaticMap
          reuseMaps
          // mapStyle={"mapbox://styles/mapbox/streets-v11"}
          mapStyle={baseMap}
          // mapboxApiAccessToken={MAPBOX_ACCESSTOKEN}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
          preventStyleDiffing={true}
        />
        {isLoading.current !== isLoading.total && (
          <Box
            sx={{ width: "90%", marginTop: "43vh", marginLeft: "5%" }}
            className={classes.boxBackground}
          >
            Loading:
            <LinearProgress variant="determinate" value={isLoading.progress} />
          </Box>
        )}

        {landUseData !== null && showLandUse && (
          //{statewideData !== null && zoomLevel >= 9 && (
          <GeoJsonLayer
            id="landUse"
            data={landUseData}
            // filled={true}
            // opacity={1}
            // pickable={true}
            // pickingRadius={5}
            // stroked={true}
            // filled={true}
            // //extruded={true}
            // pointType={"circle"}
            // lineWidthScale={20}
            // lineWidthMinPixels={5}
            // getPointRadius={100.5}
            // getLineWidth={1}
            pickable={true}
            opacity={0.8}
            stroked={true}
            filled={true}
            // radiusScale={2}
            //radiusScale={2 ** (15 - viewport.zoom)}
            // radiusMinPixels={1}
            // radiusMaxPixels={50}
            // lineWidthMinPixels={1}
            radiusMaxPixels={10}
            getRadius={6000}
            radiusScale={2}
            // getRadius={(600 * 9) / zoomLevel}
            //6000+((zoomLevel-4)*((5-6000)/(18-4)))
            // getRadius={6000 + (zoomLevel - 4) * ((5 - 6000) / (10 - 4))}
            //getElevation={500}
            // onClick={(d, event) => {
            //   setActivePopup(true);
            //   setSelectedPoint(d);
            // }}
            // onHover={(d, event) => {
            //   if (d.object !== undefined && statusGuide[0] === "TX100")
            //     setInfo(tx100Info(d.object.properties));
            //   if (d.object !== undefined && statusGuide[0] !== "TX100")
            //     setInfo(rhinoInfo(d.object.properties));
            // }}
            // getTooltip={({ object }) => object && object.message}
            getTooltip={
              ({ layer, object }) =>
                // console.log("object-check", layer)
                object && deckGlTooltip(layer, object)
              //   html: `<h5>${object.properties.road}</h5><div>From: ${object.properties.from_road}</div>
              // <div>To: ${object.properties.to_road}</div>
              // <div>arank: ${object.properties.arank}</div>
              // <div>trank: ${object.properties.trank}</div>`,
              //   style: {
              //     backgroundColor: "#e1e7ed",
              //     color: "000000",
              //     fontSize: "1.0em",
              //   },
              // }
            }
            autoHighlight={true}
            highlightColor={[160, 160, 180, 100]}
            getLineColor={[46, 49, 49]}
            getFillColor={(d) => {
              // //console.log("d-check", d);
              // if (d.properties.var1 === "II") return [255, 0, 0];
              // if (d.properties.var1 === "IE" || d.properties.var1 === "EI")
              //   return [0, 255, 0];
              // if (d.properties.var1 === "EE") return [0, 0, 255];
              // return [0, 0, 0];
              if (d.properties.Description === "Very Low Density Residential")
                return [247, 220, 180, 150];
              if (d.properties.Description === "Low Density Residential")
                return [255, 204, 0, 150];
              if (d.properties.Description === "Medium Density Residential")
                return [255, 153, 0, 150];
              if (d.properties.Description === "High Density Residential")
                return [255, 102, 0, 150];
              if (d.properties.Description === "Commercial")
                return [255, 0, 0, 150];
              if (d.properties.Description === "Industrial")
                return [106, 13, 173, 150];
              if (d.properties.Description === "Institutional")
                return [51, 102, 153, 150];
              if (d.properties.Description === "Other Developed Lands")
                return [126, 126, 126, 150];
              if (d.properties.Description === "Agriculture")
                return [0, 255, 0, 150];
              if (d.properties.Description === "Transportation")
                return [147, 0, 255, 150];
              return [0, 255, 0, 150];
            }}
            // updateTriggers={{
            //   getRadius: [viewport.zoom],
            // }}
            // transitions={{
            //   zoomLevel: 500,
            // }}
          />
        )}

        {/* {statewideData !== null && (
          //{statewideData !== null && zoomLevel >= 9 && (
          <GeoJsonLayer
            id="statewideData"
            data={statewideData}
            // filled={true}
            // opacity={1}
            // pickable={true}
            // pickingRadius={5}
            // stroked={true}
            // filled={true}
            // //extruded={true}
            // pointType={"circle"}
            // lineWidthScale={20}
            // lineWidthMinPixels={5}
            // getPointRadius={100.5}
            // getLineWidth={1}
            pickable={true}
            opacity={0.8}
            stroked={true}
            filled={true}
            // radiusScale={2}
            //radiusScale={2 ** (15 - viewport.zoom)}
            // radiusMinPixels={1}
            // radiusMaxPixels={50}
            // lineWidthMinPixels={1}
            radiusMaxPixels={10}
            getRadius={6000}
            radiusScale={2}
            // getRadius={(600 * 9) / zoomLevel}
            //6000+((zoomLevel-4)*((5-6000)/(18-4)))
            // getRadius={6000 + (zoomLevel - 4) * ((5 - 6000) / (10 - 4))}
            //getElevation={500}
            // onClick={(d, event) => {
            //   setActivePopup(true);
            //   setSelectedPoint(d);
            // }}
            // onHover={(d, event) => {
            //   if (d.object !== undefined && statusGuide[0] === "TX100")
            //     setInfo(tx100Info(d.object.properties));
            //   if (d.object !== undefined && statusGuide[0] !== "TX100")
            //     setInfo(rhinoInfo(d.object.properties));
            // }}
            autoHighlight={true}
            highlightColor={[160, 160, 180, 10]}
            getLineColor={[46, 49, 49]}
            getFillColor={(d) => {
              //console.log("d-check", d);
              if (d.properties.var1 === "II") return [255, 0, 0];
              if (d.properties.var1 === "IE" || d.properties.var1 === "EI")
                return [0, 255, 0];
              if (d.properties.var1 === "EE") return [0, 0, 255];
              return [0, 0, 0];
            }}
            // updateTriggers={{
            //   getRadius: [viewport.zoom],
            // }}
            // transitions={{
            //   zoomLevel: 500,
            // }}
          />
        )} */}
        {query.statewideData !== null &&
          showRegionParking &&
          zoomLevel >= 11 && (
            //{statewideData !== null && zoomLevel >= 10 && (
            <ScatterplotLayer
              id="statewideData"
              // data={statewideData.features}
              // getPosition={(d) => [d.properties.long, d.properties.lat]}
              data={getFilteredStatewideData()}
              getPosition={(d) => [d.long, d.lat]}
              pickable={true}
              opacity={0.8}
              // stroked={true}
              filled={true}
              radiusScale={1}
              // radiusMinPixels={1}
              radiusMaxPixels={5}
              // lineWidthMinPixels={1}
              // getRadius={3000}
              getRadius={300}
              // getFillColor={(d) => {
              //   //console.log("d-check", d);
              //   if (d.parking_cat === 1) return [0, 255, 0];
              //   if (d.parking_cat === 2) return [255, 153, 51];
              //   if (d.parking_cat === 3) return [255, 0, 0];
              //   return [0, 0, 0];
              // }}
              getFillColor={(d) => {
                let colors = [
                  "#7F3C8D",
                  "#11A579",
                  "#3969AC",
                  "#F2B701",
                  "#E73F74",
                  "#80BA5A",
                  "#E68310",
                  "#008695",
                  "#CF1C90",
                  "#f97b72",
                  "#4b4b8f",
                  "#A5AA99",
                ];

                let colorTemp = colors[d.parking_cat - 1];
                let colorTempRGB = hexToRgb(colorTemp);
                return [colorTempRGB.r, colorTempRGB.g, colorTempRGB.b];
              }}
              getLineColor={(d) => [0, 0, 0]}
            />
          )}
        {query.statewideData !== null &&
          showRegionParking &&
          zoomLevel < 11 && (
            //<ScreenGridLayer
            //<GridLayer
            <GridLayer
              id="statewideData-grid"
              // data={statewideData.features}
              // getPosition={(d) => [d.properties.long, d.properties.lat]}
              data={getFilteredStatewideData()}
              getPosition={(d) => [d.long, d.lat]}
              pickable={true}
              extruded={true}
              //cellSize={10000}
              // cellSize={40000 * (4 / zoomLevel)}
              // cellSize={40000 + ((5 - 40000) / (10 - 4)) * (zoomLevel - 4)}
              cellSize={20000 + ((5 - 20000) / (11 - 1)) * (zoomLevel - 1)}
              // elevationScale={100 * (4 / zoomLevel / 2)}
              elevationScale={100 + ((2 - 100) / (11 - 1)) * (zoomLevel - 1)}
              getLineColor={[46, 49, 49]}
              opacity={0.65}
              stroked={true}
              fp64={true}
              gpuAggregation={true}
              // onAfterRender={setIsLoading(false)}
            />
          )}
        {/* {statewideData !== null && zoomLevel < 10 && (
          //<ScreenGridLayer
          //<GridLayer
          <ScreenGridLayer
            id="statewideData-grid"
            data={statewideData.features}
            pickable={true}
            extruded={true}
            // zoomLevel
            // cellSize={10000}
            // cellSize={(4 / zoomLevel) * 10000}
            cellSizePixels={10}
            // colorRange={[
            //   [0, 25, 0, 25],
            //   [0, 85, 0, 85],
            //   [0, 127, 0, 127],
            //   [0, 170, 0, 170],
            //   [0, 190, 0, 190],
            //   [0, 255, 0, 255],
            // ]}
            colorRange={[
              [241, 238, 246, 255],
              [212, 185, 218, 255],
              [201, 148, 199, 255],
              [223, 101, 176, 255],
              [221, 28, 119, 255],
              [152, 0, 67, 255],
            ]}
            // cellSize={100}
            elevationScale={100}
            getPosition={(d) => [
              d.properties.start_lon,
              d.properties.start_lat,
            ]}
            getLineColor={[46, 49, 49]}
            opacity={0.5}
            stroked={true}
          />
        )} */}
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <NavigationControl style={NAV_CONTROL_STYLE} captureScroll={true} />

            <div style={layers_CONTROL_STYLE}>
              <LayerControls
                baseMap={baseMap}
                setBaseMap={setBaseMap}
                // showRegionParking={showRegionParking}
                // setShowRegionParking={setShowRegionParking}
                // showLandUse={showLandUse}
                // setShowLandUse={setShowLandUse}
                dataLayers={dataLayers}
              />
            </div>
          </Grid>

          <Grid item xs={4}>
            <StateYrMnthSelect />
          </Grid>

          <Grid item xs={3}>
            <StateMapLegend zoomLevel={zoomLevel} />
          </Grid>
        </Grid>
        <ScaleControl style={ScaleControl_STYLE} />
      </DeckGL>
    </div>
  );
}

export default DeckglMap;
