import axios from "axios";

// export const fetchStatewideData = async () => {
//   try {
//     // const dataUrl = `${process.env.REACT_APP_API_ROOT}/truckParking/findByRegion?region=statewide`;
//     const dataUrl = `${process.env.REACT_APP_API_ROOT}/truckParking/findByRegion?region=statewide`;
//     // console.log("dataUrl-check", dataUrl);
//     const response = await axios.get(dataUrl);
//     if (response.status === 200) {
//       // response - object, eg { status: 200, message: 'OK' }
//       // console.log("success stuff", response.data);
//       return response;
//     }
//     return false;
//   } catch (err) {
//     console.error(err);
//     return false;
//   }
// };

// export const fetchParkingDataRegionByYearMonth = async (
//   region,
//   year,
//   month
// ) => {
//   try {
//     // const dataUrl = `${process.env.REACT_APP_API_ROOT}/truckParking/findByRegion?region=statewide`;
//     const dataUrl =
//       `${process.env.REACT_APP_API_ROOT}` +
//       `/truckParking/findByRegion?region=${region}&year=${year}&month=${month}`;
//     // console.log("dataUrl-check", dataUrl);
//     const response = await axios.get(dataUrl);
//     if (response.status === 200) {
//       // response - object, eg { status: 200, message: 'OK' }
//       // console.log("success stuff", response.data);
//       return response;
//     }
//     return false;
//   } catch (err) {
//     console.error(err);
//     return false;
//   }
// };

export const fetchParkingDataCountyByYearMonth = async (
  counties,
  year,
  month
) => {
  try {
    // const dataUrl = `${process.env.REACT_APP_API_ROOT}/truckParking/findByRegion?region=statewide`;
    // const dataUrl =
    //   `${process.env.REACT_APP_API_ROOT}` +
    //   `/truckParking/findByRegion?region=${region}&year=${year}&month=${month}`;
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/findByCountyYearMonth?counties=`;
    counties.map((obj, index) => {
      if (index === 0) dataUrl = dataUrl + `${obj}`;
      else dataUrl = dataUrl + `,${obj}`;
    });
    dataUrl = dataUrl + `&year=${year}&month=${month}`;
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchParkingDataByRegionYearMonth = async (
  counties,
  yearMonth
) => {
  try {
    let promises = [];
    counties.map((cnty) => {
      yearMonth.map((obj) => {
        promises.push(
          fetchParkingDataCountyByYearMonth([cnty], obj.year, obj.month)
        );
      });
    });

    axios.all(promises).then(
      axios.spread((...responses) => {
        // console.log("responses-check", responses);
        let f = [];
        responses.forEach((r) => {
          f = f.concat(r.data);
        });

        return f;
        /*               setTimeout(function() {
                setRhino({ type: "FeatureCollection", features: fc });
              }, 500);
            
 */
      })
    );

    // return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchLandUse = async () => {
  try {
    // const dataUrl = `${process.env.REACT_APP_API_ROOT}/truckParking/findByRegion?region=statewide`;
    // const dataUrl =
    //   `${process.env.REACT_APP_API_ROOT}` +
    //   `/truckParking/findByRegion?region=${region}&year=${year}&month=${month}`;
    let dataUrl = `https://opendata.arcgis.com/datasets/6cae1162da014d84a120bf4992839808_1.geojson`;
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    // console.log("response-check", response);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchParkingSitesGeom = async (siteName) => {
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/findParkingSitesGeom?parkingSiteName=${siteName}`;
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const findParkingSiteEvents = async (siteName, year, month) => {
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/findParkingSiteEvents?parkingSiteName=${siteName}&year=${year}&month=${month}`;
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const findSiteUnoffRatioByDirYearMonthDay = async (
  siteName,
  direction,
  year,
  month
) => {
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/findSiteUnoffRatioByDirYearMonthDay?parkingSiteName=${siteName}&direction=${direction}&year=${year}&month=${month}`;
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const findSiteOffUnoffByYearMonthDay = async (
  siteName,
  // direction,
  year
) => {
  try {
    // let dataUrl =
    //   `${process.env.REACT_APP_API_ROOT}` +
    //   `/truckParking/findSiteOffUnoffByYearMonthDay?parkingSiteName=${siteName}&direction=${direction}&year=${year}`;
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/findSiteOffUnoffByYearMonthDay?parkingSiteName=${siteName}&year=${year}`;
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const findSiteOffUnoffByYearMonthHour = async (siteName, year) => {
  try {
    // let dataUrl =
    //   `${process.env.REACT_APP_API_ROOT}` +
    //   `/truckParking/findSiteOffUnoffByYearMonthHour?parkingSiteName=${siteName}&direction=${direction}&year=${year}`;
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/findSiteOffUnoffByYearMonthHour?parkingSiteName=${siteName}&year=${year}`;
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchStateOverTime = async () => {
  try {
    // const dataUrl = `${process.env.REACT_APP_API_ROOT}/truckParking/findByRegion?region=statewide`;
    // const dataUrl =
    //   `${process.env.REACT_APP_API_ROOT}` +
    //   `/truckParking/findByRegion?region=${region}&year=${year}&month=${month}`;
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` + `/truckParking/fetchStateOverTime?`;
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchExpansionFactorsSiteYearMonthy = async (
  siteName,
  // direction,
  year,
  month
) => {
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/fetchExpansionFactorsSiteYearMonthy?parkingSiteName=${siteName}&year=${year}&month=${month}`;
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const findSiteAvgHourParkingByYearMonth = async (
  siteName,
  // direction,
  year,
  month
) => {
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/fetchSitesAvgHrParkingByYearMonth?parkingSiteName=${siteName}&year=${year}&month=${month}`;
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
