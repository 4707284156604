import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import SitesLayout from "./SitesLayout";
import StatewideLayout from "./StatewideLayout";
import {
  yearsMonths,
  geographies,
  sitesYearsMonths,
  parkingSites,
} from "../common/constants";

// import { QueryContext } from "../context/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../context/GlobalContext";
import {
  // fetchStatewideData,
  // fetchParkingDataRegionByYearMonth,
  // fetchParkingDataCountyByYearMonth,
  // fetchParkingDataByRegionYearMonth,
  // fetchLandUse,
  fetchParkingSitesGeom,
  // findParkingSiteEvents,
  // findSiteUnoffRatioByDirYearMonthDay,
  // findSiteOffUnoffByYearMonthDay,
  // findSiteOffUnoffByYearMonthHour,
  // fetchExpansionFactorsSiteYearMonthy,
} from "../data-loaders/DataLoaders";

function Layout(props) {
  // console.log("Layout-call-check");
  const { statusGuide } = props;
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();
  // console.log("query-test-check", query.selectedSite);
  // console.log("statusGuide-layout-check", statusGuide);

  // const [parkingSiteGeom, setParkingSiteGeom] = useState(null);
  // if (parkingSites.filter((itm) => itm.name === statusGuide).length > 0) {
  if (query.selectedSite.selected) {
    // if (query.selectedSite.selected !== statusGuide) {
    //   let tempSelection = query.selectedSite;
    //   // console.log("query test good");
    //   dispatch({
    //     type: "setSelectedSite",
    //     selection: { ...tempSelection, selected: statusGuide },
    //   });
    // }

    return <SitesLayout />;
  } else {
    return <StatewideLayout />;
  }
}

export default Layout;
