import React from "react";
import { render } from "react-dom";
// Import Highcharts
// import Highcharts from 'highcharts/highstock'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);

export default function TimeSeriesChart(props) {
  let data = [
    [1, 659966],
    [2, 386379],
    [3, 154471],
    [4, 104445],
    [5, 66264],
    [6, 48456],
    [7, 38939],
    [8, 36019],
    [9, 39458],
    [10, 41496],
    [11, 66779],
    [12, 53044],
    [13, 41753],
    [14, 31240],
    [15, 24257],
    [16, 18352],
    [17, 12266],
    [18, 18846],
    [19, 12704],
    [20, 7460],
    [21, 6972],
    [22, 5792],
    [23, 6018],
    [24, 6813],
    [25, 987],
    [26, 626],
    [27, 508],
    [28, 471],
    [29, 426],
    [30, 497],
    [31, 505],
    [32, 654],
    [33, 891],
    [34, 659],
    [35, 882],
    [36, 1076],
    [37, 901],
    [38, 755],
    [39, 363],
    [40, 175],
    [41, 144],
    [42, 254],
    [43, 206],
    [44, 143],
    [45, 108],
    [46, 113],
    [47, 103],
  ];

  const {
    themeHC,
    chartHeight,
    chartData,
    titleText,
    xAxisCategories,
    plotOptionsColumnStacking,
    plotOptionsColumnDataLabelsEndabled,
    chartRef3,
    // seriesName,
    yAxisMax,
    subtitleText,
    yAxisTitleText,
    xAxisTitleText,
    xAxisType,
    seriesType,
    seriesName,
    seriesData,
  } = props;

  const options = {
    chart: {
      zoomType: "x",
      height: chartHeight,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: titleText,
    },
    subtitle: {
      text:
        document.ontouchstart === undefined
          ? "Click and drag in the plot area to zoom in"
          : "Pinch the chart to zoom in",
    },
    xAxis: {
      type: xAxisType,
      title: {
        text: xAxisTitleText,
      },
      labels: {
        enabled: true,
        formatter: function () {
          //   return data[this.value][0];
          return "< " + this.value;
        },
      },
    },
    yAxis: {
      title: {
        text: yAxisTitleText,
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },

    series: [
      {
        type: seriesType,
        name: seriesName,
        data: seriesData,
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
