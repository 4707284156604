/**
 * @fileOverview the main UI component
 */

import React, { useState, useRef, useEffect, useMemo } from "react";

import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CssBaseline from "@material-ui/core/CssBaseline";
// import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeTheme, sidebarStyles, gridStyles } from "./ui/styles";
import "./App.scss";

import { GlobalProvider } from "./context/GlobalContext.js";
// import StatewideLayout from "./layouts/StatewideLayout";
// import SitesLayout from "./layouts/SitesLayout";
import Layout from "./layouts/Layout";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuIcon from "@material-ui/icons/Menu";
// import AccountCircle from "@material-ui/icons/AccountCircle";

import { geographies, parkingSites } from "./common/constants";

import Grid from "@material-ui/core/Grid";

import AppBarUI from "./ui/AppBarUI";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode = prefersDarkMode ? "dark" : "light";
  let theme = makeTheme(mode);
  theme = responsiveFontSizes(theme);
  const classes = sidebarStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <GlobalProvider>
          <AppBarUI />
          <main className={classes.content}>
            <div className={classes.toolbar} />

            {/* {statusGuide && statusType === "region" && (
              <StatewideLayout statusGuide={statusGuide} />
            )}
            
            {statusType === "site" && <SitesLayout statusGuide={statusGuide} />} */}
            <Layout />
          </main>
        </GlobalProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
