import React from "react";
import { render } from "react-dom";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);

export default function Donut3dChart(props) {
  const {
    chartHeight,
    titleText,
    subtitleText,
    pieInnerSize,
    pieDepth,
    chartData,
  } = props;

  const options = {
    chart: {
      type: "pie",
      height: chartHeight,
      options3d: {
        enabled: true,
        alpha: 45,
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: titleText,
    },
    subtitle: {
      text: subtitleText,
    },
    plotOptions: {
      pie: {
        innerSize: pieInnerSize,
        depth: pieDepth,
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.2f} %",
        },
      },
    },
    series: chartData,
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
