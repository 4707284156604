import React, {
  useState,
  // useEffect,
  // useRef,
  useContext,
  useEffect,
  // useMemo,
  //    useCallback
} from "react";
// import axios from "axios";
// import { RhinoQueryContext } from "./RhinoQueryContext";
import DeckGL from "@deck.gl/react";
// import { FlyToInterpolator } from "@deck.gl/core";
import {
  ScatterplotLayer,
  // LineLayer,
  GeoJsonLayer,
} from "@deck.gl/layers";
import { hexToRgb } from "../utils/utils";
// import colorCategories from "@deck.gl/carto/src/style/color-categories-style.js";
import {
  GridLayer,
  // ScreenGridLayer,
  // ContourLayer,
} from "@deck.gl/aggregation-layers";
import {
  StaticMap,
  MapContext,
  NavigationControl,
  // Popup,
  ScaleControl,
  WebMercatorViewport,
} from "react-map-gl";
// import {
//   API_ROOT,
//   COLOR_RHINO,
//   MAPBOX_ACCESSTOKEN,
//   MAPBOX_ATTRIBUTION,
//   // MAPBOX_URL,
//   // MAPBOX_STREETS_V10_URL,
//   tx100_monitored_counties,
//   baseLayers,
//   colorContextlayers,
// } from "../common/constants";

// import * as d3 from "d3";
import { makeStyles, Dialog, colors } from "@material-ui/core";

// import CircularProgress from "@mui/material/CircularProgress";
// import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

// import { PathLayer } from "@deck.gl/layers";

import bbox from "@turf/bbox";

// import { sampleStateData } from "../TestData/sampleStateData";

// import axios from "axios";

// import { QueryContext } from "../context/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../context/GlobalContext";

import DataParametersSites from "./DataParametersSites";

// import { yearsMonths, geographies } from "../common/constants";

import LayerControls from "./contols/LayerControls";
import { chainPropTypes } from "@mui/utils";

import Grid from "@material-ui/core/Grid";

import SiteYrMnthSelect from "./SiteYrMnthSelect";
import SiteMapLegend from "./SiteMapLegend";

const useStyles = makeStyles({
  deckglMap: {
    position: "relative",
  },
  dialog: {
    position: "absolute",
    left: "92%",
    top: "50%",
    transform: "translate(-75%,-50%)",
  },
  dialog2: {
    position: "absolute",
    left: "69vw",
    top: "27vh",
  },
  dataParameters: {
    position: "absolute",
    left: "94%",
    top: "35%",
    transform: "translate(-75%,-50%)",
    color: "black",
  },
  boxBackground: {
    backgroundColor: "white",
    color: "blue",
    fontSize: 12,
  },
});

function DeckglMapSites(props) {
  // console.log("DeckglMapSites-fire-check");
  const classes = useStyles();
  const {
    // statusGuide,
    // regionSelected,
    // setRegionSelected,
    // statewideData,
    // isLoading,
    // landUseData,
    // parkingSiteGeom,
    // siteEventsGeom,
    siteSelected,
    setSiteSelected,
  } = props;
  // console.log("statewideData-check", statewideData);
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const [viewport, setViewport] = useState({
    // height: "500px",
    //height: "65vh",
    // height: "65%",
    // width: "100%",
    //initViewport
    width: "100%",
    height: "100%",
  });

  const NAV_CONTROL_STYLE = {
    position: "absolute",
    // top: "5px",
    // left: "5px",
    marginTop: "10px",
    marginLeft: "12px",
  };
  const ScaleControl_STYLE = {
    position: "absolute",
    top: "96%",
    left: "125px",
  };
  const layers_CONTROL_STYLE = {
    position: "absolute",
    // top: "90px",
    // left: 0,
    marginTop: "90px",
    marginLeft: "0px",
    paddingLeft: "0px",
  };

  const [homeMapBounds, setHomeMapBounds] = useState({
    // longitude: -99.458333,
    // latitude: 31.243333,
    longitude: -76.6413,
    latitude: 39.0458,
    zoom: 7,
  });

  function getBoundsForRoads(mapBounds_bbox) {
    // Calculate corner values of bounds
    // const pointsLong = points.map((point) => point.coordinates._long);
    // const pointsLat = points.map((point) => point.coordinates._lat);
    // const cornersLongLat = [
    //   [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
    //   [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    // ];
    const cornersLongLat = [
      [mapBounds_bbox[0], mapBounds_bbox[1]],
      [mapBounds_bbox[2], mapBounds_bbox[3]],
    ];
    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({
      width: 800,
      height: 800,
    }).fitBounds(cornersLongLat, { padding: 100 }); // Can also use option: offset: [0, -100]
    // const viewport = new WebMercatorViewport({
    //   width: 800,
    //   height: 600,
    // }).fitBounds(mapBounds_bbox, { padding: 200 }); // Can also use option: offset: [0, -100]
    const { longitude, latitude, zoom } = viewport;
    return { longitude, latitude, zoom: zoom - 0.5 };
  }

  useEffect(() => {
    if (query.parkingSiteGeom) {
      const mapBounds_bbox = bbox(query.parkingSiteGeom);
      setHomeMapBounds(getBoundsForRoads(mapBounds_bbox));
    }
  }, [query.parkingSiteGeom]);
  const [baseMap, setBaseMap] = useState("mapbox://styles/mapbox/streets-v11");

  const [zoomLevel, setZoomLevel] = useState(4);

  const [showRegionParking, setShowRegionParking] = useState(true);

  function deckGlTooltip(layer, object) {
    // console.log("object-check", object);
    let toolTipStyle = {
      backgroundColor: "#e1e7ed",
      color: "#000000",
      fontSize: "1.0em",
    };
    if (layer.id === "parkingSiteGeom-authorized")
      return {
        html:
          `<h7><strong>Parking Area</strong></h7>` +
          `<br />` +
          `<h7>Name: </h7> ${object.properties.name}` +
          `<br />` +
          `Authorized Area` +
          `<br />` +
          `<h7>Direction: </h7> ${object.properties.dir}` +
          `<br />` +
          `<h7>Number of spaces: </h7> ${object.properties.space}`,
        style: toolTipStyle,
      };
    // "landUse"
    if (layer.id === "parkingSiteGeom-unauthorized")
      return {
        html:
          `<h7><strong>Parking Area</strong></h7>` +
          `<br />` +
          `<h7>Name: </strong></h7> ${object.properties.name}` +
          `<br />` +
          `Unauthorized Area` +
          `<br />` +
          `<h7>Direction: </h7> ${object.properties.dir}`,
        style: toolTipStyle,
      };
    if (layer.id === "siteEvents")
      return {
        html:
          `<h7><strong>Parking Event</strong></h7>` +
          `<br />` +
          `<h7>Site: </h7> ${object.sitename}` +
          `<br />` +
          `${object.authorized} area` +
          `<br />` +
          `<h7>Direction: </h7> ${object.dir}` +
          `<br />` +
          `<h7>Parking Duration: </h7> ${object.parking_duration}` +
          `<br />` +
          `<h7>Parked Time: </h7> ${object.total_parked_time}`,

        style: toolTipStyle,
      };

    //siteEvents

    return;
  }

  function parkingSiteGeomFilter(category) {
    if (query.parkingSiteGeom) {
      let filtered = query.parkingSiteGeom.features.filter(
        (f) => f.properties.authorized === category
      );
      //   console.log("filtered-check", filtered);
      return {
        type: "FeatureCollection",
        features: filtered,
      };
    }
  }

  const [showSiteParkingEvents, setShowSiteParkingEvents] = useState(true);
  const [showAuthorizedParkingArea, setShowAuthorizedParkingArea] =
    useState(true);
  const [showUnauthorizedParkingArea, setShowUnauthorizedParkingArea] =
    useState(true);
  const dataLayers = [
    {
      label: "Site Parking Events",
      key: "site-parking-events",
      visibility: showSiteParkingEvents,
      setVisibility: setShowSiteParkingEvents,
    },
    {
      label: "Authorized Parking Area",
      key: "authorized-parking-area",
      visibility: showAuthorizedParkingArea,
      setVisibility: setShowAuthorizedParkingArea,
    },
    {
      label: "Unauthorized Parking Area",
      key: "unauthorized-parking-area",
      visibility: showUnauthorizedParkingArea,
      setVisibility: setShowUnauthorizedParkingArea,
    },
  ];

  const filterSiteEventsGeom = () => {
    let selectCats = query.selectedSite.parkingDurationCategories;
    if (query.siteEventsGeom)
      // console.log("selectCats-check", selectCats);
      return query.siteEventsGeom.filter((obj) =>
        selectCats.includes(obj.parking_cat)
      );
  };

  const [siteEventsFilteredGeom, setSiteEventsFilteredGeom] = useState(null);
  useEffect(() => {
    let selectCats = query.selectedSite.parkingDurationCategories;
    // console.log("selectCats-check", selectCats);
    if (query.siteEventsGeom)
      setSiteEventsFilteredGeom(
        query.siteEventsGeom.filter((obj) =>
          selectCats.includes(obj.parking_cat)
        )
      );
    // setSiteEventsFilteredGeom(
    //   siteEventsGeom.filter(
    //     (obj) => obj.parking_cat === siteSelected.parkingDataType
    //   )
    // );
  }, [query.selectedSite]);
  const [authorizedParkingSiteGeom, setAuthorizedParkingSiteGeom] =
    useState(null);
  const [unauthorizedParkingSiteGeom, setUnauthorizedParkingSiteGeom] =
    useState(null);
  useEffect(() => {
    setAuthorizedParkingSiteGeom(parkingSiteGeomFilter(1));
    setUnauthorizedParkingSiteGeom(parkingSiteGeomFilter(0));
  }, [query.parkingSiteGeom]);

  return (
    <div
      style={{
        // position: "relative",
        // height: "100%",
        // width: "100%",
        position: "absolute",
        height: "75%",
        width: "58%",
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <DeckGL
        {...viewport}
        // initialViewState={initialViewState}
        initialViewState={{
          longitude: homeMapBounds.longitude,
          latitude: homeMapBounds.latitude,
          zoom: homeMapBounds.zoom,
          pitch: 0,
          bearing: 0,
          transitionDuration: 300,
          // transitionInterpolator: new FlyToInterpolator(),
        }}
        getTooltip={({ layer, object }) =>
          object && deckGlTooltip(layer, object)
        }
        controller={true}
        // layers={newLayers2}
        // layers={[layers2]}
        // layers={[layers3]}
        ContextProvider={MapContext.Provider}
        onViewStateChange={({ viewState }) => {
          // console.log("viewState-change", viewState);
          setZoomLevel(viewState.zoom);
          // buildGeoJsonLayer();
        }}
      >
        <StaticMap
          reuseMaps
          // mapStyle={"mapbox://styles/mapbox/streets-v11"}
          mapStyle={baseMap}
          // mapboxApiAccessToken={MAPBOX_ACCESSTOKEN}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
          preventStyleDiffing={true}
        />
        {/* {isLoading.current !== isLoading.total && (
          <Box
            sx={{ width: "90%", marginTop: "43vh", marginLeft: "5%" }}
            className={classes.boxBackground}
          >
            Loading:
            <LinearProgress variant="determinate" value={isLoading.progress} />
          </Box>
        )} */}
        {showAuthorizedParkingArea && (
          <GeoJsonLayer
            id="parkingSiteGeom-authorized"
            //   data={parkingSiteGeom}
            data={authorizedParkingSiteGeom}
            //   visible={query.contextLayersVisibility.districtBoundaries}
            filled={true}
            opacity={0.25}
            pickable={true}
            pickingRadius={5}
            stroked={true}
            //extruded={true}
            pointType={"circle"}
            lineWidthScale={5}
            lineWidthMinPixels={0.1}
            getPointRadius={0.5}
            getLineWidth={0.5}
            //getElevation={500}
            // onClick={(d, event) => {
            //   setActivePopup(true);
            //   setSelectedPoint(d);
            // }}
            // onHover={(d, event) => {
            //   if (d.object !== undefined && statusGuide[0] === "TX100")
            //     setInfo(tx100Info(d.object.properties));
            //   if (d.object !== undefined && statusGuide[0] !== "TX100")
            //     setInfo(rhinoInfo(d.object.properties));
            // }}
            autoHighlight={true}
            highlightColor={[160, 160, 180, 10]}
            getLineColor={[46, 49, 49]}
            getFillColor={"#90ee90"
              .match(/[0-9a-f]{2}/g)
              .map((x) => parseInt(x, 16))}
          />
        )}
        {showUnauthorizedParkingArea && (
          <GeoJsonLayer
            id="parkingSiteGeom-unauthorized"
            //   data={parkingSiteGeom}
            data={unauthorizedParkingSiteGeom}
            //   visible={query.contextLayersVisibility.districtBoundaries}
            filled={true}
            opacity={0.001}
            pickable={true}
            pickingRadius={5}
            stroked={true}
            //extruded={true}
            pointType={"circle"}
            lineWidthScale={5}
            lineWidthMinPixels={0.1}
            getPointRadius={0.5}
            getLineWidth={0.75}
            //getElevation={500}
            // onClick={(d, event) => {
            //   setActivePopup(true);
            //   setSelectedPoint(d);
            // }}
            // onHover={(d, event) => {
            //   if (d.object !== undefined && statusGuide[0] === "TX100")
            //     setInfo(tx100Info(d.object.properties));
            //   if (d.object !== undefined && statusGuide[0] !== "TX100")
            //     setInfo(rhinoInfo(d.object.properties));
            // }}
            autoHighlight={true}
            highlightColor={[160, 160, 180, 10]}
            getLineColor={[255, 0, 0, 2000]}
            getFillColor={"#90ee90"
              .match(/[0-9a-f]{2}/g)
              .map((x) => parseInt(x, 16))}
          />
        )}
        {showSiteParkingEvents && (
          <ScatterplotLayer
            id="siteEvents"
            // data={statewideData.features}
            // getPosition={(d) => [d.properties.long, d.properties.lat]}
            data={filterSiteEventsGeom()}
            getPosition={(d) => [d.long, d.lat]}
            pickable={true}
            opacity={0.8}
            //   stroked={true}
            filled={true}
            //   radiusScale={1}
            //   radiusMinPixels={1000}
            //   radiusMaxPixels={2}
            // lineWidthMinPixels={1}
            // getRadius={3000}
            getRadius={1}
            getLineWiddth={0.0005}
            radiusScale={8}
            radiusMinPixels={1}
            radiusMaxPixels={5}
            lineWidthMinPixels={1}
            //   getFillColor={(d) => {
            //     //console.log("d-check", d);
            //     // if (d.parking_cat === 1) return [0, 255, 0];
            //     // if (d.parking_cat === 2) return [255, 153, 51];
            //     // if (d.parking_cat === 3) return [255, 0, 0];
            //     // return [0, 0, 0];
            //     // return [
            //     //   (d.parking_cat - 1) * 63,
            //     //   255 - (d.parking_cat - 1) * 63,
            //     //   (d.parking_cat - 1) * 63,
            //     // ];
            //     return [
            //       (d.parking_cat - 1) * 63,
            //       255 - (d.parking_cat - 1) * 63,
            //       255 - (d.parking_cat - 1) * 63,
            //     ];
            //   }}
            getFillColor={(d) => {
              let colors = [
                "#7F3C8D",
                "#11A579",
                "#3969AC",
                "#F2B701",
                "#E73F74",
                "#80BA5A",
                "#E68310",
                "#008695",
                "#CF1C90",
                "#f97b72",
                "#4b4b8f",
                "#A5AA99",
              ];

              let colorTemp = colors[d.parking_cat - 1];
              let colorTempRGB = hexToRgb(colorTemp);
              return [colorTempRGB.r, colorTempRGB.g, colorTempRGB.b];
            }}
            getLineColor={(d) => [0, 0, 0]}
          />
        )}
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <NavigationControl style={NAV_CONTROL_STYLE} captureScroll={true} />
            <div style={layers_CONTROL_STYLE}>
              <LayerControls
                baseMap={baseMap}
                setBaseMap={setBaseMap}
                dataLayers={dataLayers}
                // showSiteParkingEvents={showSiteParkingEvents}
                // setShowRegionParking={setShowSiteParkingEvents}
                // showAuthorizedParkingArea={showAuthorizedParkingArea}
                // setShowAuthorizedParkingArea={setShowAuthorizedParkingArea}
                // showAuthorizedParkingArea={showAuthorizedParkingArea}
                // setShowAuthorizedParkingArea={setShowAuthorizedParkingArea}
              />
            </div>
          </Grid>

          <Grid item xs={4}>
            <SiteYrMnthSelect
              siteSelected={siteSelected}
              setSiteSelected={setSiteSelected}
            />
          </Grid>

          <Grid item xs={3}>
            <SiteMapLegend
              siteSelected={siteSelected}
              setSiteSelected={setSiteSelected}
              dataLayers={dataLayers}
            />
          </Grid>
        </Grid>

        <ScaleControl style={ScaleControl_STYLE} />
      </DeckGL>
    </div>
  );
}

export default DeckglMapSites;
