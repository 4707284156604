import React from "react";
import { render } from "react-dom";
// Import Highcharts
// import Highcharts from 'highcharts/highstock'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import Boost from "highcharts/modules/boost";
// Boost(Highcharts);

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

export default function ScatterPlot(props) {
  const {
    themeHC,
    chartHeight,

    chartData,
    titleText,
    xAxisCategories,
    plotOptionsColumnStacking,
    plotOptionsColumnDataLabelsEndabled,
    chartRef3,
    // seriesName,
    yAxisMax,
    subtitleText,
    yAxisTitleText,
    xAxisTitleText,
    xAxisType,
    seriesType,
    seriesName,
    seriesData,
    lineName,
    lineData,
    seriesName1,
    seriesData1,
    seriesName2,
    seriesData2,
    seriesName3,
    seriesData3,
    seriesName4,
    seriesData4,
    seriesName5,
    seriesData5,
    seriesName6,
    seriesData6,
    seriesName7,
    seriesData7,
  } = props;
  // console.log("seriesData1-check", seriesData1);
  const hourLookups = [
    { hour: 0, hourDesc: "12:00 a.m." },
    { hour: 1, hourDesc: "1:00 a.m." },
    { hour: 2, hourDesc: "2:00 a.m." },
    { hour: 3, hourDesc: "3:00 a.m." },
    { hour: 4, hourDesc: "4:00 a.m." },
    { hour: 5, hourDesc: "5:00 a.m." },
    { hour: 6, hourDesc: "6:00 a.m." },
    { hour: 7, hourDesc: "7:00 a.m." },
    { hour: 8, hourDesc: "8:00 a.m." },
    { hour: 9, hourDesc: "9:00 a.m." },
    { hour: 10, hourDesc: "10:00 a.m." },
    { hour: 11, hourDesc: "11:00 a.m." },
    { hour: 12, hourDesc: "12:00 p.m." },
    { hour: 13, hourDesc: "1:00 p.m." },
    { hour: 14, hourDesc: "2:00 p.m." },
    { hour: 15, hourDesc: "3:00 p.m." },
    { hour: 16, hourDesc: "4:00 p.m." },
    { hour: 17, hourDesc: "5:00 p.m." },
    { hour: 18, hourDesc: "6:00 p.m." },
    { hour: 19, hourDesc: "7:00 p.m." },
    { hour: 20, hourDesc: "8:00 p.m." },
    { hour: 21, hourDesc: "9:00 p.m." },
    { hour: 22, hourDesc: "10:00 p.m." },
    { hour: 23, hourDesc: "11:00 p.m." },
    { hour: 24, hourDesc: "12:00 a.m." },
  ];

  const colors = Highcharts.getOptions().colors;
  //   console.log("colors-check", colors);

  const options = {
    chart: {
      type: "scatter",
      zoomType: "xy",
      //   zoomType: "x",
      height: chartHeight,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      chartOptions: {
        subtitle: { text: "" },
        legend: {
          enabled: false,
        },
      },
      height: 2000,
      width: 2000,
    },
    title: {
      text: titleText,
    },
    subtitle: {
      text:
        document.ontouchstart === undefined
          ? " <br/> <br/> <br/>Click and drag in the plot area to zoom in "
          : "Pinch the chart to zoom in",
    },
    xAxis: {
      type: xAxisType,
      title: {
        text: xAxisTitleText,
      },
      labels: {
        enabled: true,
        formatter: function () {
          //   return data[this.value][0];
          //   let hrDesc = hourLookups
          //     .filter((obj) => obj.hour === this.value)
          //     .map((f) => {
          //       return f[0].hourDesc;
          //     })[0].hourDesc;
          //   return this.value;

          //   return hourLookups.filter((obj) => obj.hour === this.value)[0]
          //     .hourDesc;

          let hour = Math.trunc(this.value);
          let hourDesc = "";
          let amPmDesc = "";
          if (hour === 0 || hour === 24) hourDesc = "12";
          else {
            if (hour <= 12) hourDesc = hour.toString();
            else {
              hourDesc = (hour - 12).toString();
            }
          }
          if (hour < 12 || hour === 24) amPmDesc = "a.m.";
          else amPmDesc = "p.m.";

          let minutes = (this.value - hour) * 60;
          let minutesDesc = "";
          if (minutes < 10) minutesDesc = "0" + Math.trunc(minutes).toString();
          else minutesDesc = Math.trunc(minutes).toString();

          return hourDesc + ":" + minutesDesc + " " + amPmDesc;
        },
      },
    },
    yAxis: {
      title: {
        text: yAxisTitleText,
      },
      //.toLocaleString()
      labels: {
        enabled: true,
        formatter: function () {
          return this.value.toLocaleString();
        },
      },
    },
    legend: {
      enabled: false,
      layout: "horizontal",
      align: "center",
      x: 0,
      verticalAlign: "top",
      y: 30,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || // theme
        "rgba(255,255,255,0.25)",
    },
    // plotOptions: {
    //   area: {
    //     fillColor: {
    //       linearGradient: {
    //         x1: 0,
    //         y1: 0,
    //         x2: 0,
    //         y2: 1,
    //       },
    //       stops: [
    //         [0, Highcharts.getOptions().colors[0]],
    //         [
    //           1,
    //           Highcharts.color(Highcharts.getOptions().colors[0])
    //             .setOpacity(0)
    //             .get("rgba"),
    //         ],
    //       ],
    //     },
    //     marker: {
    //       radius: 2,
    //     },
    //     lineWidth: 1,
    //     states: {
    //       hover: {
    //         lineWidth: 1,
    //       },
    //     },
    //     threshold: null,
    //   },
    // },
    plotOptions: {
      scatter: {
        marker: {
          radius: 2.5,
          states: {
            hover: {
              enabled: true,
              lineColor: "rgb(100,100,100)",
            },
          },
        },
        states: {
          hover: {
            marker: {
              enabled: false,
            },
          },
        },
        tooltip: {
          pointFormatter: function () {
            // return "Default " + this.x + "</b> is <b>" + this.y + "</b>";

            let hour = Math.trunc(this.x);
            let hourDesc = "";
            let amPmDesc = "";
            if (hour === 0 || hour === 24) hourDesc = "12";
            else {
              if (hour <= 12) hourDesc = hour.toString();
              else {
                hourDesc = (hour - 12).toString();
              }
            }
            if (hour < 12 || hour === 24) amPmDesc = "a.m.";
            else amPmDesc = "p.m.";

            let minutes = (this.x - hour) * 60;
            let minutesDesc = "";
            if (minutes < 10)
              minutesDesc = "0" + Math.trunc(minutes).toString();
            else minutesDesc = Math.trunc(minutes).toString();

            return (
              hourDesc +
              ":" +
              minutesDesc +
              " " +
              amPmDesc +
              "<br/>" +
              "Parking Duration: " +
              Math.round(this.y, 0).toLocaleString() +
              " minutes"
            );
          },
          headerFormat: "<b>{series.name}</b><br>",
          //   pointFormat: "{point.x} cm, {point.y} kg",
        },
      },
      //Following toggles the visibility of each series, only allowing for one visible at a time
      series: {
        events: {
          legendItemClick: function () {
            this.chart.series.forEach(function (s) {
              if (s !== this && s.visible) {
                s.hide();
              }
            });
            // console.log("this-check", this);
            return !this.visible ? true : false;
          },
        },
      },
    },
    // series: [
    //   {
    //     type: seriesType,
    //     name: seriesName,
    //     data: seriesData,
    //   },
    //   {
    //     type: "line",
    //     name: lineName,
    //     data: lineData,
    //   },
    // ],
    series: [
      {
        name: seriesName1,
        // color: "rgba(223, 83, 83, .5)",
        color: colors[8],
        data: seriesData1,
      },
      // {
      //   name: seriesName2,
      //   // color: "rgba(119, 152, 191, .5)",
      //   color: colors[8],
      //   data: seriesData2,
      //   //Following toggles the visibility of each series, only allowing for one visible at a time
      //   visible: false,
      // },
      // {
      //   name: seriesName3,
      //   color: colors[7],
      //   data: seriesData3,
      //   //Following toggles the visibility of each series, only allowing for one visible at a time
      //   visible: false,
      // },
      // {
      //   name: seriesName4,
      //   color: colors[6],
      //   data: seriesData4,
      //   //Following toggles the visibility of each series, only allowing for one visible at a time
      //   visible: false,
      // },
      // {
      //   name: seriesName5,
      //   color: colors[5],
      //   data: seriesData5,
      //   //Following toggles the visibility of each series, only allowing for one visible at a time
      //   visible: false,
      // },
      // {
      //   name: seriesName6,
      //   color: colors[4],
      //   data: seriesData6,
      //   //Following toggles the visibility of each series, only allowing for one visible at a time
      //   visible: false,
      // },
      // {
      //   name: seriesName7,
      //   color: colors[3],
      //   data: seriesData7,
      //   //Following toggles the visibility of each series, only allowing for one visible at a time
      //   visible: false,
      // },
    ],
    // tooltip: {
    //   formatter: function () {
    //     if (this.series.name === lineName)
    //       return (
    //         "<b>Average Duration Bin: </b>" + this.x.toFixed(2)
    //         //   +
    //         //   "</b> is <b>" +
    //         //   this.y +
    //         //   "</b>, in series " +
    //         //   this.series.name
    //       );
    //     else
    //       return (
    //         "<b>Duration Bin (hours): </b>" +
    //         this.x +
    //         "<br/>" +
    //         "<b>Number of Parking Events: </b>" +
    //         this.y.toLocaleString()
    //         //   +
    //         //   "</b> is <b>" +
    //         //   this.y +
    //         //   "</b>, in series " +
    //         //   this.series.name
    //       );
    //   },
    // },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
