import React from "react";
import { render } from "react-dom";
// Import Highcharts
// import Highcharts from 'highcharts/highstock'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import Boost from "highcharts/modules/boost";
// Boost(Highcharts);

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
// require("highcharts/modules/boost")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);

export default function Column3dPlot(props) {
  const {
    themeHC,
    chartHeight,

    chartData,
    titleText,
    xAxisCategories,
    plotOptionsColumnStacking,
    plotOptionsColumnDataLabelsEndabled,
    chartRef3,
    // seriesName,
    yAxisMax,
    subtitleText,
    yAxisTitleText,
    xAxisTitleText,
    xAxisType,
    seriesType,
    seriesName,
    seriesData,
    lineName,
    lineData,
    seriesName1,
    seriesData1,
    seriesName2,
    seriesData2,
    seriesName3,
    seriesData3,
    seriesName4,
    seriesData4,
    seriesName5,
    seriesData5,
    seriesName6,
    seriesData6,
    seriesName7,
    seriesData7,
    barName1,
    barData1,
  } = props;
  // console.log("seriesData1-check", seriesData1);
  const hourLookups = [
    { hour: 0, hourDesc: "12:00 a.m." },
    { hour: 1, hourDesc: "1:00 a.m." },
    { hour: 2, hourDesc: "2:00 a.m." },
    { hour: 3, hourDesc: "3:00 a.m." },
    { hour: 4, hourDesc: "4:00 a.m." },
    { hour: 5, hourDesc: "5:00 a.m." },
    { hour: 6, hourDesc: "6:00 a.m." },
    { hour: 7, hourDesc: "7:00 a.m." },
    { hour: 8, hourDesc: "8:00 a.m." },
    { hour: 9, hourDesc: "9:00 a.m." },
    { hour: 10, hourDesc: "10:00 a.m." },
    { hour: 11, hourDesc: "11:00 a.m." },
    { hour: 12, hourDesc: "12:00 p.m." },
    { hour: 13, hourDesc: "1:00 p.m." },
    { hour: 14, hourDesc: "2:00 p.m." },
    { hour: 15, hourDesc: "3:00 p.m." },
    { hour: 16, hourDesc: "4:00 p.m." },
    { hour: 17, hourDesc: "5:00 p.m." },
    { hour: 18, hourDesc: "6:00 p.m." },
    { hour: 19, hourDesc: "7:00 p.m." },
    { hour: 20, hourDesc: "8:00 p.m." },
    { hour: 21, hourDesc: "9:00 p.m." },
    { hour: 22, hourDesc: "10:00 p.m." },
    { hour: 23, hourDesc: "11:00 p.m." },
    { hour: 24, hourDesc: "12:00 a.m." },
  ];

  const colors = Highcharts.getOptions().colors;
  //   console.log("colors-check", colors);

  const options = {
    chart: {
      // renderTo: "container",
      type: "column",
      options3d: {
        enabled: true,
        // alpha: 15,
        // beta: 15,
        // depth: 50,
        // viewDistance: 25,
        alpha: 0,
        beta: 0,
        depth: 0,
        viewDistance: 0,
      },
      //   type: "scatter",
      zoomType: "xy",
      //   zoomType: "x",
      height: chartHeight,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      chartOptions: {
        subtitle: { text: "" },
        legend: {
          enabled: false,
        },
      },
      height: 2000,
      width: 2000,
    },
    title: {
      text: titleText,
    },
    subtitle: {
      text:
        document.ontouchstart === undefined
          ? " <br/> <br/> <br/>Click and drag in the plot area to zoom in "
          : "Pinch the chart to zoom in",
    },
    xAxis: [
      {
        type: xAxisType,
        // tickInterval: 24 * 3600 * 1000 * 15,
        tickInterval: 1,
        title: {
          text: xAxisTitleText,
        },
        labels: {
          enabled: true,
          formatter: function () {
            //   return data[this.value][0];
            //   let hrDesc = hourLookups
            //     .filter((obj) => obj.hour === this.value)
            //     .map((f) => {
            //       return f[0].hourDesc;
            //     })[0].hourDesc;
            //   return this.value;

            //   return hourLookups.filter((obj) => obj.hour === this.value)[0]
            //     .hourDesc;

            let hour = Math.trunc(this.value);
            let hourDesc = "";
            let amPmDesc = "";
            if (hour === 0 || hour === 24) hourDesc = "12";
            else {
              if (hour <= 12) hourDesc = hour.toString();
              else {
                hourDesc = (hour - 12).toString();
              }
            }
            if (hour < 12 || hour === 24) amPmDesc = "a.m.";
            else amPmDesc = "p.m.";

            let minutes = (this.value - hour) * 60;
            let minutesDesc = "";
            if (minutes < 10)
              minutesDesc = "0" + Math.trunc(minutes).toString();
            else minutesDesc = Math.trunc(minutes).toString();

            return hourDesc + ":" + minutesDesc + " " + amPmDesc;
          },
        },
      },
    ],
    yAxis: [
      {
        //Secondary Axis
        title: {
          text: "Number of Parking Events",
        },
        //.toLocaleString()
        labels: {
          enabled: true,
          formatter: function () {
            return this.value.toLocaleString();
          },
        },
      },
    ],
    legend: {
      enabled: false,
      layout: "horizontal",
      align: "center",
      x: 0,
      verticalAlign: "top",
      y: 30,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || // theme
        "rgba(255,255,255,0.25)",
    },
    plotOptions: {
      column: {
        depth: 25,
      },
    },

    series: [
      {
        name: barName1,
        // color: "rgba(223, 83, 83, .5)",
        color: colors[7],
        data: barData1,
        pointPadding: 0,
        groupPadding: 0.05,
      },
    ],
    tooltip: {
      formatter: function () {
        if (this.series.name === lineName)
          return (
            "<b>Average Duration Bin: </b>" + this.x.toFixed(2)
            //   +
            //   "</b> is <b>" +
            //   this.y +
            //   "</b>, in series " +
            //   this.series.name
          );
        else
          return (
            // "<b>Duration Bin (hours): </b>" +
            "<b>" +
            hourLookups.filter((obj) => obj.hour === this.x)[0].hourDesc +
            " to " +
            hourLookups.filter((obj) => obj.hour === this.x + 1)[0].hourDesc +
            "</b>" +
            // this.x +
            "<br/>" +
            "<b>Number of Parking Events: </b>" +
            this.y.toLocaleString()
            //   +
            //   "</b> is <b>" +
            //   this.y +
            //   "</b>, in series " +
            //   this.series.name
          );
      },
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
