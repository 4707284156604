import React, { useState, useContext } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";

import { sitesYearsMonths, sitesYears, sitesMonths } from "../common/constants";

// import { QueryContext } from "../context/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../context/GlobalContext";

export default function SiteYrMnthSelect(props) {
  const { siteSelected, setSiteSelected } = props;
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const handleChangeYear = (event) => {
    let yearMonthTemp = sitesYearsMonths.filter(
      (obj) =>
        obj.year === event.target.value &&
        obj.month === query.selectedSite.month
    )[0].yearMonth;
    // setSiteSelected({
    //   selected: siteSelected.selected,
    //   year: event.target.value,
    //   month: siteSelected.month,
    //   yearMonth: yearMonthTemp,
    //   parkingDataType: siteSelected.parkingDataType,
    //   parkingDurationCategories: siteSelected.parkingDurationCategories,
    // });
    dispatch({
      type: "setSelectedSite",
      selection: {
        ...query.selectedSite,
        year: event.target.value,
        yearMonth: yearMonthTemp,
      },
    });
  };

  const handleChangeMonth = (event) => {
    let yearMonthTemp = sitesYearsMonths.filter(
      (obj) =>
        obj.year === query.selectedSite.year && obj.month === event.target.value
    )[0].yearMonth;
    // setSiteSelected({
    //   selected: siteSelected.selected,
    //   year: siteSelected.year,
    //   month: event.target.value,
    //   yearMonth: yearMonthTemp,
    //   parkingDataType: siteSelected.parkingDataType,
    //   parkingDurationCategories: siteSelected.parkingDurationCategories,
    // });
    dispatch({
      type: "setSelectedSite",
      selection: {
        ...query.selectedSite,
        month: event.target.value,
        yearMonth: yearMonthTemp,
      },
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        // height: 300,
        // backgroundColor: "#D3D3D3",
        backgroundColor: "#FFFFFF",
        borderRadius: 4,
        // backgroundColor: "primary.dark",
        // "&:hover": {
        //   backgroundColor: "primary.main",
        //   opacity: [0.9, 0.8, 0.7],
        // },
        // marginRight: 2,
        // marginTop: "10px",
        marginLeft: 0,
        paddingLeft: 0,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FormControl sx={{ m: 1, mt: 2 }} size="small">
        <InputLabel id="sites-year-label">Year:</InputLabel>
        <Select
          labelId="sites-year-label-small"
          id="sites-year-select-small"
          value={query.selectedSite.year}
          label="Year:"
          onChange={handleChangeYear}
        >
          {sitesYears.map((option) => (
            <MenuItem key={"sites-year-" + option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, mt: 2, minWidth: 120 }} size="small">
        <InputLabel id="sites-month-label-small">Month:</InputLabel>
        <Select
          labelId="sites-month-label-small"
          id="sites-month-select-small"
          value={query.selectedSite.month}
          label="Month:"
          onChange={handleChangeMonth}
        >
          {sitesMonths.map((option) => (
            <MenuItem key={"sites-year-" + option.month} value={option.month}>
              {option.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
