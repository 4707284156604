import React from "react";
import { render } from "react-dom";
// Import Highcharts
// import Highcharts from 'highcharts/highstock'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
// import Boost from "highcharts/modules/boost";
// Boost(Highcharts);

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
// require("highcharts/modules/boost")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);

HighchartsMore(Highcharts);

export default function BoxPlot(props) {
  const {
    themeHC,
    chartHeight,

    chartData,
    titleText,
    xAxisCategories,
    plotOptionsColumnStacking,
    plotOptionsColumnDataLabelsEndabled,
    chartRef3,
    // seriesName,
    yAxisMax,
    subtitleText,
    yAxisTitleText,
    xAxisTitleText,
    xAxisType,
    seriesType,
    seriesName,
    seriesData,
    lineName,
    lineData,
    seriesName1,
    seriesData1,
    seriesName2,
    seriesData2,
    seriesName3,
    seriesData3,
    seriesName4,
    seriesData4,
    seriesName5,
    seriesData5,
    seriesName6,
    seriesData6,
    seriesName7,
    seriesData7,
    barName1,
    barData1,
  } = props;
  // console.log("seriesData1-check", seriesData1);
  const hourLookups = [
    { hour: 0, hourDesc: "12:00 a.m." },
    { hour: 1, hourDesc: "1:00 a.m." },
    { hour: 2, hourDesc: "2:00 a.m." },
    { hour: 3, hourDesc: "3:00 a.m." },
    { hour: 4, hourDesc: "4:00 a.m." },
    { hour: 5, hourDesc: "5:00 a.m." },
    { hour: 6, hourDesc: "6:00 a.m." },
    { hour: 7, hourDesc: "7:00 a.m." },
    { hour: 8, hourDesc: "8:00 a.m." },
    { hour: 9, hourDesc: "9:00 a.m." },
    { hour: 10, hourDesc: "10:00 a.m." },
    { hour: 11, hourDesc: "11:00 a.m." },
    { hour: 12, hourDesc: "12:00 p.m." },
    { hour: 13, hourDesc: "1:00 p.m." },
    { hour: 14, hourDesc: "2:00 p.m." },
    { hour: 15, hourDesc: "3:00 p.m." },
    { hour: 16, hourDesc: "4:00 p.m." },
    { hour: 17, hourDesc: "5:00 p.m." },
    { hour: 18, hourDesc: "6:00 p.m." },
    { hour: 19, hourDesc: "7:00 p.m." },
    { hour: 20, hourDesc: "8:00 p.m." },
    { hour: 21, hourDesc: "9:00 p.m." },
    { hour: 22, hourDesc: "10:00 p.m." },
    { hour: 23, hourDesc: "11:00 p.m." },
    { hour: 24, hourDesc: "12:00 a.m." },
  ];

  const colors = Highcharts.getOptions().colors;
  //   console.log("colors-check", colors);

  // sort array ascending
  function buildBoxPlotData(arr) {
    const asc = (arr) => arr.sort((a, b) => a - b);

    const sum = (arr) => arr.reduce((a, b) => a + b, 0);

    const numRecs = arr.length;

    const mean = (arr) => sum(arr) / arr.length;

    // sample standard deviation
    const std = (arr) => {
      const mu = mean(arr);
      const diffArr = arr.map((a) => (a - mu) ** 2);
      return Math.sqrt(sum(diffArr) / (arr.length - 1));
    };

    const quantile = (arr, q) => {
      const sorted = asc(arr);
      const pos = (sorted.length - 1) * q;
      const base = Math.floor(pos);
      const rest = pos - base;
      if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
      } else {
        return sorted[base];
      }
    };

    const q25 = (arr) => quantile(arr, 0.25);

    const q50 = (arr) => quantile(arr, 0.5);

    const q75 = (arr) => quantile(arr, 0.75);

    const median = (arr) => q50(arr);

    const minValue = arr[0];

    const maxValue = arr[arr.length - 1];

    // console.log("arr-check", arr);
    // console.log(
    //   "boxplotData-checK",
    //   arr[0],
    //   q25(arr),
    //   q50(arr),
    //   q75(arr),
    //   arr[arr.length - 1]
    // );
    return [
      sum(arr),
      numRecs,
      mean(arr),
      std(arr),
      minValue,
      q25(arr),
      q50(arr),
      q75(arr),
      maxValue,
    ];
  }

  //   console.log("seriesData1-check", seriesData1);
  let boxPlotData = [];
  //   let testPlotData = seriesData1[1];
  //   if (seriesData1) boxPlotData.push(buildBoxPlotData(seriesData1[0]));
  //   if (seriesData1) boxPlotData.push(buildBoxPlotData(seriesData1[1]));
  let hourlyAveData = [];

  let dailyAverageData = [];
  let dailyAverageCalcData = [0, 0, 0];

  if (seriesData1) {
    seriesData1.forEach((obj) => {
      let dataStats = buildBoxPlotData(obj);
      boxPlotData.push([
        dataStats[4],
        dataStats[5],
        dataStats[6],
        dataStats[7],
        dataStats[8],
      ]);
      hourlyAveData.push(dataStats[2]);
      dailyAverageCalcData[0] = dailyAverageCalcData[0] + dataStats[0];
      dailyAverageCalcData[1] = dailyAverageCalcData[1] + dataStats[1];
    });
    dailyAverageCalcData[2] = dailyAverageCalcData[0] / dailyAverageCalcData[1];
    [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ].forEach(() => {
      dailyAverageData.push(dailyAverageCalcData[2]);
    });
  }

  const options = {
    chart: {
      type: "boxplot",
      //   zoomType: "xy",
      zoomType: "x",
      height: chartHeight,
    },

    title: {
      text: titleText,
    },

    legend: {
      enabled: true,
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
      itemMarginTop: 10,
      itemMarginBottom: 10,
      itemStyle: {
        // width: "10%", // or whatever
        textOverflow: "wrap",
      },
      width: "15%",
    },
    credits: {
      enabled: false,
    },

    // xAxis: {
    //   categories: ["1", "2", "3", "4", "5"],
    //   title: {
    //     text: "Experiment No.",
    //   },
    // },
    xAxis: [
      {
        // type: xAxisType,
        // tickInterval: 24 * 3600 * 1000 * 15,
        // tickInterval: 1,
        title: {
          text: xAxisTitleText,
        },
        categories: hourLookups.map((obj) => {
          return obj.hourDesc;
        }),
        // labels: {
        //   enabled: true,
        //   formatter: function () {
        //     //   return data[this.value][0];
        //     //   let hrDesc = hourLookups
        //     //     .filter((obj) => obj.hour === this.value)
        //     //     .map((f) => {
        //     //       return f[0].hourDesc;
        //     //     })[0].hourDesc;
        //     //   return this.value;

        //     //   return hourLookups.filter((obj) => obj.hour === this.value)[0]
        //     //     .hourDesc;

        //     let hour = Math.trunc(this.value);
        //     let hourDesc = "";
        //     let amPmDesc = "";
        //     if (hour === 0 || hour === 24) hourDesc = "12";
        //     else {
        //       if (hour <= 12) hourDesc = hour.toString();
        //       else {
        //         hourDesc = (hour - 12).toString();
        //       }
        //     }
        //     if (hour < 12 || hour === 24) amPmDesc = "a.m.";
        //     else amPmDesc = "p.m.";

        //     let minutes = (this.value - hour) * 60;
        //     let minutesDesc = "";
        //     if (minutes < 10)
        //       minutesDesc = "0" + Math.trunc(minutes).toString();
        //     else minutesDesc = Math.trunc(minutes).toString();

        //     return hourDesc + ":" + minutesDesc + " " + amPmDesc;
        //   },
        // },
      },
    ],
    // hourLookups
    yAxis: {
      title: {
        text: "Parking Duration (Minutes)",
      },
      //   plotLines: [
      //     {
      //       value: dailyAverageData[2],
      //       color: "red",
      //       width: 1,
      //       label: {
      //         text:
      //           "Daily Average Parking Duration: " +
      //           dailyAverageData[2] +
      //           " minutes",
      //         align: "center",
      //         style: {
      //           color: "gray",
      //         },
      //       },
      //     },
      //   ],
    },
    // yAxis: {
    //   title: {
    //     text: "Observations",
    //   },
    //   plotLines: [
    //     {
    //       value: 932,
    //       color: "red",
    //       width: 1,
    //       label: {
    //         text: "Theoretical mean: 932",
    //         align: "center",
    //         style: {
    //           color: "gray",
    //         },
    //       },
    //     },
    //   ],
    // },

    series: [
      {
        name: seriesName1,
        // data: seriesData1,
        data: boxPlotData,
        // tooltip: {
        //   headerFormat: "<em>Experiment No {point.key}</em><br/>",
        // },
        tooltip: {
          headerFormat: "<b>{point.key}</b><br/>",
          pointFormatter: function () {
            return (
              '<span style="color:' +
              this.series.color +
              '">\u25CF</span> <b> ' +
              this.series.name +
              "</b><br/>" +
              "Maximum: " +
              //   this.high.toLocaleString() +
              (
                (Math.round(this.high * 100) / 100).toFixed(2) * 1
              ).toLocaleString() +
              "<br/>" +
              "Upper quartile: " +
              //   this.q3.toLocaleString() +
              (
                (Math.round(this.q3 * 100) / 100).toFixed(2) * 1
              ).toLocaleString() +
              "<br/>" +
              "Median: " +
              //   (this.median - 0.05) +
              (
                (Math.round(this.median * 100) / 100).toFixed(2) * 1
              ).toLocaleString() +
              "<br/>" +
              "Lower quartile: " +
              //   (Math.round(this.q1 * 100) / 100).toFixed(2) +
              (
                (Math.round(this.q1 * 100) / 100).toFixed(2) * 1
              ).toLocaleString() +
              "<br/>" +
              "Minimum: " +
              //   (this.low - 0.05) +
              (
                (Math.round(this.low * 100) / 100).toFixed(2) * 1
              ).toLocaleString() +
              "<br/>"
            );
          },
        },
      },
      {
        name: "Hourly Average Parking Duration",
        type: "line",
        data: hourlyAveData,
        color: Highcharts.getOptions().colors[3],
        tooltip: {
          // headerFormat: "",
          pointFormat: "{series.name}: <b>{point.y.toFixed(2)}</b><br/>",
          pointFormatter: function () {
            return (
              '<span style="color:' +
              this.series.color +
              '">\u25CF</span> <b> ' +
              this.series.name +
              ":</b>" +
              ": " +
              //   this.high.toLocaleString() +
              this.y.toFixed(2) +
              " minutes"
            );
          },
          shared: true,
        },
      },
      {
        name: "Daily Average Parking Duration",
        type: "line",
        data: dailyAverageData,
        color: Highcharts.getOptions().colors[5],
        marker: false,
        tooltip: {
          headerFormat: "",
          pointFormat: "{series.name}: <b>{point.y.toFixed(2)}</b><br/>",
          pointFormatter: function () {
            return (
              '<span style="color:' +
              this.series.color +
              '">\u25CF</span> <b> ' +
              this.series.name +
              ":</b>" +
              ": " +
              //   this.high.toLocaleString() +
              this.y.toFixed(2) +
              " minutes"
            );
          },
          shared: true,
        },
      },
      //   {
      //     name: "Outliers",
      //     color: Highcharts.getOptions().colors[0],
      //     type: "scatter",
      //     data: [
      //       // x, y positions where 0 is the first category
      //       [0, 644],
      //       [4, 718],
      //       [4, 951],
      //       [4, 969],
      //     ],
      //     marker: {
      //       fillColor: "white",
      //       lineWidth: 1,
      //       lineColor: Highcharts.getOptions().colors[0],
      //     },
      //     tooltip: {
      //       pointFormat: "Observation: {point.y}",
      //     },
      //   },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
