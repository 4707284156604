import React, { useState, useContext } from "react";
// import { QueryContext } from "../context/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../context/GlobalContext";
import { makeStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";

import Box from "@mui/material/Box";
// import Slider from "@mui/material/Slider";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";

import {
  yearsMonths,
  // geographies,
  regionParkingDataTypes,
} from "../common/constants";

import CircleIcon from "@mui/icons-material/Circle";
import Typography from "@material-ui/core/Typography";
import SquareIcon from "@mui/icons-material/Square";
import SquareOutlinedIcon from "@mui/icons-material/SquareOutlined";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import Grid from "@material-ui/core/Grid";

import IconButton from "@mui/material/IconButton";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  menuListContainer: {
    // background: "#4d4dff",
    //float: 'left',
    // marginLeft: "5px",
    // marginRight: "5px",
    // marginBottom: "10px",
    marginTop: "0px",
    paddingTop: "0px",
  },
  inactiveLayer: {
    // textDecoration: "line-through",
    // marginLeft: 20,
    // color: "#D4D4D4",
    color: "#C0C0C0",
  },
  activeLayer: {
    // textDecoration: "line-through",
    // marginLeft: 20,
    color: "inherit",
  },
}));

let optionsYearsMonths = [];
yearsMonths.map((obj) => {
  optionsYearsMonths.push(obj.yearMonth);
});

// let optionsGeographies = [];
// geographies.map((obj) => {
//   optionsGeographies.push(obj.label);
// });

export default function DataParameters(props) {
  // const { regionSelected, setRegionSelected, zoomLevel } = props;
  const { zoomLevel } = props;
  const classes = useStyles();
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElGeography, setAnchorElGeography] = React.useState(null);
  const [anchorElDataType, setAnchorElDataType] = React.useState(null);

  // const [selectedIndexYearsMonths, setSelectedIndexYearsMonths] =
  //   React.useState(regionSelected.yearMonthOptionsIndex);
  // // const [selectedIndexGeographies, setSelectedIndexGeographies] =
  // //   React.useState(regionSelected.geographyOptionsIndex);
  // const [selectedIndexDataType, setSelectedIndexDataType] = React.useState(
  //   query.regionParkingDataType
  // );

  // const openYearsMonths = Boolean(anchorEl);
  // // const openGeographies = Boolean(anchorElGeography);
  // const openDataType = Boolean(anchorElDataType);

  // const handleClickListItem = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClickListItemGeography = (event) => {
  //   setAnchorElGeography(event.currentTarget);
  // };
  // const handleClickListItemDataType = (event) => {
  //   setAnchorElDataType(event.currentTarget);
  // };

  // const handleMenuItemClickYearsMonths = (event, index) => {
  //   setSelectedIndexYearsMonths(index);

  //   setAnchorEl(null);

  //   let optionsSplit = optionsYearsMonths[index].split("-");
  //   // dispatch({
  //   //   type: "setRegionSelected",
  //   //   selection: {
  //   //     selected: regionSelected.selected,
  //   //     year: optionsSplit[0],
  //   //     month: optionsSplit[1],
  //   //     yearMonthOptionsIndex: index,
  //   //     geographyOptionsIndex: regionSelected.geographyOptionsIndex,
  //   //   },
  //   // });
  //   // setRegionSelected({
  //   //   selected: regionSelected.selected,
  //   //   year: optionsSplit[0] * 1,
  //   //   month: optionsSplit[1] * 1,
  //   //   yearMonthOptionsIndex: index,
  //   //   geographyOptionsIndex: regionSelected.geographyOptionsIndex,
  //   //   regionParkingDataType: regionSelected.regionParkingDataType,
  //   // });
  //   setRegionSelected({
  //     selected: regionSelected.selected,
  //     year: yearsMonths[index].year,
  //     month: yearsMonths[index].month,
  //     yearMonth: yearsMonths[index].yearMonth,

  //     // selected: regionSelected.selected,
  //     // year: optionsSplit[0] * 1,
  //     // month: optionsSplit[1] * 1,
  //     yearMonthOptionsIndex: index,
  //     geographyOptionsIndex: regionSelected.geographyOptionsIndex,
  //     regionParkingDataType: regionSelected.regionParkingDataType,
  //     parkingDurationCategories: regionSelected.parkingDurationCategories,
  //   });
  // };
  // const handleMenuItemClickGeographies = (event, index) => {
  //   setSelectedIndexGeographies(index);

  //   setAnchorElGeography(null);
  //   // dispatch({
  //   //   type: "setRegionSelected",
  //   //   selection: {
  //   //     selected: optionsGeographies[index],
  //   //     year: regionSelected.year,
  //   //     month: regionSelected.month,
  //   //     yearMonthOptionsIndex: regionSelected.yearMonthOptionsIndex,
  //   //     geographyOptionsIndex: index,
  //   //   },
  //   // });
  //   setRegionSelected({
  //     selected: optionsGeographies[index],
  //     year: regionSelected.year,
  //     month: regionSelected.month,
  //     yearMonthOptionsIndex: regionSelected.yearMonthOptionsIndex,
  //     geographyOptionsIndex: index,
  //     regionParkingDataType: regionSelected.regionParkingDataType,
  //   });
  // };
  // const handleMenuItemClickDataType = (event, index) => {
  //   setSelectedIndexDataType(index);

  //   setAnchorElDataType(null);
  //   dispatch({
  //     type: "setRegionParkingDataType",
  //     selection: index,
  //   });
  //   setRegionSelected({
  //     selected: regionSelected.selected,
  //     year: regionSelected.year,
  //     month: regionSelected.month,
  //     yearMonthOptionsIndex: regionSelected.yearMonthOptionsIndex,
  //     geographyOptionsIndex: regionSelected.geographyOptionsIndex,
  //     regionParkingDataType: index,
  //     parkingDurationCategories: regionSelected.parkingDurationCategories,
  //   });
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const handleCloseGeography = () => {
  //   setAnchorElGeography(null);
  // };
  // const handleCloseDataType = () => {
  //   setAnchorElDataType(null);
  // };
  // console.log(
  //   "regionParkingDataTypes[selectedIndexDataType]",
  //   regionParkingDataTypes[0]
  // );

  function setParkingDurationCatsClass(key) {
    // console.log("key-check", key);
    let selectedDataCats = query.regionSelected.parkingDurationCategories;
    const index = selectedDataCats.indexOf(key);

    if (index === -1) return classes.inactiveLayer;
    else return classes.activeLayer;
  }

  function setParkingDurationCatsTooltip(key) {
    // console.log("key-check", key);
    let selectedDataCats = query.regionSelected.parkingDurationCategories;
    const index = selectedDataCats.indexOf(key);

    let descText = "";
    if (key === 1) descText = "Less than 1 hour";
    if (key === 2) descText = "1 to 3 hours";
    if (key === 3) descText = "3 to 7 hours";
    if (key === 4) descText = "7 to 11 hours";
    if (key === 5) descText = "Greater than 11 hours";

    if (index === -1)
      return "Click to show layer - Parking Duration " + descText + ".";
    else return "Click to hide layer.";
  }

  function handleChangeParkingDurationCats(key) {
    let regionSelected = query.regionSelected;
    // console.log("key-check", key);
    //let regionSelected = query.regionSelected;
    let selectedDataCats = regionSelected.parkingDurationCategories;
    const index = selectedDataCats.indexOf(key);

    if (index === -1) selectedDataCats.push(key);
    else selectedDataCats.splice(index, 1);

    // setRegionSelected({
    //   selected: regionSelected.selected,
    //   year: regionSelected.year,
    //   month: regionSelected.month,
    //   yearMonth: regionSelected.yearMonth,
    //   yearMonthOptionsIndex: regionSelected.yearMonthOptionsIndex,
    //   geographyOptionsIndex: regionSelected.geographyOptionsIndex,
    //   regionParkingDataType: regionSelected.regionParkingDataType,
    //   parkingDurationCategories: selectedDataCats,
    // });
    // setRegionSelected({
    //   selected: regionSelected.selected,
    //   year: regionSelected.year,
    //   month: regionSelected.month,
    //   yearMonth: regionSelected.yearMonth,
    //   yearMonthOptionsIndex: regionSelected.yearMonthOptionsIndex,
    //   geographyOptionsIndex: regionSelected.geographyOptionsIndex,
    //   regionParkingDataType: regionSelected.regionParkingDataType,
    //   parkingDurationCategories: selectedDataCats,
    // });
    dispatch({
      type: "setRegionSelected",
      selection: {
        ...query.regionSelected,
        parkingDurationCategories: selectedDataCats,
      },
    });
  }

  return (
    <Accordion
      sx={{ width: "100%" }}
      disableGutters={true}
      defaultExpanded={true}
    >
      <Tooltip title="Click to hide or expand Interactive Legend.">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ marginBottom: 0, paddingBottom: 0 }}
        >
          <Typography
            variant="h6"
            noWrap
            sx={{ fontSize: 8, fontStyle: "bold" }}
          >
            Interactive Legend
          </Typography>
        </AccordionSummary>
      </Tooltip>
      <AccordionDetails sx={{ marginTop: 0, paddingTop: 0 }}>
        <Box>
          <Typography sx={{ fontSize: 8 }}>
            Wide zoom columns are clusters of parking events. Zoom in for
            parking events.
          </Typography>
          <Typography
            // variant="h6"
            noWrap
            sx={{ marginLeft: 20, fontSize: 8 }}
          >
            Parking Duration:
          </Typography>
          <Tooltip title={setParkingDurationCatsTooltip(1)}>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => handleChangeParkingDurationCats(1)}
              color="inherit"
              sx={{ padding: 0 }}
              //change cursor type for buttons - keep
              // style={noPointer}
            >
              <Typography
                noWrap
                // sx={{ marginLeft: 20 }}
                className={setParkingDurationCatsClass(1)}
              >
                {zoomLevel >= 11 && (
                  <CircleIcon
                    fontSize="small"
                    sx={{ color: "#7F3C8D", fontSize: 12, marginRight: 1 }}
                  />
                )}
                {zoomLevel < 11 && (
                  <SquareOutlinedIcon
                    fontSize="small"
                    sx={{ color: "none", fontSize: 12, marginRight: 1 }}
                  />
                )}
                Less than 1 hour
              </Typography>
            </IconButton>
            {/* <Typography noWrap sx={{ marginLeft: 20 }}>
        <CircleIcon
          fontSize="small"
          sx={{ color: "#7F3C8D", fontSize: 12, marginLeft: 2 }}
        />{" "}
        Less than 1 hour
      </Typography> */}
          </Tooltip>

          <Tooltip title={setParkingDurationCatsTooltip(2)}>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => handleChangeParkingDurationCats(2)}
              color="inherit"
              sx={{ padding: 0, paddingRight: 20 }}
              //change cursor type for buttons - keep
              // style={noPointer}
            >
              <Typography
                noWrap
                sx={{ marginright: 20 }}
                className={setParkingDurationCatsClass(2)}
              >
                {zoomLevel >= 11 && (
                  <CircleIcon
                    fontSize="small"
                    sx={{ color: "#11A579", fontSize: 12, marginRight: 1 }}
                  />
                )}
                {zoomLevel < 11 && (
                  <SquareOutlinedIcon
                    fontSize="small"
                    sx={{ color: "none", fontSize: 12, marginRight: 1 }}
                  />
                )}
                1 to 3 hours
              </Typography>
            </IconButton>
            {/* <Typography noWrap sx={{ marginLeft: 20 }}>
        <CircleIcon
          fontSize="small"
          sx={{ color: "#7F3C8D", fontSize: 12, marginLeft: 2 }}
        />{" "}
        Less than 1 hour
      </Typography> */}
          </Tooltip>

          <Tooltip title={setParkingDurationCatsTooltip(3)}>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => handleChangeParkingDurationCats(3)}
              color="inherit"
              sx={{ padding: 0, paddingRight: 20 }}
              //change cursor type for buttons - keep
              // style={noPointer}
            >
              <Typography
                noWrap
                // sx={{ marginLeft: 20 }}
                className={setParkingDurationCatsClass(3)}
              >
                {zoomLevel >= 11 && (
                  <CircleIcon
                    fontSize="small"
                    sx={{ color: "#3969AC", fontSize: 12, marginRight: 1 }}
                  />
                )}
                {zoomLevel < 11 && (
                  <SquareOutlinedIcon
                    fontSize="small"
                    sx={{ color: "none", fontSize: 12, marginRight: 1 }}
                  />
                )}
                3 to 7 hours
              </Typography>
            </IconButton>
            {/* <Typography noWrap sx={{ marginLeft: 20 }}>
        <CircleIcon
          fontSize="small"
          sx={{ color: "#7F3C8D", fontSize: 12, marginLeft: 2 }}
        />{" "}
        Less than 1 hour
      </Typography> */}
          </Tooltip>

          <Tooltip title={setParkingDurationCatsTooltip(4)}>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => handleChangeParkingDurationCats(4)}
              color="inherit"
              sx={{ padding: 0 }}
              //change cursor type for buttons - keep
              // style={noPointer}
            >
              <Typography
                noWrap
                // sx={{ marginLeft: 20 }}
                className={setParkingDurationCatsClass(4)}
              >
                {zoomLevel >= 11 && (
                  <CircleIcon
                    fontSize="small"
                    sx={{ color: "#F2B701", fontSize: 12, marginRight: 1 }}
                  />
                )}
                {zoomLevel < 11 && (
                  <SquareOutlinedIcon
                    fontSize="small"
                    sx={{ color: "none", fontSize: 12, marginRight: 1 }}
                  />
                )}
                7 to 11 hours
              </Typography>
            </IconButton>
            {/* <Typography noWrap sx={{ marginLeft: 20 }}>
        <CircleIcon
          fontSize="small"
          sx={{ color: "#7F3C8D", fontSize: 12, marginLeft: 2 }}
        />{" "}
        Less than 1 hour
      </Typography> */}
          </Tooltip>

          <Tooltip title={setParkingDurationCatsTooltip(5)}>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => handleChangeParkingDurationCats(5)}
              color="inherit"
              sx={{ padding: 0 }}
              //change cursor type for buttons - keep
              // style={noPointer}
            >
              <Typography
                noWrap
                // sx={{ marginLeft: 20 }}
                className={setParkingDurationCatsClass(5)}
              >
                {zoomLevel >= 11 && (
                  <CircleIcon
                    sx={{ color: "#E73F74", fontSize: 12, marginRight: 1 }}
                  />
                )}
                {zoomLevel < 11 && (
                  <SquareOutlinedIcon
                    fontSize="small"
                    sx={{ color: "none", fontSize: 12, marginRight: 1 }}
                  />
                )}
                Greater than 11 hours
              </Typography>
            </IconButton>
            {/* <Typography noWrap sx={{ marginLeft: 20 }}>
        <CircleIcon
          fontSize="small"
          sx={{ color: "#7F3C8D", fontSize: 12, marginLeft: 2 }}
        />{" "}
        Less than 1 hour
      </Typography> */}
          </Tooltip>

          {/* <Typography noWrap sx={{ marginLeft: 20, fontSize: 8 }}>
          <CircleIcon
            fontSize="small"
            sx={{ color: "#11A579", fontSize: 12 }}
          />{" "}
          1 to 3 hours
        </Typography> */}
          {/* <Typography noWrap sx={{ marginLeft: 20, fontSize: 8 }}>
          <CircleIcon
            fontSize="small"
            sx={{ color: "#3969AC", fontSize: 12 }}
          />{" "}
          3 to 7 hours
        </Typography> */}
          {/* <Typography noWrap sx={{ marginLeft: 20, fontSize: 8 }}>
          <CircleIcon
            fontSize="small"
            sx={{ color: "#F2B701", fontSize: 12 }}
          />{" "}
          7 to 11 hours
        </Typography> */}
          {/* <Typography noWrap sx={{ marginLeft: 20, fontSize: 8 }}>
          <CircleIcon sx={{ color: "#E73F74", fontSize: 12 }} /> Greater than 11
          hours
        </Typography> */}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
