import React from "react";
import { render } from "react-dom";
// Import Highcharts
// import Highcharts from 'highcharts/highstock'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);

export default function TimeSeriesWithLineChart(props) {
  const {
    themeHC,
    chartHeight,
    chartData,
    titleText,
    xAxisCategories,
    plotOptionsColumnStacking,
    plotOptionsColumnDataLabelsEndabled,
    chartRef3,
    // seriesName,
    yAxisMax,
    subtitleText,
    yAxisTitleText,
    xAxisTitleText,
    xAxisType,
    seriesType,
    seriesName,
    seriesData,
    lineName,
    lineData,
  } = props;

  const options = {
    chart: {
      zoomType: "x",
      height: chartHeight,
    },
    credits: {
      enabled: false,
    },
    exporting: { enabled: true, chartOptions: { subtitle: { text: "" } } },
    title: {
      text: titleText,
    },
    subtitle: {
      text:
        document.ontouchstart === undefined
          ? "Click and drag in the plot area to zoom in"
          : "Pinch the chart to zoom in",
    },
    xAxis: {
      type: xAxisType,
      title: {
        text: xAxisTitleText,
      },
      labels: {
        enabled: true,
        formatter: function () {
          //   return data[this.value][0];
          return "< " + this.value;
        },
      },
    },
    yAxis: {
      title: {
        text: yAxisTitleText,
      },
    },
    legend: {
      enabled: true,
      layout: "vertical",
      align: "right",
      x: 0,
      verticalAlign: "top",
      y: 150,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || // theme
        "rgba(255,255,255,0.25)",
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },

    series: [
      {
        type: seriesType,
        name: seriesName,
        data: seriesData,
      },
      {
        type: "line",
        name: lineName,
        data: lineData,
      },
    ],
    tooltip: {
      formatter: function () {
        if (this.series.name === lineName)
          return (
            "<b>Average Duration Bin: </b>" + this.x.toFixed(2)
            //   +
            //   "</b> is <b>" +
            //   this.y +
            //   "</b>, in series " +
            //   this.series.name
          );
        else
          return (
            "<b>Duration Bin (hours): </b>" +
            this.x +
            "<br/>" +
            "<b>Number of Parking Events: </b>" +
            this.y.toLocaleString()
            //   +
            //   "</b> is <b>" +
            //   this.y +
            //   "</b>, in series " +
            //   this.series.name
          );
      },
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
