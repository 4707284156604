import React from "react";
import { render } from "react-dom";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);

export default function StackedBarChart(props) {
  const {
    themeHC,
    chartHeight,
    chartData,
    titleText,
    xAxisCategories,
    plotOptionsColumnStacking,
    plotOptionsColumnDataLabelsEndabled,
    chartRef3,
    seriesName,
    yAxisMax,
    yAxisTitleText,
    subtitleText,
    legendSpecs,
    xAxisTitle,
  } = props;

  const options = {
    chart: {
      type: "column",
      height: chartHeight,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: titleText,
    },
    subtitle: {
      text: subtitleText,
    },
    xAxis: {
      categories: xAxisCategories,
      title: xAxisTitle,
    },
    yAxis: {
      stackLabels: {
        enabled: false,
        align: "center",
      },
      visible: true,
      title: { enabled: true, text: yAxisTitleText },
      max: yAxisMax,
    },
    plotOptions: {
      column: {
        stacking: plotOptionsColumnStacking,
        dataLabels: {
          enabled: plotOptionsColumnDataLabelsEndabled,
        },
      },
    },
    series: chartData,
    legend: legendSpecs,
  };
  // console.log("chartData-check", chartData);
  //xAxisCategories
  // console.log("xAxisCategories-check", xAxisCategories);

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        updateArgs={[true]}
        ref={chartRef3}
      />
    </div>
  );
}
