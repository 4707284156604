import React from "react";
import { render } from "react-dom";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);

export default function AreaChart(props) {
  const {
    themeHC,
    chartHeight,
    chartData,
    titleText,
    xAxisCategories,
    plotOptionsColumnStacking,
    plotOptionsColumnDataLabelsEndabled,
    chartRef3,
    seriesName,
    yAxisMax,
    yAxisTitleText,
    subtitleText,
    legendSpecs,
    xAxisTitle,
  } = props;

  const options = {
    chart: {
      type: "area",
      height: chartHeight,
      zoomType: "xy",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: titleText,
    },
    subtitle: {
      text: subtitleText,
    },
    xAxis: {
      //   categories: xAxisCategories,
      title: xAxisTitle,
    },
    yAxis: {
      visible: true,
      title: { enabled: true, text: yAxisTitleText },
      //   max: yAxisMax,
    },
    // plotOptions: {
    //   column: {
    //     stacking: plotOptionsColumnStacking,
    //     dataLabels: {
    //       enabled: plotOptionsColumnDataLabelsEndabled,
    //     },
    //   },
    // },
    plotOptions: {
      area: {
        pointStart: 1,
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series: chartData,
    // legend: legendSpecs,
  };
  // console.log("area-chartData-check", chartData);
  //xAxisCategories
  // console.log("xAxisCategories-check", xAxisCategories);

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        updateArgs={[true]}
        // ref={chartRef3}
      />
    </div>
  );
}
