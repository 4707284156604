export const MAPBOX_URL =
  "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={access_token}";
//"https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={access_token}";

export const MAPBOX_STREETS_V10_URL =
  "https://api.tiles.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}?access_token={access_token}";

export const MAPBOX_ATTRIBUTION = `Map data &copy; 
<a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, 
<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>,
Imagery © <a href="https://www.mapbox.com/">Mapbox</a>`;

export const baseLayers = [
  {
    name: "Streets",
    url: MAPBOX_STREETS_V10_URL,
    id: "mapbox/streets-v11",
  },
  {
    name: "Satellite",
    url: MAPBOX_URL,
    id: "mapbox/satellite-v9",
  },
  {
    name: "Satellite-Streets",
    url: MAPBOX_URL,
    id: "mapbox/satellite-streets-v11",
  },
  {
    name: "Light",
    url: MAPBOX_URL,
    id: "mapbox/light-v10",
  },
  {
    name: "Dark",
    url: MAPBOX_URL,
    id: "mapbox/dark-v10",
  },
];

export const yearsMonths = [
  // { year: 2018, month: 1, yearMonth: "2018-1" },
  // { year: 2018, month: 4, yearMonth: "2018-4" },
  // { year: 2018, month: 7, yearMonth: "2018-7" },
  // { year: 2018, month: 10, yearMonth: "2018-10" },
  { year: 2018, month: 1, yearMonth: "2018-January" },
  { year: 2018, month: 2, yearMonth: "2018-February" },
  { year: 2018, month: 3, yearMonth: "2018-March" },
  { year: 2018, month: 4, yearMonth: "2018-April" },
  { year: 2018, month: 5, yearMonth: "2018-May" },
  { year: 2018, month: 6, yearMonth: "2018-June" },
  { year: 2018, month: 7, yearMonth: "2018-July" },
  { year: 2018, month: 8, yearMonth: "2018-August" },
  { year: 2018, month: 9, yearMonth: "2018-September" },
  { year: 2018, month: 10, yearMonth: "2018-October" },
  { year: 2018, month: 11, yearMonth: "2018-November" },
  { year: 2018, month: 12, yearMonth: "2018-December" },
  { year: 2019, month: 1, yearMonth: "2019-January" },
  { year: 2019, month: 2, yearMonth: "2019-February" },
  { year: 2019, month: 3, yearMonth: "2019-March" },
  { year: 2019, month: 4, yearMonth: "2019-April" },
  { year: 2019, month: 5, yearMonth: "2019-May" },
  { year: 2019, month: 6, yearMonth: "2019-June" },
  { year: 2019, month: 7, yearMonth: "2019-July" },
  { year: 2019, month: 8, yearMonth: "2019-August" },
  { year: 2019, month: 9, yearMonth: "2019-September" },
  { year: 2019, month: 10, yearMonth: "2019-October" },
  { year: 2019, month: 11, yearMonth: "2019-November" },
  { year: 2019, month: 12, yearMonth: "2019-December" },
  { year: 2020, month: 1, yearMonth: "2020-January" },
  { year: 2020, month: 2, yearMonth: "2020-February" },
  { year: 2020, month: 3, yearMonth: "2020-March" },
  { year: 2020, month: 4, yearMonth: "2020-April" },
  { year: 2020, month: 5, yearMonth: "2020-May" },
  { year: 2020, month: 6, yearMonth: "2020-June" },
  { year: 2020, month: 7, yearMonth: "2020-July" },
  { year: 2020, month: 8, yearMonth: "2020-August" },
  { year: 2020, month: 9, yearMonth: "2020-September" },
  { year: 2020, month: 10, yearMonth: "2020-October" },
  { year: 2020, month: 11, yearMonth: "2020-November" },
  { year: 2020, month: 12, yearMonth: "2020-December" },
  { year: 2021, month: 1, yearMonth: "2021-January" },
  { year: 2021, month: 2, yearMonth: "2021-February" },
  { year: 2021, month: 3, yearMonth: "2021-March" },
  { year: 2021, month: 4, yearMonth: "2021-April" },
  { year: 2021, month: 5, yearMonth: "2021-May" },
  { year: 2021, month: 6, yearMonth: "2021-June" },
  { year: 2021, month: 7, yearMonth: "2021-July" },
  { year: 2021, month: 8, yearMonth: "2021-August" },
  { year: 2021, month: 9, yearMonth: "2021-September" },
  { year: 2021, month: 10, yearMonth: "2021-October" },
  { year: 2021, month: 11, yearMonth: "2021-November" },
  { year: 2021, month: 12, yearMonth: "2021-December" },
];

export const sitesYears = [2018, 2019, 2020, 2021];
export const sitesMonths = [
  { month: 1, description: "January" },
  { month: 2, description: "February" },
  { month: 3, description: "March" },
  { month: 4, description: "April" },
  { month: 5, description: "May" },
  { month: 6, description: "June" },
  { month: 7, description: "July" },
  { month: 8, description: "August" },
  { month: 9, description: "September" },
  { month: 10, description: "October" },
  { month: 11, description: "November" },
  { month: 12, description: "December" },
];
export const sitesYearsMonths = [
  { year: 2018, month: 1, yearMonth: "2018-January" },
  { year: 2018, month: 2, yearMonth: "2018-February" },
  { year: 2018, month: 3, yearMonth: "2018-March" },
  { year: 2018, month: 4, yearMonth: "2018-April" },
  { year: 2018, month: 5, yearMonth: "2018-May" },
  { year: 2018, month: 6, yearMonth: "2018-June" },
  { year: 2018, month: 7, yearMonth: "2018-July" },
  { year: 2018, month: 8, yearMonth: "2018-August" },
  { year: 2018, month: 9, yearMonth: "2018-September" },
  { year: 2018, month: 10, yearMonth: "2018-October" },
  { year: 2018, month: 11, yearMonth: "2018-November" },
  { year: 2018, month: 12, yearMonth: "2018-December" },
  { year: 2019, month: 1, yearMonth: "2019-January" },
  { year: 2019, month: 2, yearMonth: "2019-February" },
  { year: 2019, month: 3, yearMonth: "2019-March" },
  { year: 2019, month: 4, yearMonth: "2019-April" },
  { year: 2019, month: 5, yearMonth: "2019-May" },
  { year: 2019, month: 6, yearMonth: "2019-June" },
  { year: 2019, month: 7, yearMonth: "2019-July" },
  { year: 2019, month: 8, yearMonth: "2019-August" },
  { year: 2019, month: 9, yearMonth: "2019-September" },
  { year: 2019, month: 10, yearMonth: "2019-October" },
  { year: 2019, month: 11, yearMonth: "2019-November" },
  { year: 2019, month: 12, yearMonth: "2019-December" },
  { year: 2020, month: 1, yearMonth: "2020-January" },
  { year: 2020, month: 2, yearMonth: "2020-February" },
  { year: 2020, month: 3, yearMonth: "2020-March" },
  { year: 2020, month: 4, yearMonth: "2020-April" },
  { year: 2020, month: 5, yearMonth: "2020-May" },
  { year: 2020, month: 6, yearMonth: "2020-June" },
  { year: 2020, month: 7, yearMonth: "2020-July" },
  { year: 2020, month: 8, yearMonth: "2020-August" },
  { year: 2020, month: 9, yearMonth: "2020-September" },
  { year: 2020, month: 10, yearMonth: "2020-October" },
  { year: 2020, month: 11, yearMonth: "2020-November" },
  { year: 2020, month: 12, yearMonth: "2020-December" },
  { year: 2021, month: 1, yearMonth: "2021-January" },
  { year: 2021, month: 2, yearMonth: "2021-February" },
  { year: 2021, month: 3, yearMonth: "2021-March" },
  { year: 2021, month: 4, yearMonth: "2021-April" },
  { year: 2021, month: 5, yearMonth: "2021-May" },
  { year: 2021, month: 6, yearMonth: "2021-June" },
  { year: 2021, month: 7, yearMonth: "2021-July" },
  { year: 2021, month: 8, yearMonth: "2021-August" },
  { year: 2021, month: 9, yearMonth: "2021-September" },
  { year: 2021, month: 10, yearMonth: "2021-October" },
  { year: 2021, month: 11, yearMonth: "2021-November" },
  { year: 2021, month: 12, yearMonth: "2021-December" },
];

export const geographies = [
  {
    id: 1,
    name: "State",
    label: "State",
    lookup: "statewide",
    counties: [
      0, 1, 3, 5, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39,
      41, 43, 45, 47, 510,
    ],
  },
  {
    id: 2,
    name: "Western Region",
    label: "Western Region",
    lookup: "western",
    counties: [1, 23, 43],
    countiesLandUse: ["WASH", "ALLE", "GARR"],
  },
  {
    id: 3,
    name: "Central Region",
    label: "Central Region",
    lookup: "central",
    counties: [3, 2, 5, 510, 13, 25, 27],
    countiesLandUse: ["ANNE", "BACO", "BACI", "CARR", "HARF", "HOWA"],
  },
  {
    id: 4,
    name: "DC Metro Area",
    label: "DC Metro Area",
    lookup: "dc-metro-area",
    counties: [21, 31, 33, 0],
    countiesLandUse: ["FRED", "MONT", "PRIN"],
  },
  {
    id: 5,
    name: "Southern Region",
    label: "Southern Region",
    lookup: "southern",
    counties: [9, 17, 37],
    countiesLandUse: ["CALV", "CHAR", "STMA"],
  },
  {
    id: 6,
    name: "Eastern Shore Region",
    label: "Eastern Shore Region",
    lookup: "eastern-shore",
    counties: [11, 15, 19, 29, 35, 39, 41, 45, 47],
    countiesLandUse: [
      "CARO",
      "CECI",
      "DORC",
      "KENT",
      "QUEE",
      "SOME",
      "TALB",
      "WICO",
      "WORC",
    ],
  },
  {
    id: 7,
    name: "Baltimore Area",
    label: "Baltimore Area",
    lookup: "baltimore-area",
    counties: [5, 510],
    countiesLandUse: ["BACO", "BACI"],
  },
];

export const regionParkingDataTypes = [
  {
    value: 0,
    label: "All Parking",
  },
  {
    value: 1,
    label: "Parking < 1 hour",
  },
  {
    value: 2,
    label: "Parking >= 1 hour, < 3 hours",
  },
  {
    value: 3,
    label: "Parking >= 3 hours, < 7 hours",
  },
  {
    value: 4,
    label: "Parking >= 7 hours, < 11 hours",
  },
  {
    value: 5,
    label: "Parking >= 11 hours",
  },
];

export const parkingSites = [
  { id: 1, name: "Sideling Hill Welcome Center", dir: ["N", "S"] },
  { id: 2, name: "Laurel – I-95 Welcome Center", dir: ["N", "S"] },
  { id: 3, name: "New Market Rest Area", dir: ["E"] },
  { id: 4, name: "I-70 Welcome Center", dir: ["E", "W"] },
  { id: 5, name: "Maryland House NB/SB", dir: ["B"] },
  { id: 6, name: "Youghiogheny Overlook Welcome Center", dir: ["E"] },
  { id: 7, name: "West Friendship TWIS", dir: ["W"] },
  { id: 8, name: "I-95/495 Park and Ride", dir: ["E"] },
  { id: 9, name: "Finzel Scalehouse Weigh Station", dir: ["S"] },
  { id: 10, name: "Mason Dixon Welcome Center", dir: ["S"] },
  { id: 11, name: "New Market TWIS", dir: ["E"] },
  { id: 12, name: "Hyattstown TWIS", dir: ["N", "S"] },
  { id: 13, name: "NB/SB Upper Marlboro Weigh station", dir: ["N", "S"] },
  { id: 14, name: "Perryville TWIS", dir: ["N", "S"] },
  { id: 15, name: "Chesapeake House NB/SB", dir: ["B"] },
  { id: 16, name: "Cecilton TWIS", dir: ["S"] },
];

export const vehicleWeightClassOptions = [
  { label: "All Vehicles (Weight Class 2 and 3)", value: 0 },
  { label: "Vehicle Weight Class 2", value: 2 },
  { label: "Vehicle Weight Class 3", value: 3 },
];

export const parkingDurationOptions = [
  {
    label: "Less than 1 hour",
    value: "< 1",
    authorizedField: "total_auth1",
    unauthorizedField: "total_unauth1",
  },
  {
    label: "Greater than 1, less than 3 hours",
    value: "> 1, < 3",
    authorizedField: "total_auth1_3",
    unauthorizedField: "total_unauth1_3",
  },
  {
    label: "Greater than 3, less than 7 hours",
    value: "> 3, < 7",
    authorizedField: "total_auth3_7",
    unauthorizedField: "total_unauth3_7",
  },
  {
    label: "Greater than 7, less than 11 hours",
    value: "> 7, < 11",
    authorizedField: "total_auth7_11",
    unauthorizedField: "total_unauth7_11",
  },
  {
    label: "Greater than 11 hours",
    value: "> 11",
    authorizedField: "total_auth11",
    unauthorizedField: "total_unauth11",
  },
];
