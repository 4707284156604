import React, { useState, useRef, useEffect, useMemo, useContext } from "react";

import {
  // geographies,
  // vehicleWeightClassOptions,
  parkingSites,
  parkingDurationOptions,
} from "../common/constants";

import Grid from "@material-ui/core/Grid";

// import HighStock from "./HighCharts/HighStock";
// import LineChart from "./Charts/HighCharts/LineChart";
import StackedBarChart from "./HighCharts/StackedBarChart";
// import PieChart from "./Charts/HighCharts/PieChart";
// import StackedGrouped3dBarChart from "./HighCharts/StackedGrouped3dBarChart";
// import StackedGroupedBarChart from "./HighCharts/StackedGroupedBarChart";
import Donut3dChart from "./HighCharts/Donut3dChart";
import MultiLineChart from "./HighCharts/MultiLineChart";
// import DependencyWheel from "./Charts/HighCharts/DependencyWheel";
// import SankeyDiagram from "./HighCharts/SankeyDiagram";
// import TimeSeriesChart from "./HighCharts/TimeSeriesChart";
// import TimeSeriesWithLineChart from "./HighCharts/TimeSeriesWithLineChart";
// import ScatterPlot from "./HighCharts/ScatterPlot";
// import Column3dPlot from "./HighCharts/Column3dPlot";
// import StackedGrouped3dBarChart from "./HighCharts/StackedGrouped3dBarChart";
// import MultipleStackedBarCharts from "./HighCharts/MultipleStackedBarCharts";
import HighContrasLight from "highcharts/themes/high-contrast-light";

// import { QueryContext } from "../context/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../context/GlobalContext";
import axios from "axios";

import { gridStyles } from "../ui/styles";

// import {
//   defaultPieChartData1,
//   defaultPieChartData2,
//   defaultPieChartData3,
//   defaultMultiLineChartData1,
//   defaultMultiLineChartData2,
//   defaultMultiLineChartData3,
//   defaultStackedColumnChartData1,
//   defaultStackedColumnChartData2,
//   defaultStackedColumnChartData3,
//   defaultDependencyWheelData1,
//   defaultDependencyWheelData2,
//   defaultDependencyWheelData3,
//   defaultStackedGrouped3dBarChartData1,
//   defaultStackedGrouped3dBarChartData2,
//   defaultStackedGrouped3dBarChartData3,
//   defaultDonut3dChartData1,
//   defaultDonut3dChartData2,
//   defaultDonut3dChartData3,
//   defaultSankeyDiagramData1,
//   defaultSankeyDiagramData2,
//   defaultSankeyDiagramData3,
// } from "../data/defaultData";

// import { fetchParkingCatSummaryByRegion } from "../data-loaders/DataLoaders";

// import Tooltip from "@mui/material/Tooltip";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// import BoxPlot from "./HighCharts/BoxPlot";

import {
  // fetchStatewideData,
  // fetchParkingDataRegionByYearMonth,
  // fetchParkingDataCountyByYearMonth,
  // fetchParkingDataByRegionYearMonth,
  // fetchLandUse,
  // fetchParkingSitesGeom,
  // findParkingSiteEvents,
  findSiteUnoffRatioByDirYearMonthDay,
  // findSiteOffUnoffByYearMonthDay,
  // findSiteOffUnoffByYearMonthHour,
} from "../data-loaders/DataLoaders";

import Typography from "@mui/material/Typography";

function ChartsLayoutSites(props) {
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();
  const classesGrid = gridStyles();
  //   const classes = useStyles();
  const {
    // statusGuide,
    chartHeight,
    parkingSiteGeom,
    siteSelected,
    siteOffUnoffByYearMonthHourData,
    siteOffUnoffByYearMonthDayData,
  } = props;
  // console.log("siteSelected-check", siteSelected);
  const [unofficialDataChart, setUnofficialDataChart] = useState(null);
  // useEffect(() => {
  //   if (unofficialDataDay !== null) {
  //     console.log("unofficialDataDay-check-layout", unofficialDataDay);
  //     setUnofficialDataChart(unofficialDataDay);
  //   }
  // }, [unofficialDataDay]);

  // console.log("unofficialDataChart-check", unofficialDataChart);
  // useEffect(() => {
  //   console.log(
  //     "siteOffUnoffByYearMonthHourData-check",
  //     siteOffUnoffByYearMonthHourData
  //   );
  // }, [siteSelected]);
  let hourlyCountsSummaryVars = [
    "unauth_ratio",
    "unauth1_ratio",
    "unauth1_3_ratio",
    "unauth3_7_ratio",
    "unauth7_11_ratio",
    "unauth11_ratio",
  ];
  // useEffect(() => {
  //   if (parkingSiteGeom) {
  //     // let tempDataTable = [];
  //     // [
  //     //   "less than 1 hour",
  //     //   "1-3 hours",
  //     //   "3-7 hours",
  //     //   "7-11 hours",
  //     //   "greater than 11 hours",
  //     // ].map((obj) => {
  //     //   let dataTemp = calcUnauthorizedPercent(obj, siteEventsGeom);
  //     //   // console.log("dataTemp-check", dataTemp);
  //     //   tempDataTable.push(dataTemp);
  //     // });
  //     // let totalCount = sumByName(tempDataTable, "totalCount");
  //     // let authorizedCount = sumByName(tempDataTable, "authorizedCount");
  //     // let unauthorizedCount = sumByName(tempDataTable, "unauthorizedCount");
  //     // let percentUnauthorized =
  //     //   totalCount > 0 ? (unauthorizedCount / totalCount) * 100 : 0;
  //     // tempDataTable.push({
  //     //   siteName: statusGuide,
  //     //   yearMonth: siteSelected.yearMonth,
  //     //   parkingCategory: "Total",
  //     //   totalCount: totalCount,
  //     //   authorizedCount: authorizedCount,
  //     //   unauthorizedCount: unauthorizedCount,
  //     //   percentUnauthorized: percentUnauthorized,
  //     // });
  //     // setUnauthorizedPercentTable(tempDataTable);

  //     // let tempSpaceRatioData = [];
  //     let tempUnofficialRatioDataDay = [];
  //     let siteDirs = parkingSites.filter(
  //       (obj) => obj.name === query.selectedSite.selected
  //     )[0].dir;
  //     // console.log("siteDirs-check", siteDirs);
  //     siteDirs.map((ste, index) => {
  //       // console.log("ste-check", ste);
  //       let siteLabel =
  //         query.selectedSite.selected +
  //         (ste === "N"
  //           ? " - Northbound"
  //           : ste === "S"
  //           ? " - Southbound"
  //           : ste === "W"
  //           ? " - Westbound"
  //           : ste === "E"
  //           ? " - Eastbound"
  //           : "");
  //       // let dailyAveTotalCount = (
  //       //   siteEventsGeom.filter(
  //       //     (obj) => obj.sitename === query.selectedSite.selected && obj.dir === ste
  //       //   ).length / 31
  //       // ).toFixed(0);

  //       // let tempSiteData = [];
  //       // parkingSiteGeom.features.map((fc) =>
  //       //   tempSiteData.push(fc.properties)
  //       // );

  //       // let spaces = 0;
  //       // if (
  //       //   tempSiteData.filter(
  //       //     (fc) => fc.dir === ste && fc.authorized === 1
  //       //   )[0]
  //       // )
  //       //   spaces = tempSiteData.filter(
  //       //     (fc) => fc.dir === ste && fc.authorized === 1
  //       //   )[0].space;
  //       // tempSpaceRatioData.push({
  //       //   siteName: query.selectedSite.selected,
  //       //   dir: ste,
  //       //   yearMonth: siteSelected.yearMonth,
  //       //   siteLabel: siteLabel,
  //       //   totalCount: dailyAveTotalCount,
  //       //   spaces: spaces,
  //       //   spaceRatio: spaces > 0 ? (dailyAveTotalCount / spaces) * 100 : 0,
  //       // });

  //       findSiteUnoffRatioByDirYearMonthDay(
  //         query.selectedSite.selected,
  //         ste,
  //         query.selectedSite.year,
  //         query.selectedSite.month
  //       ).then((response) => {
  //         // console.log("response-check", response);
  //         // setSiteEventsGeom(response.data);

  //         let daysTemp = [];
  //         let chartDataTemp = [];
  //         hourlyCountsSummaryVars.map((item, index) => {
  //           chartDataTemp.push([]);
  //         });

  //         response.data.map((obj) => {
  //           daysTemp.push(obj.start_day);

  //           hourlyCountsSummaryVars.map((item, index) => {
  //             chartDataTemp[index].push(obj[item]);
  //           });
  //         });

  //         // tempUnofficialRatioDataDay.push({
  //         //   siteName: query.selectedSite.selected,
  //         //   dir: ste,
  //         //   yearMonth: query.selectedSite.yearMonth,
  //         //   siteLabel: siteLabel,
  //         //   days: daysTemp,
  //         //   chartData: [
  //         //     hourlyCountsSummaryVars.map((item, index) => {
  //         //       return { name: item, data: chartDataTemp[index] };
  //         //     }),
  //         //   ],
  //         // });
  //         let mergedChartData = [];

  //         hourlyCountsSummaryVars.map((item, index) => {
  //           mergedChartData = [
  //             ...mergedChartData,
  //             { name: item, data: chartDataTemp[index] },
  //           ];
  //         });
  //         if (index === 0)
  //           setUnofficialDataChart({
  //             siteName: query.selectedSite.selected,
  //             dir: ste,
  //             yearMonth: query.selectedSite.yearMonth,
  //             siteLabel: siteLabel,
  //             days: daysTemp,
  //             chartData: mergedChartData,
  //           });
  //       });

  //       // findSiteOffUnoffByYearMonthDay(
  //       //   query.selectedSite.selected,
  //       //   ste,
  //       //   query.selectedSite.year
  //       // ).then((response) => {
  //       //   console.log("response-off-unoff-yr-day-check", response);
  //       // });
  //       // findSiteOffUnoffByYearMonthHour(
  //       //   query.selectedSite.selected,
  //       //   ste,
  //       //   query.selectedSite.year
  //       // ).then((response) => {
  //       //   console.log("response-off-unoff-yr-hour-check", response);
  //       // });
  //     });
  //     // setSpaceRatioData(tempSpaceRatioData);
  //     // setUnofficialDataChart(tempUnofficialRatioDataDay);
  //   }
  // }, [parkingSiteGeom, query.selectedSite]);
  // console.log("unofficialDataChart-check", unofficialDataChart);
  // console.log(
  //   "siteOffUnoffByYearMonthHourData-check",
  //   siteOffUnoffByYearMonthHourData
  // );

  const monthsDescription = [
    { value: 1, text: "January", color: "#7cb5ec" },
    { value: 2, text: "February", color: "#434348" },
    { value: 3, text: "March", color: "#90ed7d" },
    { value: 4, text: "April", color: "#f7a35c" },
    { value: 5, text: "May", color: "#8085e9" },
    { value: 6, text: "June", color: "#f15c80" },
    { value: 7, text: "July", color: "#e4d354" },
    { value: 8, text: "August", color: "#2b908f" },
    { value: 9, text: "September", color: "#f45b5b" },
    { value: 10, text: "October", color: "#91e8e1" },
    { value: 11, text: "November", color: "#4572A7" },
    { value: 12, text: "December", color: "#8bbc21" },
  ];
  function setChartDataOffUnoffByYearMonthHourData(
    direction,
    category,
    yAxisNumber,
    xAxisNumber,
    showInLegend
  ) {
    let tempHourData = siteOffUnoffByYearMonthHourData.filter(
      (obj) => obj.direction === direction
    );
    let startMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let chartData = [];
    monthsDescription.map((itm) => {
      let obj = itm.value;
      let tempData = [];
      tempHourData
        .filter((itm) => itm.start_month === obj)
        .map((dt) => (tempData[dt.start_hour] = dt[category]));
      chartData.push({
        // name: "Month " + obj.toLocaleString(),
        name: itm.text,
        data: tempData,
        // offset: 0,
        // yAxis: yAxisNumber,
        // xAxis: yAxisNumber,
        // showInLegend: showInLegend,
        // id: obj.toLocaleString() + "-month-" + yAxisNumber,
        id: obj.toLocaleString() + "-month",
        // pointWidth: 5,
        // groupPadding: 0,
        // pointPadding: 0,
        // type: "column",
        color: itm.color,
      });
    });

    // console.log("chartData-check", chartData);
    return chartData;
  }
  // console.log(
  //   "siteOffUnoffByYearMonthDayData-check",
  //   siteOffUnoffByYearMonthDayData
  // );
  function setChartDataOffUnoffByYearMonthDayData(
    direction,
    category,
    yAxisNumber,
    xAxisNumber,
    showInLegend
  ) {
    let tempDayData = siteOffUnoffByYearMonthDayData.filter(
      (obj) => obj.direction === direction
    );
    let startMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let chartData = [];
    monthsDescription.map((itm) => {
      let obj = itm.value;
      let tempData = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
      ];
      tempDayData
        .filter((itm) => itm.start_month === obj)
        .map((dt) => (tempData[dt.start_day] = dt[category]));
      // console.log("tempData-day-check", tempData);
      chartData.push({
        // name: "Month " + obj.toLocaleString(),
        name: itm.text,
        data: tempData,
        // offset: 0,
        // yAxis: yAxisNumber,
        // xAxis: yAxisNumber,
        // showInLegend: showInLegend,
        // id: obj.toLocaleString() + "-month-" + yAxisNumber,
        // id: obj.toLocaleString() + "-month",
        // pointWidth: 5,
        // groupPadding: 0,
        // pointPadding: 0,
        // type: "column",
        color: itm.color,
      });
    });

    // console.log("chartData-check", chartData);
    return chartData;
  }

  const [selectedSitesDuration, setSelectedSitesDuration] = useState(
    parkingDurationOptions[0].value
  );

  const handleChangeParkingDuration = (event) => {
    setSelectedSitesDuration(event.target.value);
    // if (event.target.value === "leaflet") setDeckGlMapTypeOptionsEnabled(true);
    // else setDeckGlMapTypeOptionsEnabled(false);
    // dispatch({
    //   type: "setSelectedVehicleWeightClass",
    //   selection: event.target.value,
    // });
  };

  function setChartTitle(year, site, dir) {
    if (dir === "N") return year + " " + site + " - Northbound";
    if (dir === "S") return year + " " + site + " - Southbound";
    if (dir === "E") return year + " " + site + " - Eastbound";
    if (dir === "W") return year + " " + site + " - Westbound";
    if (dir === "B") return year + " " + site;

    return year + " " + site;
  }

  return (
    <div>
      {parkingSites
        .filter((itm) => itm.name === query.selectedSite.selected)[0]
        .dir.map((obj) => {
          return (
            <Grid
              container
              // spacing={0}
              // style={{ height: "41vh" }}
              // style={{ height: "65" }}
              className={classesGrid.gridRow1}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ padding: "5px", textAlign: "center" }}
                >
                  Usage by Hour -{" "}
                  {setChartTitle(
                    query.selectedSite.year,
                    query.selectedSite.selected,
                    obj
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <StackedBarChart
                  chartData={setChartDataOffUnoffByYearMonthHourData(
                    obj,
                    "total_auth"
                  )}
                  plotOptionsColumnStacking={"normal"}
                  plotOptionsColumnDataLabelsEndabled={false}
                  chartHeight={"50%"}
                  themeHC={HighContrasLight}
                  yAxisTitleText={"# of Parking Events"}
                  legendSpecs={{
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                    itemMarginTop: 0.5,
                    itemMarginBottom: 0,
                    itemStyle: {
                      // color: "#000000",
                      // fontWeight: "bold",
                      fontSize: 10.5,
                    },
                  }}
                  titleText={setChartTitle(
                    query.selectedSite.year,
                    query.selectedSite.selected,
                    obj
                  )}
                  subtitleText={"Average Authorized Utilization by Hour"}
                  xAxisTitle={{
                    enabled: true,
                    text: "Hour (Hour 0 is 12 a.m.)",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <StackedBarChart
                  chartData={setChartDataOffUnoffByYearMonthHourData(
                    obj,
                    "total_unauth"
                  )}
                  plotOptionsColumnStacking={"normal"}
                  plotOptionsColumnDataLabelsEndabled={false}
                  chartHeight={"50%"}
                  themeHC={HighContrasLight}
                  yAxisTitleText={"# of Parking Events"}
                  legendSpecs={{
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                    itemMarginTop: 0.5,
                    itemMarginBottom: 0,
                    itemStyle: {
                      // color: "#000000",
                      // fontWeight: "bold",
                      fontSize: 10.5,
                    },
                  }}
                  titleText={setChartTitle(
                    query.selectedSite.year,
                    query.selectedSite.selected,
                    obj
                  )}
                  subtitleText={"Average Unauthorized Utilization by Hour"}
                  xAxisTitle={{
                    enabled: true,
                    text: "Hour (Hour 0 is 12 a.m.)",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classesGrid.formLabel}>
                  <FormLabel
                    id="sites-duration-radio-buttons-group-label"
                    className={classesGrid.formLabel}
                  >
                    Parking Duration
                  </FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="duration-radio-buttons-group"
                    value={selectedSitesDuration}
                    onChange={handleChangeParkingDuration}
                    className={classesGrid.formLabel}
                    row
                  >
                    {parkingDurationOptions.map((option, index) => (
                      <FormControlLabel
                        // value="mapbox://styles/mapbox/streets-v11"
                        value={option.value}
                        control={<Radio size="small" />}
                        label={option.label}
                        key={"sitesDurations-" + option.value}
                        // className={classesGrid.formLabel}
                        // disabled={false}
                        // disabled={regionSelected.year === 2018 ? true : false}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <StackedBarChart
                  // chartData={setChartDataOffUnoffByYearMonthHourData(
                  //   "N",
                  //   "total_auth1"
                  // )}
                  chartData={setChartDataOffUnoffByYearMonthHourData(
                    obj,
                    parkingDurationOptions.filter(
                      (obj) => obj.value === selectedSitesDuration
                    )[0].authorizedField
                  )}
                  plotOptionsColumnStacking={"normal"}
                  plotOptionsColumnDataLabelsEndabled={false}
                  chartHeight={"50%"}
                  themeHC={HighContrasLight}
                  yAxisTitleText={"# of Parking Events"}
                  legendSpecs={{
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                    itemMarginTop: 0.5,
                    itemMarginBottom: 0,
                    itemStyle: {
                      // color: "#000000",
                      // fontWeight: "bold",
                      fontSize: 10.5,
                    },
                  }}
                  titleText={setChartTitle(
                    query.selectedSite.year,
                    query.selectedSite.selected,
                    obj
                  )}
                  subtitleText={
                    "Average Authorized Utilization by Hour - Parking " +
                    parkingDurationOptions.filter(
                      (obj) => obj.value === selectedSitesDuration
                    )[0].label
                  }
                  xAxisTitle={{
                    enabled: true,
                    text: "Hour (Hour 0 is 12 a.m.)",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <StackedBarChart
                  // chartData={setChartDataOffUnoffByYearMonthHourData(
                  //   "N",
                  //   "total_auth1"
                  // )}
                  chartData={setChartDataOffUnoffByYearMonthHourData(
                    obj,
                    parkingDurationOptions.filter(
                      (obj) => obj.value === selectedSitesDuration
                    )[0].unauthorizedField
                  )}
                  plotOptionsColumnStacking={"normal"}
                  plotOptionsColumnDataLabelsEndabled={false}
                  chartHeight={"50%"}
                  themeHC={HighContrasLight}
                  yAxisTitleText={"# of Parking Events"}
                  legendSpecs={{
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                    itemMarginTop: 0.5,
                    itemMarginBottom: 0,
                    itemStyle: {
                      // color: "#000000",
                      // fontWeight: "bold",
                      fontSize: 10.5,
                    },
                  }}
                  titleText={setChartTitle(
                    query.selectedSite.year,
                    query.selectedSite.selected,
                    obj
                  )}
                  subtitleText={
                    "Average Unauthorized Utilization by Hour - Parking " +
                    parkingDurationOptions.filter(
                      (obj) => obj.value === selectedSitesDuration
                    )[0].label
                  }
                  xAxisTitle={{
                    enabled: true,
                    text: "Hour (Hour 0 is 12 a.m.)",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ padding: "5px", textAlign: "center" }}
                >
                  Usage by Day -{" "}
                  {setChartTitle(
                    query.selectedSite.year,
                    query.selectedSite.selected,
                    obj
                  )}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <StackedBarChart
                  chartData={setChartDataOffUnoffByYearMonthDayData(
                    obj,
                    "total_auth"
                  )}
                  plotOptionsColumnStacking={"normal"}
                  plotOptionsColumnDataLabelsEndabled={false}
                  chartHeight={"50%"}
                  themeHC={HighContrasLight}
                  yAxisTitleText={"# of Parking Events"}
                  legendSpecs={{
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                    itemMarginTop: 0.5,
                    itemMarginBottom: 0,
                    itemStyle: {
                      // color: "#000000",
                      // fontWeight: "bold",
                      fontSize: 10.5,
                    },
                  }}
                  titleText={setChartTitle(
                    query.selectedSite.year,
                    query.selectedSite.selected,
                    obj
                  )}
                  subtitleText={"Average Authorized Utilization by Day"}
                  xAxisTitle={{
                    enabled: true,
                    text: "Day",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <StackedBarChart
                  chartData={setChartDataOffUnoffByYearMonthDayData(
                    obj,
                    "total_unauth"
                  )}
                  plotOptionsColumnStacking={"normal"}
                  plotOptionsColumnDataLabelsEndabled={false}
                  chartHeight={"50%"}
                  themeHC={HighContrasLight}
                  yAxisTitleText={"# of Parking Events"}
                  legendSpecs={{
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                    itemMarginTop: 0.5,
                    itemMarginBottom: 0,
                    itemStyle: {
                      // color: "#000000",
                      // fontWeight: "bold",
                      fontSize: 10.5,
                    },
                  }}
                  titleText={setChartTitle(
                    query.selectedSite.year,
                    query.selectedSite.selected,
                    obj
                  )}
                  subtitleText={"Average Unauthorized Utilization by Day"}
                  xAxisTitle={{
                    enabled: true,
                    text: "Day",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classesGrid.formLabel}>
                  <FormLabel
                    id="sites-duration-radio-buttons-group-label"
                    className={classesGrid.formLabel}
                  >
                    Parking Duration
                  </FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="duration-radio-buttons-group"
                    value={selectedSitesDuration}
                    onChange={handleChangeParkingDuration}
                    className={classesGrid.formLabel}
                    row
                  >
                    {parkingDurationOptions.map((option, index) => (
                      <FormControlLabel
                        // value="mapbox://styles/mapbox/streets-v11"
                        value={option.value}
                        control={<Radio size="small" />}
                        label={option.label}
                        key={"sitesDurations-" + option.value}
                        // className={classesGrid.formLabel}
                        // disabled={false}
                        // disabled={regionSelected.year === 2018 ? true : false}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <StackedBarChart
                  // chartData={setChartDataOffUnoffByYearMonthHourData(
                  //   "N",
                  //   "total_auth1"
                  // )}
                  chartData={setChartDataOffUnoffByYearMonthDayData(
                    obj,
                    parkingDurationOptions.filter(
                      (obj) => obj.value === selectedSitesDuration
                    )[0].authorizedField
                  )}
                  plotOptionsColumnStacking={"normal"}
                  plotOptionsColumnDataLabelsEndabled={false}
                  chartHeight={"50%"}
                  themeHC={HighContrasLight}
                  yAxisTitleText={"# of Parking Events"}
                  legendSpecs={{
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                    itemMarginTop: 0.5,
                    itemMarginBottom: 0,
                    itemStyle: {
                      // color: "#000000",
                      // fontWeight: "bold",
                      fontSize: 10.5,
                    },
                  }}
                  titleText={setChartTitle(
                    query.selectedSite.year,
                    query.selectedSite.selected,
                    obj
                  )}
                  subtitleText={
                    "Average Authorized Utilization by Day - Parking " +
                    parkingDurationOptions.filter(
                      (obj) => obj.value === selectedSitesDuration
                    )[0].label
                  }
                  xAxisTitle={{
                    enabled: true,
                    text: "Day",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <StackedBarChart
                  // chartData={setChartDataOffUnoffByYearMonthHourData(
                  //   "N",
                  //   "total_auth1"
                  // )}
                  chartData={setChartDataOffUnoffByYearMonthDayData(
                    obj,
                    parkingDurationOptions.filter(
                      (obj) => obj.value === selectedSitesDuration
                    )[0].unauthorizedField
                  )}
                  plotOptionsColumnStacking={"normal"}
                  plotOptionsColumnDataLabelsEndabled={false}
                  chartHeight={"50%"}
                  themeHC={HighContrasLight}
                  yAxisTitleText={"# of Parking Events"}
                  legendSpecs={{
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                    itemMarginTop: 0.5,
                    itemMarginBottom: 0,
                    itemStyle: {
                      // color: "#000000",
                      // fontWeight: "bold",
                      fontSize: 10.5,
                    },
                  }}
                  titleText={setChartTitle(
                    query.selectedSite.year,
                    query.selectedSite.selected,
                    obj
                  )}
                  subtitleText={
                    "Average Unauthorized Utilization by Day - Parking " +
                    parkingDurationOptions.filter(
                      (obj) => obj.value === selectedSitesDuration
                    )[0].label
                  }
                  xAxisTitle={{
                    enabled: true,
                    text: "Day",
                  }}
                />
              </Grid>
            </Grid>
          );
        })}
    </div>
  );
}

export default ChartsLayoutSites;
