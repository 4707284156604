/**
 * @fileOverview the main UI component
 */

import React, { useState, useRef, useEffect, useMemo, useContext } from "react";

import logo from "../logo.svg";
import "../App.css";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeTheme, sidebarStyles, gridStyles } from "../ui/styles";
import "../App.scss";
// import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import Paper from "@material-ui/core/Paper";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
// import MenuItem from "@material-ui/core/MenuItem";
// import Menu from "@material-ui/core/Menu";

// import LeafletMap from "../map/LeafletMap.js";
import DeckglMapSites from "../map/DeckglMapSites.js";
// import DataTabPanel from "../TabPanel/DataTabPanel.js";

// import ResizePanel from "react-resize-panel";

import TablePanel from "../table/TablePanel";

// import HighStock from "../Charts/HighCharts/HighStock";
// import LineChart from "../Charts/HighCharts/LineChart";
// import StackedBarChart from "../Charts/HighCharts/StackedBarChart";
// import PieChart from "../Charts/HighCharts/PieChart";
// import StackedGrouped3dBarChart from "../Charts/HighCharts/StackedGrouped3dBarChart";
// import Donut3dChart from "../Charts/HighCharts/Donut3dChart";
// import MultiLineChart from "../Charts/HighCharts/MultiLineChart";
// import DependencyWheel from "../Charts/HighCharts/DependencyWheel";
// import SankeyDiagram from "../Charts/HighCharts/SankeyDiagram";

// import { QueryProvider, QueryContext } from "../context/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../context/GlobalContext";

import {
  // fetchStatewideData,
  // fetchParkingDataRegionByYearMonth,
  fetchParkingDataCountyByYearMonth,
  fetchParkingDataByRegionYearMonth,
  fetchLandUse,
  fetchParkingSitesGeom,
  findParkingSiteEvents,
  findSiteUnoffRatioByDirYearMonthDay,
  findSiteOffUnoffByYearMonthDay,
  findSiteOffUnoffByYearMonthHour,
  fetchExpansionFactorsSiteYearMonthy,
  findSiteAvgHourParkingByYearMonth,
} from "../data-loaders/DataLoaders";
// import axios from "axios";
import {
  yearsMonths,
  geographies,
  sitesYearsMonths,
  parkingSites,
} from "../common/constants";

import ChartsLayoutSites from "../Charts/ChartsLayoutSites";

import ChartsLayout from "../Charts/ChartsLayout";

import bbox from "@turf/bbox";
import { CollectionsOutlined } from "@material-ui/icons";

import TablePanelSites from "../table/TablePanelSites";

import Box from "@mui/material/Box";

function SitesLayout(props) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode = prefersDarkMode ? "dark" : "light";
  let theme = makeTheme(mode);
  theme = responsiveFontSizes(theme);
  const classesGrid = gridStyles();

  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();
  // console.log("query.selectedSite-check", query.selectedSite);

  // const { statusGuide } = props;

  // const [parkingSiteGeom, setParkingSiteGeom] = useState(null);
  // const [siteEventsGeom, setSiteEventsGeom] = useState(null);

  // const [siteSelected, setSiteSelected] = useState({
  //   selected: statusGuide,
  //   year: sitesYearsMonths[sitesYearsMonths.length - 1].year,
  //   month: sitesYearsMonths[0].month,
  //   // yearMonth: sitesYearsMonths[0].yearMonth,
  //   yearMonth: sitesYearsMonths.filter(
  //     (itm) => itm.year === sitesYearsMonths[sitesYearsMonths.length - 1].year
  //   )[0].yearMonth,
  //   parkingDataType: 0,
  //   parkingDurationCategories: [1, 2, 3, 4, 5],
  // });
  // console.log("siteSelected-check", siteSelected);
  const [siteOffUnoffByYearMonthHourData, setSiteOffUnoffByYearMonthHourData] =
    useState([{ sitename: "None" }]);
  const [siteOffUnoffByYearMonthDayData, setSiteOffUnoffByYearMonthDayData] =
    useState([{ sitename: "None" }]);

  const [unauthorizedPercentTable, setUnauthorizedPercentTable] =
    useState(null);
  const [spaceRatioData, setSpaceRatioData] = useState(null);
  const [unofficialDataDay, setUnofficialDataDay] = useState(null);
  // const [expansionFactor, setExpansionFactor] = useState(0.1);

  const [
    siteAvgHourParkingByYearMonthData,
    setSiteAvgHourParkingByYearMonthData,
  ] = useState([{ sitename: "None" }]);

  console.log(
    "siteAvgHourParkingByYearMonthData-check",
    siteAvgHourParkingByYearMonthData
  );

  // useEffect(() => {
  //   // if (statusGuide !== "statewide")
  //   //   setSiteSelected({
  //   //     selected: statusGuide,
  //   //     year: sitesYearsMonths[0].year,
  //   //     month: sitesYearsMonths[0].month,
  //   //     yearMonth: sitesYearsMonths[0].yearMonth,
  //   //     parkingDataType: 0,
  //   //     parkingDurationCategories: [1, 2, 3, 4, 5],
  //   //   });

  //   setSiteSelected({
  //     selected: statusGuide,
  //     year: siteSelected.year,
  //     month: siteSelected.month,
  //     yearMonth: siteSelected.yearMonth,
  //     parkingDataType: siteSelected.parkingDataType,
  //     parkingDurationCategories: siteSelected.parkingDurationCategories,
  //   });

  //   fetchParkingSitesGeom(statusGuide).then((response) => {
  //     // console.log("response-check", response);
  //     setParkingSiteGeom({
  //       type: "FeatureCollection",
  //       features: response.data,
  //     });
  //   });
  //   findParkingSiteEvents(
  //     statusGuide,
  //     siteSelected.year,
  //     siteSelected.month
  //   ).then((response) => {
  //     // console.log("response-check", response);
  //     setSiteEventsGeom(response.data);
  //   });
  // }, [statusGuide]);

  useEffect(() => {
    let site = query.selectedSite.selected;
    // console.log("site-check", site);

    async function asyncCall() {
      // console.log("siteSelected-check", siteSelected.year);
      // await fetchExpansionFactorsSiteYearMonthy(
      //   site,
      //   // ste,
      //   query.selectedSite.year,
      //   query.selectedSite.month
      // ).then((response) => {
      //   // console.log(
      //   //   "response-findSiteOffUnoffByYearMonthDay-check",
      //   //   response
      //   //   // siteOffUnoffByYearMonthHourData[0],
      //   //   // site
      //   // );
      //   // if (response !== false)
      //   //   // dispatch({
      //   //   //   type: "setSiteOffUnoffByYearMonthHourData",
      //   //   //   selection: response.data,
      //   //   // });
      //   //   setExpansionFactor(response.data[0].expansion_fact);
      // });
      // await fetchParkingSitesGeom(site).then((response) => {
      //   // console.log("response-check", response);
      //   setParkingSiteGeom({
      //     type: "FeatureCollection",
      //     features: response.data,
      //   });
      // });
      // await findParkingSiteEvents(
      //   site,
      //   query.selectedSite.year,
      //   query.selectedSite.month
      // ).then((response) => {
      //   // console.log("response-check", response);
      //   setSiteEventsGeom(response.data);
      // });
    }
    async function asyncCallGeom() {
      await fetchParkingSitesGeom(site).then((response) => {
        // console.log("response-check", response);
        // setParkingSiteGeom({
        //   type: "FeatureCollection",
        //   features: response.data,
        // });
        dispatch({
          type: "setParkingSiteGeom",
          selection: {
            type: "FeatureCollection",
            features: response.data,
          },
        });
      });

      await findParkingSiteEvents(
        site,
        query.selectedSite.year,
        query.selectedSite.month
      ).then((response) => {
        // console.log("response-check", response);

        // setSiteEventsGeom(response.data);
        dispatch({
          type: "setSiteEventsGeom",
          selection: response.data,
        });
      });
    }
    async function asyncCallSiteAvgHourParkingByYearMonthData() {
      // console.log("siteSelected-check", siteSelected.year);

      await findSiteAvgHourParkingByYearMonth(
        site,
        // ste,
        query.selectedSite.year,
        query.selectedSite.month
      ).then((response) => {
        // console.log(
        //   "response-findSiteOffUnoffByYearMonthDay-check",
        //   response
        //   // siteOffUnoffByYearMonthHourData[0],
        //   // site
        // );
        if (response !== false)
          // dispatch({
          //   type: "setSiteOffUnoffByYearMonthHourData",
          //   selection: response.data,
          // });
          setSiteAvgHourParkingByYearMonthData(response.data);
      });
    }
    if (site !== "null") {
      asyncCall();
      // asyncCall2();
      asyncCallGeom();
      asyncCallSiteAvgHourParkingByYearMonthData();
    }
    // console.log("data update check");
  }, [
    query.selectedSite.selected,
    query.selectedSite.year,
    query.selectedSite.month,
  ]);

  async function asyncCallSiteOffUnoffByYearMonthHour(site) {
    // console.log("siteSelected-check", siteSelected.year);
    await findSiteOffUnoffByYearMonthHour(
      site,
      // ste,
      query.selectedSite.year
    ).then((response) => {
      // console.log(
      //   "response-off-unoff-yr-hour-layout-LoadData-check",
      //   response,
      //   siteOffUnoffByYearMonthHourData[0],
      //   site
      // );
      if (response !== false)
        // dispatch({
        //   type: "setSiteOffUnoffByYearMonthHourData",
        //   selection: response.data,
        // });
        setSiteOffUnoffByYearMonthHourData(response.data);
    });
  }

  async function asyncCallSiteOffUnoffByYearMonthDayData(site) {
    // console.log("siteSelected-check", siteSelected.year);

    await findSiteOffUnoffByYearMonthDay(
      site,
      // ste,
      query.selectedSite.year
    ).then((response) => {
      // console.log(
      //   "response-findSiteOffUnoffByYearMonthDay-check",
      //   response
      //   // siteOffUnoffByYearMonthHourData[0],
      //   // site
      // );
      if (response !== false)
        // dispatch({
        //   type: "setSiteOffUnoffByYearMonthHourData",
        //   selection: response.data,
        // });
        setSiteOffUnoffByYearMonthDayData(response.data);
    });
  }
  useEffect(() => {
    let site = query.selectedSite.selected;
    // console.log("site-check", site);

    if (site !== "statusGuide") {
      // asyncCall(site);
      asyncCallSiteOffUnoffByYearMonthHour(site);
      asyncCallSiteOffUnoffByYearMonthDayData(site);
    }
    // console.log("data update check");
  }, [query.selectedSite.selected, query.selectedSite.year]);

  // useEffect(() => {
  //   let site = query.selectedSite.selected;
  //   // console.log("site-check", site);

  //   async function asyncCallGeom() {
  //     await fetchParkingSitesGeom(site).then((response) => {
  //       // console.log("response-check", response);
  //       setParkingSiteGeom({
  //         type: "FeatureCollection",
  //         features: response.data,
  //       });
  //     });

  //     await findParkingSiteEvents(
  //       site,
  //       query.selectedSite.year,
  //       query.selectedSite.month
  //     ).then((response) => {
  //       // console.log("response-check", response);

  //       setSiteEventsGeom(response.data);
  //     });
  //   }

  //   if (site !== "statusGuide") {
  //     // asyncCall();
  //     // asyncCall2();
  //     asyncCallGeom();
  //   }
  // }, [query.selectedSite.selected]);

  // console.log("unauthorizedPercentTable-check", unauthorizedPercentTable);
  // useEffect(() => {
  //   if (statusGuide !== "statewide")
  //     //   setSiteSelected({
  //     //     selected: statusGuide,
  //     //     year: siteSelected.year,
  //     //     month: siteSelected.month,
  //     //   });
  //     fetchParkingSitesGeom(statusGuide).then((response) => {
  //       // console.log("response-check", response);
  //       setParkingSiteGeom({
  //         type: "FeatureCollection",
  //         features: response.data,
  //       });
  //     });
  //   findParkingSiteEvents(
  //     statusGuide,
  //     siteSelected.year,
  //     siteSelected.month
  //   ).then((response) => {
  //     //   console.log("response-check", response);
  //     setSiteEventsGeom(response.data);
  //   });

  //   // fetchExpansionFactorsSiteYearMonthy(
  //   //   siteSelected.selected,
  //   //   // ste,
  //   //   siteSelected.year,
  //   //   siteSelected.month
  //   // ).then((response) => {
  //   //   // console.log(
  //   //   //   "response-findSiteOffUnoffByYearMonthDay-check",
  //   //   //   response
  //   //   //   // siteOffUnoffByYearMonthHourData[0],
  //   //   //   // siteSelected.selected
  //   //   // );
  //   //   if (response !== false)
  //   //     // dispatch({
  //   //     //   type: "setSiteOffUnoffByYearMonthHourData",
  //   //     //   selection: response.data,
  //   //     // });
  //   //     setExpansionFactor(response.data[0].expansion_fact);
  //   // });
  // }, [siteSelected.year, siteSelected.month]);

  // console.log("SitesLayout-call-check");
  let hourlyCountsSummaryVars = [
    "unauth_ratio",
    "unauth1_ratio",
    "unauth1_3_ratio",
    "unauth3_7_ratio",
    "unauth7_11_ratio",
    "unauth11_ratio",
  ];
  useEffect(() => {
    if (query.parkingSiteGeom && query.siteEventsGeom) {
      let tempDataTable = [];
      [
        "less than 1 hour",
        "1-3 hours",
        "3-7 hours",
        "7-11 hours",
        "greater than 11 hours",
      ].map((obj) => {
        let dataTemp = calcUnauthorizedPercent(obj, query.siteEventsGeom);
        // console.log("dataTemp-check", dataTemp);
        tempDataTable.push(dataTemp);
      });
      let totalCount = sumByName(tempDataTable, "totalCount");
      let authorizedCount = sumByName(tempDataTable, "authorizedCount");
      let unauthorizedCount = sumByName(tempDataTable, "unauthorizedCount");
      let percentUnauthorized =
        totalCount > 0 ? (unauthorizedCount / totalCount) * 100 : 0;
      let percentAuthorized =
        totalCount > 0 ? (authorizedCount / totalCount) * 100 : 0;
      tempDataTable.push({
        siteName: query.selectedSite.selected,
        yearMonth: query.selectedSite.yearMonth,
        parkingCategory: "Total",
        totalCount: totalCount,
        authorizedCount: authorizedCount,
        unauthorizedCount: unauthorizedCount,
        percentUnauthorized: percentUnauthorized,
        percentAuthorized: percentAuthorized,
      });
      setUnauthorizedPercentTable(tempDataTable);

      let tempSpaceRatioData = [];
      let tempUnofficialRatioDataDay = [];
      let siteDirs = parkingSites.filter(
        (obj) => obj.name === query.selectedSite.selected
      )[0].dir;
      // console.log("siteDirs-check", siteDirs);
      siteDirs.map((ste) => {
        // console.log("ste-check", ste);
        let siteLabel =
          query.selectedSite.selected +
          (ste === "N"
            ? " - Northbound"
            : ste === "S"
            ? " - Southbound"
            : ste === "W"
            ? " - Westbound"
            : ste === "E"
            ? " - Eastbound"
            : "");
        let dailyAveTotalCount = (
          query.siteEventsGeom.filter(
            (obj) =>
              obj.sitename === query.selectedSite.selected && obj.dir === ste
          ).length / 31
        ).toFixed(0);
        // let spaces = 10;
        // let spaces2 = 10;
        // if (siteEventsGeom)
        //   spaces2 = siteEventsGeom.features.properties.filter(
        //     (fc) => fc.dir === ste
        //   );
        let tempSiteData = [];
        query.parkingSiteGeom.features.map((fc) =>
          tempSiteData.push(fc.properties)
        );
        // console.log("siteEventsGeom-check", siteEventsGeom);
        // console.log("tempSiteData-check", tempSiteData);
        let spaces = 0;
        if (
          tempSiteData.filter((fc) => fc.dir === ste && fc.authorized === 1)[0]
        )
          spaces = tempSiteData.filter(
            (fc) => fc.dir === ste && fc.authorized === 1
          )[0].space;
        tempSpaceRatioData.push({
          siteName: query.selectedSite.selected,
          dir: ste,
          yearMonth: query.selectedSite.yearMonth,
          siteLabel: siteLabel,
          totalCount: dailyAveTotalCount,
          spaces: spaces,
          spaceRatio: spaces > 0 ? (dailyAveTotalCount / spaces) * 100 : 0,
        });

        findSiteUnoffRatioByDirYearMonthDay(
          query.selectedSite.selected,
          ste,
          query.selectedSite.year,
          query.selectedSite.month
        ).then((response) => {
          // console.log(
          //   "findSiteUnoffRatioByDirYearMonthDay-response-check",
          //   response,
          //   siteSelected
          // );
          // setSiteEventsGeom(response.data);

          let daysTemp = [];
          let chartDataTemp = [];
          hourlyCountsSummaryVars.map((item, index) => {
            chartDataTemp.push([]);
          });

          response.data.map((obj) => {
            daysTemp.push(obj.start_day);

            hourlyCountsSummaryVars.map((item, index) => {
              chartDataTemp[index].push(obj[item]);
            });
          });

          tempUnofficialRatioDataDay.push({
            siteName: query.selectedSite.selected,
            dir: ste,
            yearMonth: query.selectedSite.yearMonth,
            siteLabel: siteLabel,
            days: daysTemp,
            chartData: chartDataTemp,
          });
        });
      });
      setSpaceRatioData(tempSpaceRatioData);
      setUnofficialDataDay(tempUnofficialRatioDataDay);
    }
  }, [query.siteEventsGeom]);

  function calcUnauthorizedPercent(parkCat, eventsData) {
    let totalCount = eventsData.filter(
      (obj) => obj.parking_duration === parkCat
    ).length;

    let authorizedCount = eventsData.filter(
      (obj) =>
        obj.parking_duration === parkCat && obj.authorized === "authorized"
    ).length;

    let unauthorizedCount = eventsData.filter(
      (obj) =>
        obj.parking_duration === parkCat && obj.authorized === "unauthorized"
    ).length;

    let percentUnauthorized =
      totalCount > 0 ? (unauthorizedCount / totalCount) * 100 : 0;

    let percentAuthorized =
      totalCount > 0 ? (authorizedCount / totalCount) * 100 : 0;

    return {
      siteName: query.selectedSite.selected,
      yearMonth: query.selectedSite.yearMonth,
      parkingCategory: parkCat,
      totalCount: totalCount,
      authorizedCount: authorizedCount,
      unauthorizedCount: unauthorizedCount,
      percentAuthorized: percentAuthorized,
      percentUnauthorized: percentUnauthorized,
    };
  }

  const sumByName = (data, name) => {
    return data.reduce(function (a, b) {
      return a + b[name];
    }, 0);
  };

  /* Region boundary geometry in Leaflet's (latitude,longitude) coordinate system */
  const [bounds] = useState([
    [37.99093286, -79.48710834],
    [39.72301501, -75.05239796],
  ]);

  const stageCanvasRef = useRef(null);
  const [chartHeight, setChartHeight] = useState(600);
  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (stageCanvasRef.current) {
      let height = stageCanvasRef.current.offsetHeight;
      let width = stageCanvasRef.current.offsetWidth;

      setChartHeight(stageCanvasRef.current.offsetHeight);
    }
  }, [stageCanvasRef]);

  // console.log(
  //   "siteOffUnoffByYearMonthHourData-check",
  //   siteOffUnoffByYearMonthHourData
  // );

  return (
    <div>
      {/* <QueryProvider> */}
      <Grid container spacing={0} className={classesGrid.gridRow1}>
        <Grid item xs={7}>
          <Grid item xs={12}>
            <Box sx={{ height: "75vh" }}>
              <div id={"map"} className={mode}>
                {/* <LeafletMap
                    // mode={mode}
                    bounds={bounds}
                    // year={year}
                    // region={region}
                    // county={county}
                    // setYear={setYear}
                    // layerID={statsLayerParam}
                    // geoRoute={geoRoute}
                    // segmentsTable={segmentsTable}
                    // segmentSelection={segmentSelection}
                    // dataRoute="hpms/data"
                    Zoom={8}
                    position={[39.045753, -76.641273]}
                    ref={mapRef}
                  />{" "} */}
                <DeckglMapSites
                // year={year}
                // layerID={statsLayerParam}
                // //district={districtParam}
                // route={geoRoute}
                // //minZoom={6}
                // //Zoom={6}
                // // utprawdata={utprawdata}
                // //position={[31.243333, -97.839333]}
                // statusGuide={statusGuide}
                // mode={mode}
                // mapType={"deckGL"}
                //   regionSelected={regionSelected}
                //   setRegionSelected={setRegionSelected}
                //   statewideData={statewideData}
                //   isLoading={isLoading}
                //   landUseData={landUseData}
                // parkingSiteGeom={parkingSiteGeom}
                // siteEventsGeom={siteEventsGeom}
                // siteSelected={siteSelected}
                // setSiteSelected={setSiteSelected}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid item xs={12} sx={{ ml: 11, pl: 11, display: "flex" }}>
            <TablePanelSites
              unauthorizedPercentTable={unauthorizedPercentTable}
              spaceRatioData={spaceRatioData}
              // expansionFactor={expansionFactor}
              siteAvgHourParkingByYearMonthData={
                siteAvgHourParkingByYearMonthData
              }
            ></TablePanelSites>
          </Grid>
        </Grid>
        <Grid item xs={12} direction="row">
          <Grid item xs={12}>
            {siteOffUnoffByYearMonthHourData != null && (
              <div>
                <ChartsLayoutSites
                  parkingSiteGeom={query.parkingSiteGeom}
                  // statusGuide={statusGuide}
                  // siteSelected={siteSelected}
                  chartHeight={chartHeight}
                  siteOffUnoffByYearMonthHourData={
                    siteOffUnoffByYearMonthHourData
                  }
                  siteOffUnoffByYearMonthDayData={
                    siteOffUnoffByYearMonthDayData
                  }
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default SitesLayout;
