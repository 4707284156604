import React, { useState, useContext } from "react";
// import { QueryContext } from "../context/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../context/GlobalContext";
import { makeStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

import {
  yearsMonths,
  geographies,
  sitesYearsMonths,
  sitesYears,
  sitesMonths,
} from "../common/constants";

import CircleIcon from "@mui/icons-material/Circle";
import Typography from "@material-ui/core/Typography";
import SquareIcon from "@mui/icons-material/Square";
import CropSquareIcon from "@mui/icons-material/CropSquare";

import IconButton from "@mui/material/IconButton";

import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const useStyles = makeStyles((theme) => ({
  menuListContainer: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  inactiveLayer: {
    color: "#C0C0C0",
  },
  activeLayer: {
    color: "inherit",
  },
}));

let optionsYearsMonths = [];
yearsMonths.map((obj) => {
  optionsYearsMonths.push(obj.yearMonth);
});

let optionsGeographies = [];
geographies.map((obj) => {
  optionsGeographies.push(obj.label);
});

export default function DataParameters(props) {
  const { siteSelected, setSiteSelected, dataLayers } = props;
  const classes = useStyles();
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElYears, setAnchorElYears] = React.useState(null);
  const [anchorElMonths, setAnchorElMonths] = React.useState(null);
  const [anchorElGeography, setAnchorElGeography] = React.useState(null);
  const [anchorElDataType, setAnchorElDataType] = React.useState(null);

  const [selectedIndexDataType, setSelectedIndexDataType] = React.useState(
    siteSelected.parkingDataType
  );

  const handleChangeYears = (event) => {
    let yearMonthTemp = sitesYearsMonths.filter(
      (obj) =>
        obj.year === event.target.value && obj.month === siteSelected.month
    )[0].yearMonth;
    // console.log(
    //   "yearMonthTemp-check",
    //   sitesYears[index],
    //   siteSelected.month,
    //   yearMonthTemp
    // );

    setSiteSelected({
      selected: siteSelected.selected,
      year: event.target.value,
      month: siteSelected.month,
      yearMonth: yearMonthTemp,
      parkingDataType: siteSelected.parkingDataType,
      parkingDurationCategories: siteSelected.parkingDurationCategories,
    });
  };

  const handleChangeMonths = (event) => {
    let yearMonthTemp = sitesYearsMonths.filter(
      (obj) =>
        obj.year === siteSelected.year && obj.month === event.target.value
    )[0].yearMonth;
    // console.log(
    //   "yearMonthTemp-check",
    //   sitesYears[index],
    //   siteSelected.month,
    //   yearMonthTemp
    // );
    setSiteSelected({
      selected: siteSelected.selected,
      year: siteSelected.year,
      month: event.target.value,
      yearMonth: yearMonthTemp,
      parkingDataType: siteSelected.parkingDataType,
      parkingDurationCategories: siteSelected.parkingDurationCategories,
    });
  };

  function handleChangeDataLayer(key) {
    let item = dataLayers.filter((obj) => obj.key === key)[0];
    item.setVisibility(!item.visibility);
  }
  function handleChangeParkingDurationCats(key) {
    // console.log("key-check", key);
    let selectedDataCats = siteSelected.parkingDurationCategories;
    const index = selectedDataCats.indexOf(key);

    if (index === -1) selectedDataCats.push(key);
    else selectedDataCats.splice(index, 1);
    setSiteSelected({
      selected: siteSelected.selected,
      year: siteSelected.year,
      month: siteSelected.month,
      yearMonth: siteSelected.yearMonth,
      parkingDataType: siteSelected.parkingDataType,
      parkingDurationCategories: selectedDataCats,
    });
  }

  function setDataLayerClass(key) {
    let item = dataLayers.filter((obj) => obj.key === key)[0]; // console.log("item-check", item);
    if (item.visibility) return classes.activeLayer;
    else return classes.inactiveLayer;
  }

  function setDataLayerTooltip(key) {
    let item = dataLayers.filter((obj) => obj.key === key)[0];

    if (item.visibility) return "Click to hide layer.";
    else return "Click to show layer - " + item.label + ".";
  }

  function setParkingDurationCatsClass(key) {
    let selectedDataCats = siteSelected.parkingDurationCategories;
    const index = selectedDataCats.indexOf(key);

    if (index === -1) return classes.inactiveLayer;
    else return classes.activeLayer;
  }

  function setParkingDurationCatsTooltip(key) {
    let selectedDataCats = siteSelected.parkingDurationCategories;
    const index = selectedDataCats.indexOf(key);

    let descText = "";
    if (key === 1) descText = "Less than 1 hour";
    if (key === 2) descText = "1 to 3 hours";
    if (key === 3) descText = "3 to 7 hours";
    if (key === 4) descText = "7 to 11 hours";
    if (key === 5) descText = "Greater than 11 hours";

    if (index === -1)
      return "Click to show layer - Parking Duration " + descText + ".";
    else return "Click to hide layer.";
  }

  return (
    <Box
      sx={{
        width: 200,
        // height: 300,
        // backgroundColor: "#D3D3D3",
        backgroundColor: "#FFFFFF",
        borderRadius: 4,
        // backgroundColor: "primary.dark",
        // "&:hover": {
        //   backgroundColor: "primary.main",
        //   opacity: [0.9, 0.8, 0.7],
        // },
        marginRight: 2,
        marginTop: "10px",
      }}
    >
      <FormControl sx={{ m: 1, mt: 2, minWidth: 120 }} size="small">
        <InputLabel id="sites-year-label">Year:</InputLabel>
        <Select
          labelId="sites-year-label-small"
          id="sites-year-select-small"
          value={siteSelected.year}
          label="Year:"
          onChange={handleChangeYears}
        >
          {sitesYears.map((option) => (
            <MenuItem
              key={"sites-year-" + option}
              value={option}
              disabled={option === 2018 && true}
            >
              {option}
              {option === 2018 && " - Coming Soon"}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="sites-month-label-small">Month:</InputLabel>
        <Select
          labelId="sites-month-label-small"
          id="sites-month-select-small"
          value={siteSelected.month}
          label="Month:"
          onChange={handleChangeMonths}
        >
          {sitesMonths.map((option) => (
            <MenuItem key={"sites-year-" + option.month} value={option.month}>
              {option.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ marginLeft: 2 }}>
        <Typography
          variant="h6"
          noWrap
          sx={{ marginLeft: 20, fontSize: 8, fontStyle: "bold" }}
        >
          Interactive Legend
        </Typography>
      </Box>
      <Box sx={{ marginLeft: 2 }}>
        <Typography
          // variant="h6"
          noWrap
          sx={{ marginLeft: 20, fontSize: 8 }}
        >
          Parking Duration:
        </Typography>
      </Box>
      <Tooltip title={setParkingDurationCatsTooltip(1)}>
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => handleChangeParkingDurationCats(1)}
          color="inherit"
          sx={{ padding: 0 }}
          //change cursor type for buttons - keep
          // style={noPointer}
        >
          <Typography
            noWrap
            // sx={{ marginLeft: 20 }}
            className={setParkingDurationCatsClass(1)}
          >
            <CircleIcon
              fontSize="small"
              sx={{ color: "#7F3C8D", fontSize: 12, marginLeft: 2 }}
            />{" "}
            Less than 1 hour
          </Typography>
        </IconButton>
        {/* <Typography noWrap sx={{ marginLeft: 20 }}>
        <CircleIcon
          fontSize="small"
          sx={{ color: "#7F3C8D", fontSize: 12, marginLeft: 2 }}
        />{" "}
        Less than 1 hour
      </Typography> */}
      </Tooltip>
      <Tooltip title={setParkingDurationCatsTooltip(2)}>
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => handleChangeParkingDurationCats(2)}
          color="inherit"
          sx={{ padding: 0 }}
          //change cursor type for buttons - keep
          // style={noPointer}
        >
          <Typography
            noWrap
            // sx={{ marginLeft: 20 }}
            className={setParkingDurationCatsClass(2)}
          >
            <CircleIcon
              fontSize="small"
              sx={{ color: "#11A579", fontSize: 12, marginLeft: 2 }}
            />{" "}
            1 to 3 hours
          </Typography>
        </IconButton>
      </Tooltip>
      <Tooltip title={setParkingDurationCatsTooltip(3)}>
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => handleChangeParkingDurationCats(3)}
          color="inherit"
          sx={{ padding: 0 }}
          //change cursor type for buttons - keep
          // style={noPointer}
        >
          <Typography
            noWrap
            // sx={{ marginLeft: 20 }}
            className={setParkingDurationCatsClass(3)}
          >
            <CircleIcon
              fontSize="small"
              sx={{ color: "#3969AC", fontSize: 12, marginLeft: 2 }}
            />{" "}
            3 to 7 hours
          </Typography>
        </IconButton>
      </Tooltip>
      <Tooltip title={setParkingDurationCatsTooltip(4)}>
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => handleChangeParkingDurationCats(4)}
          color="inherit"
          sx={{ padding: 0 }}
          //change cursor type for buttons - keep
          // style={noPointer}
        >
          <Typography
            noWrap
            // sx={{ marginLeft: 20 }}
            className={setParkingDurationCatsClass(4)}
          >
            <CircleIcon
              fontSize="small"
              sx={{ color: "#F2B701", fontSize: 12, marginLeft: 2 }}
            />{" "}
            7 to 11 hours
          </Typography>
        </IconButton>
      </Tooltip>
      <Tooltip title={setParkingDurationCatsTooltip(5)}>
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => handleChangeParkingDurationCats(5)}
          color="inherit"
          sx={{ padding: 0 }}
          //change cursor type for buttons - keep
          // style={noPointer}
        >
          <Typography
            noWrap
            // sx={{ marginLeft: 20 }}
            className={setParkingDurationCatsClass(5)}
          >
            <CircleIcon
              sx={{ color: "#E73F74", fontSize: 12, marginLeft: 2 }}
            />{" "}
            Greater than 11 hours
          </Typography>
        </IconButton>
      </Tooltip>
      <Box sx={{ marginLeft: 2 }}>
        <Typography
          // variant="h6"
          noWrap
          sx={{ marginLeft: 20, fontSize: 8 }}
        >
          Parking Areas:
        </Typography>
      </Box>
      <Tooltip title={setDataLayerTooltip("authorized-parking-area")}>
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => handleChangeDataLayer("authorized-parking-area")}
          color="inherit"
          sx={{ padding: 0 }}
          //change cursor type for buttons - keep
          // style={noPointer}
        >
          <Typography
            noWrap
            // sx={{ marginLeft: 20 }}
            className={setDataLayerClass("authorized-parking-area")}
          >
            <SquareIcon
              sx={{ color: "#90ee90", fontSize: 16, marginLeft: 2 }}
            />{" "}
            Authorized Parking
          </Typography>
        </IconButton>
      </Tooltip>
      <Tooltip title={setDataLayerTooltip("unauthorized-parking-area")}>
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => handleChangeDataLayer("unauthorized-parking-area")}
          color="inherit"
          sx={{ padding: 0 }}
          //change cursor type for buttons - keep
          // style={noPointer}
        >
          <Typography
            noWrap
            // sx={{ marginLeft: 20 }}
            className={setDataLayerClass("unauthorized-parking-area")}
          >
            <CropSquareIcon
              sx={{
                color: "#ff0000",
                opacity: 0.5,

                fontSize: 16,
                marginLeft: 2,
              }}
            />{" "}
            Unauthorized Parking
          </Typography>
        </IconButton>
      </Tooltip>
    </Box>
  );
}
