import React, { useEffect } from "react";
import { render } from "react-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);

export default function MultiLineChart(props) {
  const {
    chartHeight,
    chartData,
    titleText,
    subtitleText,
    yAxisTitleText,
    xAxisRangeDescription,
    legendLayout,
    legendAlign,
    legendVerticalAlign,
    seriesPointStart,
    themeHC,
    chartRef2,
  } = props;

  // console.log("chartData-check-mlc", chartData);
  let chartData2 = [
    {
      marker: {
        fillColor: "transparent",
        lineColor: Highcharts.getOptions().colors[0],
      },
      data: [...Array(12)].map(Math.random),
    },
    {
      marker: {
        fillColor: "transparent",
      },
      data: [...Array(12)].map(Math.random),
    },
    {
      data: [...Array(12)].map(Math.random),
    },
    {
      lineColor: "red",
      data: [...Array(12)].map(Math.random),
    },
  ];
  // console.log("chartData2-check", chartData, chartData2);

  const options = {
    chart: {
      height: chartHeight,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: titleText,
    },

    subtitle: {
      text: subtitleText,
    },

    yAxis: {
      title: {
        text: yAxisTitleText,
      },
    },

    // xAxis: {
    //   accessibility: {
    //     rangeDescription: xAxisRangeDescription,
    //   },
    // },

    // legend: {
    //   layout: legendLayout,
    //   align: legendAlign,
    //   verticalAlign: legendVerticalAlign,
    // },
    legend: {
      // align: "right",
      // verticalAlign: "top",
      layout: "horizontal",
      // x: 0,
      // y: 100,
      itemStyle: {
        // color: "#000000",
        // fontWeight: "bold",
        fontSize: 11,
      },
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: seriesPointStart,
      },
    },
    series: chartData,
    // series: [
    //   {
    //     marker: {
    //       fillColor: "transparent",
    //       lineColor: Highcharts.getOptions().colors[0],
    //     },
    //     data: [...Array(12)].map(Math.random),
    //   },
    //   {
    //     marker: {
    //       fillColor: "transparent",
    //     },
    //     data: [...Array(12)].map(Math.random),
    //   },
    //   {
    //     data: [...Array(12)].map(Math.random),
    //   },
    //   {
    //     lineColor: "red",
    //     data: [...Array(12)].map(Math.random),
    //   },
    // ],

    // responsive: {
    //     rules: [{
    //         condition: {
    //             maxWidth: 500
    //         },
    //         chartOptions: {
    //             legend: {
    //                 layout: 'horizontal',
    //                 align: 'center',
    //                 verticalAlign: 'bottom'
    //             }
    //         }
    //     }]
    // }
  };

  return (
    <div>
      {/* <h1>Test #5</h1> */}
      <HighchartsReact
        //   highcharts={themeHC(Highcharts)}
        highcharts={Highcharts}
        options={options}
        updateArgs={[true]}
        // ref={chartRef2}
      />
    </div>
  );
}
