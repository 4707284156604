/**
 * @fileOverview the main UI component
 */

import React, { useState, useRef, useEffect, useMemo, useContext } from "react";

import "../App.css";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeTheme, sidebarStyles, gridStyles } from "../ui/styles";
import "../App.scss";
import Grid from "@material-ui/core/Grid";
import DeckglMap from "../map/DeckglMap.js";
import TablePanel from "../table/TablePanel";

// import HighStock from "../Charts/HighCharts/HighStock";
// import LineChart from "../Charts/HighCharts/LineChart";
// import StackedBarChart from "../Charts/HighCharts/StackedBarChart";
// import PieChart from "../Charts/HighCharts/PieChart";
// import StackedGrouped3dBarChart from "../Charts/HighCharts/StackedGrouped3dBarChart";
// import Donut3dChart from "../Charts/HighCharts/Donut3dChart";
// import MultiLineChart from "../Charts/HighCharts/MultiLineChart";
// import DependencyWheel from "../Charts/HighCharts/DependencyWheel";
// import SankeyDiagram from "../Charts/HighCharts/SankeyDiagram";

// import { QueryContext } from "../context/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../context/GlobalContext";

import {
  // fetchStatewideData,
  // fetchParkingDataRegionByYearMonth,
  fetchParkingDataCountyByYearMonth,
  fetchParkingDataByRegionYearMonth,
  fetchLandUse,
  fetchStateOverTime,
} from "../data-loaders/DataLoaders";
import { yearsMonths, geographies } from "../common/constants";

import ChartsLayout from "../Charts/ChartsLayout";

import Box from "@mui/material/Box";

function StatewideLayout(props) {
  // console.log("statewide-layer-check");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode = prefersDarkMode ? "dark" : "light";
  let theme = makeTheme(mode);
  theme = responsiveFontSizes(theme);
  const classes = sidebarStyles();
  const classesGrid = gridStyles();
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  // const [statusGuide, setStatusGuide] = useState("Western Region");
  const { statusGuide } = props;

  /* Region boundary geometry in Leaflet's (latitude,longitude) coordinate system */
  const [bounds] = useState([
    [37.99093286, -79.48710834],
    [39.72301501, -75.05239796],
  ]);

  const stageCanvasRef = useRef(null);
  const [chartHeight, setChartHeight] = useState(600);
  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (stageCanvasRef.current) {
      let height = stageCanvasRef.current.offsetHeight;
      let width = stageCanvasRef.current.offsetWidth;

      setChartHeight(stageCanvasRef.current.offsetHeight);
    }
  }, [stageCanvasRef]);

  const mapRef = React.useRef(null);

  const [stateOverTime, setStateOverTime] = useState([]);
  // useEffect(() => {
  //   if (stateOverTime.length === 0) {
  //     const buildStateOverTime = async () => {
  //       await fetchStateOverTime().then((res) => {
  //         console.log("res.data-check", res.data);
  //         setStateOverTime(res.data);
  //       });
  //     };
  //     buildStateOverTime();
  //   }
  // });
  // const [regionSelected, setRegionSelected] = useState({
  //   selected: statusGuide,
  //   year: 2021,
  //   month: 1,
  //   yearMonthOptionsIndex: 36,
  //   // geographyOptionsIndex: 1,
  //   geographyOptionsIndex: geographies.findIndex(
  //     (obj) => obj.label === statusGuide
  //   ),
  //   regionParkingDataType: 0,
  //   parkingDurationCategories: [1, 2, 3, 4, 5],
  // });
  useMemo(() => {
    // setRegionSelected({
    //   selected: statusGuide,
    //   year: 2021,
    //   month: 1,
    //   yearMonthOptionsIndex: 36,
    //   // geographyOptionsIndex: 1,
    //   geographyOptionsIndex: geographies.findIndex(
    //     (obj) => obj.label === statusGuide
    //   ),
    //   regionParkingDataType: 0,
    //   parkingDurationCategories: regionSelected.parkingDurationCategories,
    // });

    const buildStateOverTime = async () => {
      await fetchStateOverTime().then((res) => {
        // console.log("res.data-check", res.data);
        setStateOverTime(res.data);
      });
    };
    buildStateOverTime();
  }, [query.regionSelected.geographyOptionsIndex]);

  /*loading overlay status to display spinner*/
  const [isLoading, setIsLoading] = useState({
    current: 0,
    total: 0,
    progress: 0,
  });

  // const statewideData = sampleStateData;
  // const [statewideData, setStatewideData] = useState(null);
  const [fullLandUseData, setFullLandUseData] = useState(null);
  const [landUseData, setLandUseData] = useState(null);

  useEffect(() => {
    let counties =
      geographies[query.regionSelected.geographyOptionsIndex].counties;
    let yearMonth = [
      { year: query.regionSelected.year, month: query.regionSelected.month },
      // { year: 2018, month: 1 },
      // { year: 2018, month: 4 },
      // { year: 2018, month: 7 },
      // { year: 2018, month: 10 },
    ];

    // console.log("counties-check", counties);

    let f = [];

    let cntyCounter = 0;
    if (counties.length > 0) {
      const fetchTParkingData = async () => {
        await counties.map((cnty, index) => {
          // setIsLoading(true);
          // console.log("cnty_index-check", index, counties.length);
          yearMonth.map((obj) => {
            fetchParkingDataCountyByYearMonth([cnty], obj.year, obj.month).then(
              (res) => {
                cntyCounter = cntyCounter + 1;
                setIsLoading({
                  current: cntyCounter,
                  total: counties.length,
                  progress: (cntyCounter / counties.length) * 100,
                });
                // setIsLoading(true);
                // console.log("res.data - check", "cnty=", cnty, res.data);
                f = f.concat(res.data);
                // if (cntyCounter === counties.length) setStatewideData(f);
                if (cntyCounter === counties.length)
                  dispatch({
                    type: "setStatewideData",
                    selection: f,
                  });

                // setIsLoading(false);
              }
            );
          });
        });
      };
      fetchTParkingData();

      let countiesLandUse =
        geographies[query.regionSelected.geographyOptionsIndex].countiesLandUse;
      if (fullLandUseData) {
        if (
          geographies[query.regionSelected.geographyOptionsIndex].lookup ===
          "statewide"
        ) {
          setLandUseData(fullLandUseData);
        } else {
          let tempData = fullLandUseData.features;

          let keepData = [];
          let cntyLandUse = {};

          countiesLandUse.map((obj) => {
            cntyLandUse = tempData.filter(
              (fobj) => fobj.properties.COUNTY === obj
            );
            // console.log("cntyLandUse-check", cntyLandUse);
            // if (keepData.length === 0) keepData = cntyLandUse;
            // else keepData.concat(cntyLandUse);
            keepData = [...keepData, ...cntyLandUse];
            // cntyLandUse = tempData.filter(
            //   (fobj) => fobj.properties.COUNTY === obj
            // );
            // keepData = { ...keepData, cntyLandUse };
          });

          // keepData = tempData.filter(
          //   (obj) =>
          //     obj.properties.COUNTY === "WASH" ||
          //     obj.properties.COUNTY === "ALLE" ||
          //     obj.properties.COUNTY === "GARR"
          // );
          // console.log("keepData-check", keepData);
          // setLandUseData(res.data);
          setLandUseData({
            type: "FeatureCollection",
            name: "Maryland_Land_Use_Land_Cover_-_Land_Use_Land_Cover_2010",
            crs: {
              type: "name",
              properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
            },
            features: keepData,
          });
        }
      } else {
        fetchLandUse().then((res) => {
          // console.log("res-check", res);
          let tempData = res.data.features;
          tempData = tempData.filter(
            (fobj) =>
              fobj.properties.Description === "Agriculture" ||
              fobj.properties.Description === "Barren Land" ||
              fobj.properties.Description === "Commercial" ||
              fobj.properties.Description === "High Density Residential" ||
              fobj.properties.Description === "Industrial" ||
              fobj.properties.Description === "Institutional" ||
              fobj.properties.Description === "Low Density Residential" ||
              fobj.properties.Description === "Medium Density Residential" ||
              fobj.properties.Description === "Other Developed Lands" ||
              fobj.properties.Description === "Transportation" ||
              fobj.properties.Description === "Very Low Density Residential"
            // Dropped 'Forest', 'Water', 'Wetlands'
          );
          // setFullLandUseData(res.data);
          setFullLandUseData({
            type: "FeatureCollection",
            name: "Maryland_Land_Use_Land_Cover_-_Land_Use_Land_Cover_2010",
            crs: {
              type: "name",
              properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
            },
            features: tempData,
          });

          let keepData = [];
          let cntyLandUse = {};

          countiesLandUse.map((obj) => {
            cntyLandUse = tempData.filter(
              (fobj) => fobj.properties.COUNTY === obj
            );
            // console.log("cntyLandUse-check", cntyLandUse);
            // if (keepData.length === 0) keepData = cntyLandUse;
            // else keepData.concat(cntyLandUse);
            keepData = [...keepData, ...cntyLandUse];
            // cntyLandUse = tempData.filter(
            //   (fobj) => fobj.properties.COUNTY === obj
            // );
            // keepData = { ...keepData, cntyLandUse };
          });

          // keepData = tempData.filter(
          //   (obj) =>
          //     obj.properties.COUNTY === "WASH" ||
          //     obj.properties.COUNTY === "ALLE" ||
          //     obj.properties.COUNTY === "GARR"
          // );
          // console.log("keepData-check", keepData);
          // setLandUseData(res.data);
          setLandUseData({
            type: "FeatureCollection",
            name: "Maryland_Land_Use_Land_Cover_-_Land_Use_Land_Cover_2010",
            crs: {
              type: "name",
              properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
            },
            features: keepData,
          });
          // res.data.filter((obj) => obj.features.properties.COUNTY === "ALLE") "WASH", "ALLE", "GARR"
        });
      }
    }
  }, [
    // statusGuide,
    // query.regionSelected,
    query.regionSelected.geographyOptionsIndex,
    query.regionSelected.yearMonth,
  ]);

  return (
    <div>
      <Grid container spacing={0} className={classesGrid.gridRow1}>
        <Grid item xs={7}>
          <Box sx={{ height: "75vh" }}>
            <div id={"map"} className={mode}>
              <DeckglMap
                // year={year}
                // layerID={statsLayerParam}
                // //district={districtParam}
                // route={geoRoute}
                // //minZoom={6}
                // //Zoom={6}
                // // utprawdata={utprawdata}
                // //position={[31.243333, -97.839333]}
                // statusGuide={statusGuide}
                // mode={mode}
                // mapType={"deckGL"}

                // setRegionSelected={setRegionSelected}
                // statewideData={statewideData}
                isLoading={isLoading}
                landUseData={landUseData}
              />
            </div>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <div
            id={"stateDataPanel"}
            style={{ marginRight: "2px", minHeight: "68vh" }}
          >
            <TablePanel titleDesc="Parking Events" />
          </div>
        </Grid>
      </Grid>

      <ChartsLayout
        chartHeight={chartHeight}
        // statewideData={statewideData}
        stateOverTime={stateOverTime}
      />
    </div>
  );
}

export default StatewideLayout;
