import React, { useState, useContext, useEffect } from "react";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
import MaterialTable from "material-table";

// import { QueryContext } from "../context/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../context/GlobalContext";

// const useStyles = makeStyles((theme) => ({
//     root: {
//       backgroundColor: theme.palette.background.paper,
//       width: "50vw",
//       height: "25vh"
//     },
//   }));

export default function TablePanel(props) {
  // const classes = useStyles();
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const { titleDesc } = props;

  const [tableData, setTableData] = useState(query.regionCategoryTableData);
  useEffect(() => {
    let dataTemp = query.regionCategoryTableData;
    if (dataTemp.length > 1)
      dataTemp.map((obj, index) => {
        if (index < 12) {
          if (obj.start_month === 1) return (obj.start_month = "January");
          if (obj.start_month === 2) return (obj.start_month = "February");
          if (obj.start_month === 3) return (obj.start_month = "March");
          if (obj.start_month === 4) return (obj.start_month = "April");
          if (obj.start_month === 5) return (obj.start_month = "May");
          if (obj.start_month === 6) return (obj.start_month = "June");
          if (obj.start_month === 7) return (obj.start_month = "July");
          if (obj.start_month === 8) return (obj.start_month = "August");
          if (obj.start_month === 9) return (obj.start_month = "September");
          if (obj.start_month === 10) return (obj.start_month = "October");
          if (obj.start_month === 11) return (obj.start_month = "November");
          if (obj.start_month === 12) return (obj.start_month = "December");
        }
      });
    // setTableData(query.regionCategoryTableData);
    setTableData(dataTemp);
  }, [query.regionCategoryTableData]);
  // console.log("tableData-check", tableData);

  const materialTableDetailPanel = (data) => {
    // console.log("detailPanel-check", data);
    let detailData = [
      {
        region: data.region,
        start_year: data.start_year,
        start_month: data.start_month,
        parking_cat_1: data.parking_cat_1,
        parking_cat_2: data.parking_cat_2,
        parking_cat_3: data.parking_cat_3,
        parking_cat_4: data.parking_cat4,
        parking_cat_5: data.parking_cat_5,
        parking_cat_total: data.parking_cat_total,
      },
    ];
    // let detailData = [
    //   {
    //     parking_cat_1: 0,
    //     parking_cat_2: 0,
    //     parking_cat_3: 0,
    //     parking_cat_total: 0,
    //   },
    // ];

    return (
      <div
        style={{
          fontSize: 14,
          textAlign: "center",
          color: "white",
          backgroundColor: "#E53935",
          marginLeft: 10,
          marginBottom: 10,
          marginTop: 0,
          borderTopWidth: 0,
        }}
      >
        <MaterialTable
          title=""
          columns={[
            // { title: "Region", field: "region" },
            // { title: "Year", field: "start_year", width: "5%" },
            // { title: "Month", field: "start_month" },
            {
              title: "< 1 hour",
              field: "parking_cat_1",
              type: "numeric",
            },
            {
              title: ">= 1, < 3 hours",
              field: "parking_cat_2",
              type: "numeric",
            },
            {
              title: ">= 3, < 7 hours",
              field: "parking_cat_3",
              type: "numeric",
            },
            {
              title: ">= 7, < 11 hours",
              field: "parking_cat_4",
              type: "numeric",
            },
            {
              title: ">= 11 hours",
              field: "parking_cat_5",
              type: "numeric",
            },
            {
              title: "Total Events",
              field: "parking_cat_total",
              type: "numeric",
            },
          ]}
          data={detailData}
          options={{
            // exportButton: { csv: true, pdf: false },
            // // maxBodyHeight: "25vh"
            // exportAllData: true,
            paging: false,
            search: false,
          }}
        />
      </div>
    );
  };

  return (
    <div>
      {/* <div className={classes.root}> */}
      {tableData.length > 1 && (
        <MaterialTable
          columns={[
            { title: "Region", field: "region", hidden: true, export: true },
            {
              title: "Year",
              field: "start_year",
              width: "5%",
              hidden: true,
              export: true,
            },
            {
              title: "Month",
              field: "start_month",
              cellStyle: {
                backgroundColor: "#039be5",
                color: "#FFF",
                // width: "100px",
                // maxWidth: "2px",
              },
            },
            {
              title: "Events < 1 hour",
              field: "parking_cat_1",
              type: "numeric",
              hidden: true,
              export: true,
            },
            {
              title: "Events >= 1, < 3 hours",
              field: "parking_cat_2",
              type: "numeric",
              hidden: true,
              export: true,
            },
            {
              title: "Events >= 3, < 7 hours",
              field: "parking_cat_3",
              type: "numeric",
              hidden: true,
              export: true,
            },
            {
              title: "Events >= 7, < 11 hours",
              field: "parking_cat_4",
              type: "numeric",
              hidden: true,
              export: true,
            },
            {
              title: "Events >= 11 hours",
              field: "parking_cat_5",
              type: "numeric",
              hidden: true,
              export: true,
            },
            {
              title: "Total Events",
              field: "parking_cat_total",
              type: "numeric",
              hidden: true,
              export: true,
            },
            {
              title: "% < 1 hour",
              field: "parking_cat_1_pc",
              type: "numeric",
            },
            {
              title: "% >= 1, < 3 hours",
              field: "parking_cat_2_pc",
              type: "numeric",
            },
            {
              title: "% >= 3, < 7 hours",
              field: "parking_cat_3_pc",
              type: "numeric",
            },
            {
              title: "% >= 7, < 11 hours",
              field: "parking_cat_4_pc",
              type: "numeric",
            },
            {
              title: "% >= 11 hours",
              field: "parking_cat_5_pc",
              type: "numeric",
            },
            {
              title: "Total",
              field: "parking_cat_total_pc",
              type: "numeric",
            },
          ]}
          data={tableData}
          title={titleDesc}
          //   detailPanel={rowData => {
          //     return (
          //       <div>
          //        <PlotlyBarChart />
          //       <PlotlyStackedBarChart dataX={[1, 2, 3]} dataY1={[2, 5, 3]} dataY2={[3, 4, 4]}/>
          //       </div>
          //     )
          //   }}
          //   onRowClick={(event, rowData, togglePanel) => togglePanel()}
          options={{
            exportButton: { csv: true, pdf: false },
            // maxBodyHeight: "25vh"
            exportAllData: true,
            exportFileName:
              tableData[0].region +
              "_" +
              tableData[0].start_year +
              "-" +
              tableData[0].start_month +
              "-" +
              (query.selectedDayType === "All Daytypes"
                ? "AllDaytypes"
                : query.selectedDayType) +
              "_" +
              (query.selectedVehicleWeightClass === 0
                ? "alltrucks"
                : "vehwghtclass" + query.selectedVehicleWeightClass) +
              "_truckparkingevents",
            paging: false,
            rowStyle: {
              wordWrap: "break-word",
            },
            padding: "dense",
            // tableLayout: "auto",
            doubleHorizontalScroll: true,
            detailPanelType: "multiple",
            headerStyle: {
              // position: "sticky",
              top: 0,
              paddingTop: 0,
              marginTop: 0,
              backgroundColor: "#01579b",
              color: "#FFF",
            },
            titleStyle: {
              paddingTop: 0,
              marginTop: 0,
            },
          }}
          // detailPanel={(rowData) => {
          //   // return console.log(
          //   //   "rowData-check",
          //   //   tableData[rowData.tableData.id]
          //   // );
          //   let detailData = {
          //     parking_cat_1: rowData.parking_cat_1,
          //     parking_cat_2: rowData.parking_cat_2,
          //     parking_cat_3: rowData.parking_cat_3,
          //     parking_cat_total: rowData.parking_cat_total,
          //   };
          //   return materialTableDetailPanel(detailData);
          // }}
          detailPanel={[
            {
              // disabled: roadLayerType === "MD100",
              tooltip: "Show Detailed Information",
              render: (rowData) => {
                // console.log("rowData-check", rowData[0]);
                return (
                  <div
                    style={{
                      fontSize: 100,
                      textAlign: "center",
                      color: "white",
                      backgroundColor: "#43A047",
                    }}
                  >
                    {materialTableDetailPanel(rowData)}
                  </div>
                );
              },
            },
          ]}
        />
      )}
    </div>
  );
}
